import React, { useEffect, useState } from 'react';
import {
    Cell,
    Check,
    Circle,
    HiddenButton,
    HiddenButtonsRow,
    LargeCircle,
    Row,
    SelectionsRow,
    StepsRow,
    Wrapper
} from './styled/ProgressIndicator';

const optionalText = 'Optional';

interface Props {
    currentStep: number;
    onStepChange: (step: number) => void;
    selections: string[];
    steps: { name: string; optional: boolean }[];
}

const ProgressIndicator: React.FC<Props> = ({
    currentStep,
    onStepChange,
    selections,
    steps
}) => {
    const [stepsIndicators, setIndicators] = useState<React.ReactElement[]>(
        new Array(steps.length)
    );
    const [selectionTexts, setSelectionTexts] = useState<string[]>(
        new Array(steps.length)
    );

    useEffect(() => {
        const newIndicators = [...stepsIndicators].map((_, i) => {
            const stepNumber = i;
            if (stepNumber < currentStep) {
                return <Check fontSize="small" />;
            }
            if (stepNumber === currentStep) {
                return <LargeCircle>{stepNumber + 1}</LargeCircle>;
            }
            if (stepNumber > currentStep) {
                return <Circle />;
            }
            return <></>;
        });
        setIndicators(newIndicators);
    }, [currentStep]);

    useEffect(() => {
        const newSelectionTexts = [...selectionTexts].map((_, i) => {
            if (selections[i]) {
                return selections[i];
            }
            if (i === currentStep && steps[i].optional) {
                return optionalText;
            }
            return '';
        });
        setSelectionTexts(newSelectionTexts);
    }, [currentStep, selections]);

    return (
        <Wrapper>
            <Row>
                {stepsIndicators.map((indicator, i) => (
                    <Cell indicator key={`step-${i}`}>
                        {indicator}
                    </Cell>
                ))}
            </Row>
            <StepsRow>
                {steps.map((step, i) => (
                    <Cell key={`step-info-${i}`} stepText>
                        {step.name}
                    </Cell>
                ))}
            </StepsRow>
            {selections.length > 0 && (
                <SelectionsRow>
                    {selectionTexts.map((selection, i) => (
                        <Cell
                            highlight={selection === optionalText}
                            key={`step-selection-${i}`}
                            selectionText
                        >
                            {selection}
                        </Cell>
                    ))}
                </SelectionsRow>
            )}
            <HiddenButtonsRow>
                {steps.map((_, i) => (
                    <HiddenButton
                        data-testid={`step-button-${i}`}
                        disabled={i >= currentStep}
                        key={`step-button-${i}`}
                        onClick={() => onStepChange(i)}
                    />
                ))}
            </HiddenButtonsRow>
        </Wrapper>
    );
};

export default ProgressIndicator;
