export enum PageType {
    customBuilder = 'custom_builder',
    customResults = 'custom_results',
    templateResults = 'template_results',
    default = ''
}

export interface Constraint {
    questionId: string;
    questionText: string;
    text: string;
    answerText: string;
}
