import styled, { css } from 'styled-components';
import { baseFont, breakpoints, colors, Theme } from '../../../theme';

interface ButtonProps {
    active: boolean;
    theme: Theme;
}

export const TabsContainer = styled.div`
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
`;

export const TabButton = styled.button<ButtonProps>`
    display: inline-block;
    margin: 0 auto;
    width: 130px;
    outline: none;
    background: transparent;
    padding: 10px 24px 10px 24px;
    line-height: 1;
    border: 1px solid
        ${p => (p.theme === Theme.dark ? colors.darkGray : colors.titleGray)};
    border-radius: 0;
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.titleGray)};
    ${baseFont()}
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    ${p =>
        p.active &&
        css`
            background: ${p =>
                p.theme === Theme.dark ? colors.goldenrod : colors.titleGray};
            color: ${colors.white};
        `}
    &:hover {
        ${p =>
            p.active &&
            css`
                cursor: initial;
            `}
        ${p =>
            !p.active &&
            css`
                background: ${p =>
                    p.theme === Theme.dark
                        ? colors.goldenrod
                        : colors.titleGray};
                color: ${colors.white};
            `}
    }
    ${p =>
        p.theme === Theme.dark &&
        css`
            text-transform: uppercase;
            width: 194px;
            @media (max-width: ${breakpoints.smallMax}) {
                display: block;
                &:first-child {
                    border-top-right-radius: 4px;
                    border-bottom-left-radius: 0;
                }
                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-top-right-radius: 0;
                }
            }
        `}
`;
