export interface Question {
    id: string;
    title: string;
    text: string;
    surveys: string[];
    allowedAnswers: string[];
    isDreamEntry: boolean;
    enabled: boolean;
}

export interface Answer {
  id: string;
  question: Question;
  answerText: string;
  wordCount: number;
  responseSet: string;
  createdAt: string;
  dreamDate?: string;
  isBce: boolean;
}

export interface Category {
    id: string;
    name: string;
    description?: string;
    subCategories: SubCategory[];
    position: number;
    wordTemplate: string;
}

export interface SubCategory {
    id: string;
    name: string;
    description?: string;
    category?: string;
    keywords: string[];
}

export interface CsvExport {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    link?: string;
    projectDetails?: string;
}

export interface Analysis {
    id: string;
    requestData: AnalysisRequest;
    analysisData: AnalysisData[];
}

export interface AnalysisRequest {
    surveyIds: string[];
    xAxisQuestionId: string;
    yAxisQuestionId: string;
    constraints: AnalysisConstraint[];
}

export interface AnalysisData {
    xAnswer: string;
    yAnswer: string;
    count: number;
}

export interface AnalysisConstraint {
    questionId: string;
    answerText: string[];
}

export interface Respondent {
    id: string;
    userName?: string;
    createdAt: string;
    responseSets: string[];
}

export interface ResponseSet {
    id: string;
    survey: string;
    answers: string[];
    respondent: string;
    createdAt: string;
}

export interface Survey {
    id: string;
    questions: string[];
    name: string;
    description?: string;
    userKey?: string;
    responseSets: string[];
    respondentCount: number;
    enabled: boolean;
    enableAnalysis: boolean;
    enableWordSearch: boolean;
    open: boolean;
    userAgreementType?: string;
    journal: boolean;
    baseline: boolean;
    category?: string;
    mappingSets: string[];
    createdAt: string;
    year: string;
}

export interface SurveySubmissionRequest {
    survey: string;
    name: string;
    email: string | null;
    code: string | null;
    public: boolean;
    answers: Answer[];
}

export interface SurveySubmissionResponse {
    responseSet: string;
    respondentCode: string;
}

export interface SurveyMapping {
    id: string;
    surveyMappingSet: string;
    name?: string;
    question: string;
    questionKey: string;
    dreamEntry: boolean;
    answerMappings: any[];
}

export interface SurveyMappingSet {
    id: string;
    survey: string;
    name: string;
    mappings: string[];
}

export interface WordTemplate {
    id: string;
    name: string;
    version: number;
    categories: Category[];
}

export interface WordSearchRequest {
    metadataOnly: boolean;
    matchPartials: boolean;
    surveyIds: string[];
    dreamEntryIds: string[];
    respondentIds: string[];
    responseSetIds: string[];
    startDate?: string;
    endDate?: string;
    startBce?: string; //boolean?
    endBce?: string; //boolean?
    minWordCount?: number;
    maxWordCount?: number;
    searchText: string[][];
    searchTextOperators: string[];
    subCategoryIds: string[][];
    subCategoryOperators: string[];
    //should answerText be an array like it is in Contraint.php?
    //can't figure out how this could work given the backend wants an array
    //but the frontend seems to pass a string
    constraints: { questionId: string; answerText: string }[];
    size: number;
    from: number;
    sortDir: string;
    sortBy: string;
}

export interface WordSearchMetadata {
    [word: string]: {
        buckets: {
            [word: string]: {
                doc_count: number;
            };
        };
    };
}

export interface WordSearchStatistics {
    count: number;
    min: number | null;
    max: number | null;
    avg: number | null;
    sum: number | null;
    std_deviation: number | null;
    median: number | null;
}

export interface WordSearch {
    id: string;
    requestData: WordSearchRequest[];
    dreamEntryCount: number;
    respondentCount: number;
    answers: any[];
    metadata: WordSearchMetadata;
    statistics: WordSearchStatistics;
}

export enum ApiType {
    Analysis = 'analyses',
    Category = 'categories',
    SubCategory = 'sub_categories',
    Question = 'questions',
    Answer = 'answers',
    Survey = 'surveys',
    Respondent = 'respondents',
    ResponseSet = 'response_sets',
    SurveyMapping = 'survey_mappings',
    SurveyMappingSets = 'survey_mapping_sets',
    WordTemplate = 'word_templates',
    SurveySubmission = 'survey_submissions',
}

export type QueryConfig = {
    active?: boolean;
    activeFn?: () => boolean;
};


