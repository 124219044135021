import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
    customMultiSelectStyles,
    customNestedMultiSelectStyles,
    DropdownIndicator
} from './styled/Select';

interface Option {
    label: string;
    value: string;
}

interface OptionGroup {
    label: string;
    options: Option[];
}

interface Props {
    handleChange: (values: string[]) => void;
    handleInputChange?: (value: string) => void;
    nested?: boolean;
    options: Option[] | OptionGroup[];
    placeholder: string;
    values: Option[];
}

const Multiselect: React.FC<Props> = ({
    handleChange,
    handleInputChange,
    nested,
    options,
    placeholder,
    values
}) => {
    const [_values, setValues] = useState(values);

    useEffect(() => {
        setValues(values);
    }, [values]);

    const _handleChange = (options: Option[]) => {
        const selected = options.map(option => option.value);
        setValues(options);
        handleChange(selected);
    };

    return (
        <Select
            components={{
                DropdownIndicator: ({ selectProps }) => (
                    <DropdownIndicator open={selectProps.menuIsOpen || false} />
                )
            }}
            isMulti
            onChange={values =>
                _handleChange(values ? (values as Option[]) : [])
            }
            onInputChange={handleInputChange}
            options={options}
            placeholder={placeholder}
            styles={
                nested ? customNestedMultiSelectStyles : customMultiSelectStyles
            }
            value={_values}
            closeMenuOnSelect={false}
        />
    );
};

export default Multiselect;
