import React, { useState } from 'react';
import * as Styled from './styled/Home';
import FeaturedDream from './FeaturedDream';
import FeaturedAnalysis from './FeaturedAnalysis';
import TemplateCard from '../library/TemplateCard';
import Copyright from '../shared/Copyright';
import Divider from '../shared/Divider';
import LoadingIndicator from '../shared/LoadingIndicator';
import Search from '../shared/dreamTextSearch/DreamTextSearch';
import Tooltip from '../shared/Tooltip';
import { libraryEntries } from '../advancedSearch/constants';
import { LibraryEntryUrl } from '../advancedSearch/types';
import { templateAnalyses } from '../analysis/templates/constants';
import { TemplateAnalysisUrl } from '../analysis/templates/types';
import { Runnable } from '../../types';
import { colors } from '../../theme';
import { ENTRYPOINT } from '../../config/entrypoint';

const featuredEntry = libraryEntries.find(
    entry => entry.url === LibraryEntryUrl.Pandemic
);

const featuredAnalysisOne = templateAnalyses.find(
    template => template.url === TemplateAnalysisUrl.PoliticsVVisitation
);

const featuredAnalysisTwo = templateAnalyses.find(
    template => template.url === TemplateAnalysisUrl.ReligionVRecall
);

const Home: React.FC = () => {

    const [loading, setLoading] = useState(true);

    const handleFeaturedDreamDataFetch: Runnable = () => {
        setLoading(false);
    };

    return (
        <Styled.Wrapper>
            {loading && <LoadingIndicator />}
            {!loading && (
                <>
                    <Tooltip
                        id="mobile-heading-tooltip"
                        overrides={{ place: 'bottom' }}
                        trigger={
                            <Styled.Heading>Dream Data Analysis</Styled.Heading>
                        }
                    >
                        <p>
                            The Sleep and Dream Database is an open-access
                            digital archive that supports new empirical research
                            in the study of dreams. The SDDb is directed by
                            Kelly Bulkeley, Ph.D.
                        </p>
                    </Tooltip>
                    <Styled.SearchWrapper>
                        <Search large placeholder="Start exploring" />
                    </Styled.SearchWrapper>
                    <Tooltip
                        id="dreamer-tooltip"
                        trigger={<Divider text="The Dreamers" />}
                    >
                        <p>
                            The dream reports collected in the SDDb come from
                            many sources: individual dream journals,
                            biographies, demographic surveys, psychology
                            experiments, anthropological fieldwork, and literary
                            fiction.
                        </p>
                    </Tooltip>
                </>
            )}
            <FeaturedDream onDataFetched={handleFeaturedDreamDataFetch} />
            {!loading && (
                <>
                    <Tooltip
                        id="compare-data-tooltip"
                        trigger={
                            <Styled.CustomDivider text="Easily Compare Data" />
                        }
                    >
                        <p>
                            The SDDb has two main functions: analyze surveys
                            about people's sleep and dream patterns, and explore
                            the collection of more than 30,000 dream reports.
                        </p>
                    </Tooltip>
                    <Styled.CardsWrapper>
                        {featuredEntry !== undefined && (
                            <TemplateCard
                                color={colors.categoryCards.peach}
                                image={featuredEntry.image}
                                params={featuredEntry.params}
                                title={featuredEntry.title}
                                url={featuredEntry.url}
                            />
                        )}
                        {featuredAnalysisOne !== undefined && (
                            <FeaturedAnalysis template={featuredAnalysisOne} />
                        )}
                        {featuredAnalysisTwo !== undefined && (
                            <FeaturedAnalysis template={featuredAnalysisTwo} />
                        )}
                    </Styled.CardsWrapper>
                    <Copyright />
                </>
            )}
        </Styled.Wrapper>
    );
};

export default Home;
