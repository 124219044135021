import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { baseFont, breakpoints, colors, Theme } from '../../../theme';
import Button from "../Button";

export const Container = styled.div<{ large: boolean; theme: Theme }>`
    display: block;
    position: relative;
    width: calc(100% - 70px);
    margin: 0 auto;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 240px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 756px;
    }
    ${baseFont()}
    ${p =>
        p.large &&
        css`
            color: ${p.theme === Theme.dark ? colors.white : colors.titleGray};
        `}
    ${p =>
        !p.large &&
        css`
            color: ${p.theme === Theme.dark ? colors.white : colors.darkGray};
        `}
    input {
        display: block;
        outline: none;
        border: none;
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        color: inherit;
        ${p =>
            p.large &&
            css`
                font-family: 'Martel Sans', sans-serif;
                font-size: 32px;
                font-weight: 200;
                padding: 0;
                padding-right: 32px;
                @media (min-width: ${breakpoints.largeMin}) {
                    font-size: 50px;
                    line-height: 1.5;
                }
                border-bottom: 8px solid
                    ${p.theme === Theme.dark ? colors.white : colors.titleGray};
            `}
        ${p =>
            !p.large &&
            css`
                padding: 10px 0;
                padding-right: 32px;
                font-family: inherit;
                font-weight: 400;
                font-size: 16px;
                @media (min-width: ${breakpoints.largeMin}) {
                    font-size: 18px;
                }
                border-bottom: 1px solid
                    ${p.theme === Theme.dark ? colors.white : colors.darkGray};
            `}
        ::-webkit-input-placeholder {
            color: ${colors.lightGray};
            opacity: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        ::-moz-placeholder {
            color: ${colors.lightGray};
            opacity: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        :-ms-input-placeholder {
            color: ${colors.lightGray};
            opacity: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        :-moz-placeholder {
            color: ${colors.lightGray};
            opacity: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        ${p =>
            p.theme === Theme.dark &&
            css`
                ::-webkit-input-placeholder {
                    color: ${colors.white};
                    opacity: 0.7;
                }
                ::-moz-placeholder {
                    color: ${colors.white};
                    opacity: 0.7;
                }
                :-ms-input-placeholder {
                    color: ${colors.white};
                    opacity: 0.7;
                }
                :-moz-placeholder {
                    color: ${colors.white};
                    opacity: 0.7;
                }
            `}
    }
`;

export const SearchButton = styled.button<{ large: boolean }>`
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    top: 4px;
    right: -2px;
    cursor: pointer;
    color: inherit;
    margin: 0;
    padding: 0;
    display: block;
    font-size: 24px;
    line-height: 38px;
    svg {
        vertical-align: initial;
    }
    ${p =>
        p.large &&
        css`
            line-height: 64px;
            @media (min-width: ${breakpoints.largeMin}) {
                top: 18px;
                line-height: 50px;
            }
            svg {
                width: 34px;
                height: 34px;
            }
        `}
`;

export const ClearButton = SearchButton;

export const AdvancedSearchButton = styled(Link)<{ theme: Theme }>`
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.darkGray)};
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    padding: 0px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    line-height: 1;
    svg {
        margin-left: 8px;
        height: 16px;
        width: 16px;
    }
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        border: none;
        outline: none;
        color: ${p =>
            p.theme === Theme.dark ? colors.white : colors.darkGray};
    }
`;

export const FilterButton = styled(Button)<{ theme: Theme, add: boolean }>`
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.darkGray)};
    font-weight: 700;
    font-size: 14px;
    padding: 0px;
    margin-top: 16px;
    margin-left: ${p => (p.add === true ? '0' : 'auto')};
    margin-right: ${p => (p.add === false ? '0' : 'auto')};
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 1;
    svg {
        margin-left: 8px;
        height: 16px;
        width: 16px;
    }
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        border: none;
        outline: none;
        background-color: transparent;
        color: ${p =>
  p.theme === Theme.dark ? colors.white : colors.darkGray};
    }
`;