import {Supplier} from './types';
import featured_image_one from './assets/featured/fern_valley_spring@2x.jpg';
import featured_image_two from './assets/featured/full_moon@2x.jpg';
import featured_image_three from './assets/featured/lacy_moss@2x.jpg';
import featured_image_four from './assets/featured/many_arlis@2x.jpg';
import featured_image_five from './assets/featured/morning_moon@2x.jpg';
import featured_image_six from './assets/featured/purple_flowers@2x.jpg';
import featured_image_seven from './assets/featured/rainbow_tree@2x.jpg';
import featured_image_eight from './assets/featured/reflecting_pool@2x.jpg';
import featured_image_nine from './assets/featured/roots_onthecreek@2x.jpg';
import featured_image_ten from './assets/featured/skunk_cabba@2x.jpg';
import featured_image_eleven from './assets/featured/two_birds_attack@2x.jpg';
import featured_image_twelve from './assets/featured/under-the-rock@2x.jpg';
import featured_image_thirteen from './assets/featured/wild-wild-iris@2x.jpg';

export const getRandomInteger: (min: number, max: number) => number = (
    min,
    max
) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};

const heroImageUrls: string[] = [
    featured_image_one,
    featured_image_two,
    featured_image_three,
    featured_image_four,
    featured_image_five,
    featured_image_six,
    featured_image_seven,
    featured_image_eight,
    featured_image_nine,
    featured_image_ten,
    featured_image_eleven,
    featured_image_twelve,
    featured_image_thirteen
];

export const getRandomImageUrl: Supplier<string> = () => {
    const randomImageIndex = getRandomInteger(0, heroImageUrls.length);
    return heroImageUrls[randomImageIndex];
};

export const extractIdFromPath: (path: string) => string = path => {
    //split on / and take the last element
    const splitPath = path.split('/');
    return splitPath[splitPath.length - 1];
}

export const removeApiPrefix: (path: string) => string = path => {
    return path.replace('/api/sddb', '');
}

export const extractUuidFromFullPath: (
    fullPath: string
) => string = fullPath => {
    const regex = /.{8}-.{4}-.{4}-.{4}-.{12}/;
    const match = regex.exec(fullPath);
    if (!match) {
        return '';
    }
    return match[0];
};

const nonUniqueUuidSegment = '8421-11ea-b97a-0242ac120005';

export const createFullUuid: (
    uniqueSegment: string
) => string = uniqueSegment => `${uniqueSegment}-${nonUniqueUuidSegment}`;

function decoder<T>(jsonString: string): T | undefined {
    try {
        const params = JSON.parse(decodeURIComponent(jsonString));
        return params;
    } catch (_) {
        return undefined;
    }
}

export const decode: <U>(arg: string) => U | undefined = decoder;
