import styled, { css } from 'styled-components';
import BasicButton from '../../shared/Button';
import { baseFont, breakpoints, colors } from '../../../theme';

const extraBreakpoints = {
    extraSmallMax: 370,
    tabletMax: 790
};

const setDynamicText = () => css`
    @media (max-width: ${extraBreakpoints.extraSmallMax}px) {
        font-size: 3.8vw;
    }
    @media (min-width: ${breakpoints.mediumMin}) and (max-width: ${extraBreakpoints.tabletMax}px) {
        font-size: 1.8vw;
    }
`;

export const Card = styled.div<{ color: string; img?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100vw - 70px);
    height: calc((100vw - 70px) + 33px);
    max-width: 300px;
    max-height: 333px;
    border-radius: 4px;
    padding: 29px 27px;
    margin-top: 24px;
    background: ${p => p.color};
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(((100vw - 160px) / 2) - 16px);
        height: calc(((100vw - 160px) / 2) + 10px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 360px;
        height: 395px;
        max-width: initial;
        max-height: initial;
    }
    ${p =>
        p.img &&
        css`
            ::after {
                background-image: url(${p.img});
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: 0;
                opacity: 0.1;
                position: absolute;
                width: calc(100% - 54px);
                height: calc(100% - 58px);
                content: ' ';
                z-index: 0;
            }
        `}
`;

export const Title = styled.div`
    font-family: 'Martel Sans', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.4;
    @media (max-width: ${extraBreakpoints.extraSmallMax}px) {
        font-size: 6.3vw;
    }
    @media (min-width: ${breakpoints.mediumMin}) and (max-width: ${extraBreakpoints.tabletMax}px) {
        font-size: 2.8vw;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 28px;
    }
    text-align: center;
    color: ${colors.titleGray};
    padding: 0 12px;
`;

export const Subtitle = styled.div`
    ${baseFont()}
    ${setDynamicText()}
    color: ${colors.darkGray};
    text-align: center;
    
`;

export const Button = styled(BasicButton)`
    margin-top: 18px;
    z-index: 1;
    ${setDynamicText()}
`;
