import React from 'react';
import styled from 'styled-components';
import Home from '@material-ui/icons/Home';
import PieChart from '@material-ui/icons/PieChart';
import CloudIcon from '@material-ui/icons/Cloud';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import { breakpoints, colors } from '../../theme';
import NavigationButton from './MobileNavigationButton';
import { Path } from '../../types';

export enum ButtonName {
    Feed = 'Feed',
    AnalyzeData = 'Analyze Data',
    DreamLibrary = 'Dream Library',
    Participate = 'Participate',
    Contact = 'Contact',
    Search = 'Search'
}

interface ButtonMeta {
    externalLink?: boolean;
    icon: React.ReactElement;
    name: ButtonName;
    path: string;
}

const Nav = styled.nav`
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    height: 56px;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${colors.darkGray};
    @media (min-width: ${breakpoints.mediumMin}) {
        display: none;
    }
`;

const buttons: ButtonMeta[] = [
    {
        icon: <Home />,
        name: ButtonName.Feed,
        path: Path.home
    },
    {
        icon: <PieChart />,
        name: ButtonName.AnalyzeData,
        path: Path.analyze.replace(':type?/:json?', '')
    },
    {
        icon: <CloudIcon />,
        name: ButtonName.DreamLibrary,
        path: Path.library
    },
    /*{
        externalLink: true,
        icon: <EmailIcon />,
        name: ButtonName.Contact,
        path: 'https://kellybulkeley.org/contact/'
    },*/
    {
        icon: <SearchIcon />,
        name: ButtonName.Search,
        path: Path.search
    }
];

const MobileNavigationMenu: React.FC = () => (
    <Nav>
        {buttons.map(button => (
            <NavigationButton
                external={button.externalLink}
                icon={button.icon}
                key={button.name}
                name={button.name}
                path={button.path}
            />
        ))}
    </Nav>
);

export default MobileNavigationMenu;
