import { Question, Survey } from '../../api/types';
import {extractUuidFromFullPath} from "../../../utils";

export function transformSurveyData (
    surveys: Survey[]
): Survey[] {
    return surveys
        .sort((a, b) => {
            const aWords = a.name.split(' ');
            const firstAWordAsNumber = parseInt(aWords[0]);
            const bWords = b.name.split(' ');
            const firstBWordAsNumber = parseInt(bWords[0]);
            if (!isNaN(firstAWordAsNumber) && !isNaN(firstBWordAsNumber)) {
                if (firstAWordAsNumber > firstBWordAsNumber) {
                    return -1;
                }
                if (firstBWordAsNumber > firstAWordAsNumber) {
                    return 1;
                }
            }
            return 0;
        });
}

export function getQuestionsInAllSurveys(
    questions: Question[],
    selectedSurveys: Survey[]
): Question[] {
    return questions.filter((q) => {
        for (const survey of selectedSurveys) {
            if (!survey.questions.map(sq => extractUuidFromFullPath(sq)).includes(q.id)) {
                return false;
            }
        }
        return true;
    });
}
