import { TemplateAnalysis, TemplateAnalysisUrl } from './types';
import education_level_versus_dream_recall_svg from '../../../assets/analysis/education.svg';
import gender_versus_falling_dreams_svg from '../../../assets/analysis/gender.svg';
import marital_status_versus_talking_about_dreams_svg from '../../../assets/analysis/marital.svg';
import political_ideology_versus_visitation_dreams_svg from '../../../assets/analysis/political.svg';
import religious_affiliation_versus_dream_recall_svg from '../../../assets/analysis/religious-v-dream-recall.svg';
import gender_versus_dream_recall_18_to_34_year_olds_svg from '../../../assets/analysis/gender-dream-recall.svg';

export const templateAnalyses: TemplateAnalysis[] = [
    {
        url: TemplateAnalysisUrl.EducationVRecall,
        params: {
            title: 'Education Level versus Dream Recall',
            surveyIds: [
                '/surveys/97476417-8421-11ea-b97a-0242ac120005',
                '/surveys/97476541-8421-11ea-b97a-0242ac120005',
                '/surveys/974763e1-8421-11ea-b97a-0242ac120005'
            ],
            xAxisQuestionId: '/questions/018ed463-cadf-7bb5-80d4-9f77d30339e4',
            yAxisQuestionId: '/questions/9749ba09-8421-11ea-b97a-0242ac120005',
            constraints: []
        },
        title: 'Education Level v. Dream Recall',
        imageUrl: education_level_versus_dream_recall_svg,
        highlight: '',
        respondentCount: 5590
    },
    {
        url: TemplateAnalysisUrl.GenderVFalling,
        params: {
            title: 'Gender versus Falling Dreams',
            surveyIds: [
                '/surveys/97475df4-8421-11ea-b97a-0242ac120005',
                '/surveys/974774f3-8421-11ea-b97a-0242ac120005'
            ],
            xAxisQuestionId: '/questions/018ed463-cbb2-7f3e-a6b9-4ad9b9d463e0',
            yAxisQuestionId: '/questions/9749bcc2-8421-11ea-b97a-0242ac120005',
            constraints: []
        },
        title: 'Gender v. Falling Dreams',
        imageUrl: gender_versus_falling_dreams_svg,
        highlight: '',
        respondentCount: 3675
    },
    {
        url: TemplateAnalysisUrl.MaritalStatusVTalking,
        params: {
            title: 'Marital Status versus Talking About Dreams',
            surveyIds: ['/surveys/97476417-8421-11ea-b97a-0242ac120005'],
            xAxisQuestionId: '/questions/97480e42-8421-11ea-b97a-0242ac120005',
            yAxisQuestionId: '/questions/9749bafd-8421-11ea-b97a-0242ac120005',
            constraints: []
        },
        title: 'Marital Status v. Talking About Dreams',
        imageUrl: marital_status_versus_talking_about_dreams_svg,
        highlight: '',
        respondentCount: 2272
    },
    {
        url: TemplateAnalysisUrl.PoliticsVVisitation,
        params: {
            title: 'Political Ideology versus Visitation Dreams',
            surveyIds: [
                '/surveys/97476417-8421-11ea-b97a-0242ac120005',
                '/surveys/974763e1-8421-11ea-b97a-0242ac120005'
            ],
            xAxisQuestionId: '/questions/974930dc-8421-11ea-b97a-0242ac120005',
            yAxisQuestionId: '/questions/9749bb34-8421-11ea-b97a-0242ac120005',
            constraints: []
        },
        title: 'Political Ideology v. Visitation Dreams',
        imageUrl: political_ideology_versus_visitation_dreams_svg,
        highlight: '',
        respondentCount: 4588
    },
    {
        url: TemplateAnalysisUrl.ReligionVRecall,
        params: {
            title: 'Religious Affiliation versus Dream Recall',
            surveyIds: ['/surveys/97476417-8421-11ea-b97a-0242ac120005'],
            xAxisQuestionId: '/questions/9749ce47-8421-11ea-b97a-0242ac120005',
            yAxisQuestionId: '/questions/9749ba09-8421-11ea-b97a-0242ac120005',
            constraints: []
        },
        title: 'Religious Affiliation v. Dream Recall',
        imageUrl: religious_affiliation_versus_dream_recall_svg,
        highlight: '',
        respondentCount: 2285
    },
    {
        url: TemplateAnalysisUrl.GenderVRecall,
        params: {
            title: 'Gender versus Dream Recall for 18 to 34 year olds',
            surveyIds: [
                '/surveys/97476204-8421-11ea-b97a-0242ac120005',
                '/surveys/974763e1-8421-11ea-b97a-0242ac120005',
                '/surveys/97476541-8421-11ea-b97a-0242ac120005',
                '/surveys/9747702f-8421-11ea-b97a-0242ac120005'
            ],
            xAxisQuestionId: '/questions/018ed463-cbb2-7f3e-a6b9-4ad9b9d463e0',
            yAxisQuestionId: '/questions/9749ba09-8421-11ea-b97a-0242ac120005',
            constraints: [
                {
                    answerText: '18-34',
                    questionText: 'Age Group D',
                    questionId:
                        '/questions/9749bd34-8421-11ea-b97a-0242ac120005',
                    text: 'Age Group D: 18-34'
                }
            ]
        },
        title: 'Gender v. Dream Recall for 18-34 year olds',
        imageUrl: gender_versus_dream_recall_18_to_34_year_olds_svg,
        highlight: '',
        respondentCount: 3556
    }
];
