import React, { useState } from 'react';
import * as Styled from '../home/styled/Home';
import Copyright from '../shared/Copyright';
import LoadingIndicator from '../shared/LoadingIndicator';
import useApiGet from '../api/useApiGet';
import { ApiType, Survey } from '../api/types';
import ChooseData from '../analysis/builder/ChooseData';
import { Consumer } from '../../types';
import styled from 'styled-components';
import { baseFont, colors } from '../../theme';

const Heading = styled.h1`
    color: ${colors.darkGray};
    ${baseFont({ size: 16, weight: 900 })}
    text-align: center;
    width: 100%;
    margin: 30px 0 66px 0;
    text-transform: uppercase;
    line-height: initial;
`;

const OpenSurveys: React.FC = () => {
    const { data: surveys, loading } = useApiGet<Survey>({
        open: true,
        enabled: true,
    }, ApiType.Survey);
    const [selectedSurveys, setSelectedSurveys] = useState<string[]>([]);

    const handleSurveySelectionChange: Consumer<string[]> = ids => {
        //hack, we really want to redirect to the specific survye selected
        //of which we only allow one
        const surveyId = ids[0] || null;
        if (surveyId) {
            window.location.href = `/open_survey/${surveyId}`;
        }
    };

    return (
        <Styled.Wrapper>
            <Heading>Open Surveys</Heading>
            {loading && <LoadingIndicator />}
            {!loading && (
                <>
                    <ChooseData
                        onSurveySelectionChange={handleSurveySelectionChange}
                        selectedSurveyIds={selectedSurveys}
                        surveys={surveys || []}
                    />
                    <Copyright />
                </>
            )}
        </Styled.Wrapper>
    );
};

export default OpenSurveys;
