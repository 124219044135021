import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { customSelectStyles, DropdownIndicator } from './styled/Select';

interface Option {
    label: string;
    value: string;
}

interface Props {
    clearable?: boolean;
    handleChange: (value: string) => void;
    handleInputChange?: (value: string) => void;
    isDisabled?: boolean;
    options: Option[];
    placeholder: string;
    styleOverrides?: { [param: string]: string };
    value: Option | null;
}

const Select: React.FC<Props> = ({
    clearable = true,
    handleChange,
    handleInputChange,
    isDisabled,
    options,
    placeholder,
    styleOverrides = {},
    value
}) => {
    const [_value, setValue] = useState<Option | null>(value);

    const [_isDisabled, setDisabled] = useState(false);

    /*
     * Open menu will not close if disabled via prop
     * This adds one render cycle to allow the menu to close before
     * it is disabled
     */
    useEffect(() => {
        setDisabled(isDisabled || false);
    }, [isDisabled]);

    useEffect(() => {
        setValue(value);
    }, [value]);

    const _handleChange = (value: Option | null) => {
        handleChange(value ? value.value : '');
        setValue(value);
    };

    return (
        <ReactSelect
            components={{
                DropdownIndicator: ({ selectProps }) => (
                    <DropdownIndicator open={selectProps.menuIsOpen || false} />
                )
            }}
            hideSelectedOptions
            isClearable={clearable}
            isDisabled={_isDisabled || false}
            onChange={value => _handleChange(value ? (value as Option) : null)}
            onInputChange={handleInputChange}
            options={options}
            placeholder={placeholder}
            styles={customSelectStyles(styleOverrides)}
            value={_value}
        />
    );
};

export default Select;
