import { Moment } from 'moment';
import {Category, Question, Survey} from "../api/types";

export enum LibraryEntryUrl {
    RacialJustice = 'racial_justice_survey',
    Visitations = 'visitations_from_the_dead',
    Lucidity = 'lucid_dreams',
    ReligiousReferences = 'dreams_with_religious_references',
    Flying = 'flying_dreams',
    Falling = 'falling_dreams',
    Pandemic = 'pandemic_dreams',
    Nightmares = 'nightmares',
    YoungWomenDreamOfAnimals = 'young_womens_dreams_of_animals',
    YoungMenDreamOfAnimals = 'young_mens_dreams_of_animals',
    Beverly = 'dreams_of_a_religious_cult_member',
    Nan = 'dreams_of_a_traumatic_accident_survivor',
    Mike = 'dreams_of_a_vietnam_war_veteran',
    Lucrecia = 'dreams_of_a_16th_century_prophet',
    Mehinaku = 'dreams_of_an_indigenous_people_of_the_amazon',
    Trump = 'dreams_of_donald_trump_2016_to_2017',
    Obama = 'dreams_of_barack_obama_2008'
}

export interface Metadata {
    dataSetEntries: number;
    dataSetParticipants: number;
    dataSetPercentage: string;
    searchEntries: number;
    searchParticipants: number;
    searchPercentage: string;
    totalEntries: number;
    totalParticipants: number;
}

export interface DreamEntry {
    createdAt: number;
    displayDate: string;
    questionText: string;
    respondent?: {
        id: string;
        userName: string;
    };
    text: string;
    linkTarget: string;
    wordCount: number;
}

export interface Respondent {
    id: string;
    userName: string;
}

export interface SearchFiltersDisplayTexts {
    surveyNames: string[];
    dreamTexts: string[];
    constraintText: string;
    keywordTexts: string[];
    respondentNames: string[];
}

export interface SearchParameters extends SearchFiltersDisplayTexts {
    category: string;
    constraints: { questionId: string; answerText: string }[];
    dateRangeEnd: string;
    dateRangeStart: string;
    startBce: string;
    endBce: string;
    dreamTextIds: string[];
    freeSearchWords: string[][];
    freeSearchOperators: string[];
    keywordIds: string[][];
    keywordOperators: string[];
    maxWordCount: string;
    minWordCount: string;
    respondentIds: string[];
    surveyIds: string[];
}

export interface WordCountFilter {
    gte?: number;
    lte?: number;
}

export interface Keyword {
    keyword: string;
    subcategories: {
        word: string;
        counts: number;
    }[];
}

export interface ResultsFilters {
    surveyIds: string[];
    dreamEntryIds: string[];
    respondentIds: string[];
    startDate: string;
    endDate: string;
    startBce: string;
    endBce: string;
    minWordCount: number;
    maxWordCount: number;
    searchText: string[][];
    searchTextOperators: string[];
    subCategoryIds: string[][];
    subCategoryOperators: string[];
    constraints: { questionId: string; answerText: string }[];
}

export interface DateRangeFilter {
    before?: string;
    after?: string;
}

export interface RespondentFilters {
    surveys: string[];
    questions: string[];
    'responseSets.answers.wordCount[gte]'?: number;
    'responseSets.answers.wordCount[lte]'?: number;
    'responseSets.createdAt[before]'?: string;
    'responseSets.createdAt[after]'?: string;
}

export interface State {
    constraintQuestions: Question[];
    dateRange: string;
    dateRangeEnd: Moment | null;
    dateRangeStart: Moment | null;
    startBce: string;
    endBce: string;
    dreamTextQuestions: Question[];
    freeSearchInputValue: string[];
    freeSearchWords: string[][];
    freeSearchOperators: string[];
    keywords: Category[];
    maxWordCount: string;
    maxWordInputValidity: boolean;
    minWordCount: string;
    minWordInputValidity: boolean;
    respondents: Respondent[];
    selectedConstraintAnswer: string;
    selectedConstraintQuestionId: string;
    selectedDreamTextIds: string[];
    selectedKeywordIds: string[][];
    selectedKeywordOperators: string[];
    selectedRespondentIds: string[];
    selectedRespondentUserNames: string[];
    selectedSurveyIds: string[];
    surveys: Survey[];
}

export interface ExportCsvData {
    constraints?: { questionId: string; answerText: string }[];
    surveyIds?: string[];
    freeSearchWords?: string[][];
    freeSearchOperators?: string[];
    keywordIds?: string[][];
    keywordOperators?: string[];
    respondentIds?: string[];
    dreamTextIds?: string[];
}

export enum ActionType {
    reset,
    setFreeSearchInputValue,
    setDateRangeEnd,
    setDateRangeStart,
    setStartBce,
    setEndBce,
    setKeywordCategories,
    setQuestions,
    setRespondents,
    setSelectedConstraintAnswer,
    setSelectedConstraintQuestionId,
    setSelectedDreamTextIds,
    setSelectedFreeSearchWords,
    setSelectedKeywordIds,
    setSelectedRespondents,
    setSelectedSurveyIds,
    setSurveys,
    setWordCounts,
    setWordCountValidity,
    setDateRange,
    setSelectedFreeSearchOperators,
    setSelectedKeywordOperators
}

type Reset = {
    type: ActionType.reset;
};
type SetFreeSearchInputValue = {
    type: ActionType.setFreeSearchInputValue;
    payload: string[];
};
type SetSelectedFreeSearchWords = {
    type: ActionType.setSelectedFreeSearchWords;
    payload: string[][];
};
type SetSelectedFreeSearchOperators = {
    type: ActionType.setSelectedFreeSearchOperators;
    payload: string[];
};
type SetSelectedKeywordOperators = {
    type: ActionType.setSelectedKeywordOperators;
    payload: string[];
};
// type SetFreeSearchInputValue = {
//     type: ActionType.setFreeSearchInputValue;
//     payload: string;
// };
// type SetSelectedFreeSearchWords = {
//     type: ActionType.setSelectedFreeSearchWords;
//     payload: string[];
// };
type SetDateRangeEnd = {
    type: ActionType.setDateRangeEnd;
    payload: Moment | null;
};
type SetStartBce = {
    type: ActionType.setStartBce;
    payload: string;
};
type SetEndBce = {
    type: ActionType.setEndBce;
    payload: string;
};
type SetDateRangeStart = {
    type: ActionType.setDateRangeStart;
    payload: Moment | null;
};
type SetKeywordCategories = {
    type: ActionType.setKeywordCategories;
    payload: Category[];
};
type SetQuestions = {
    type: ActionType.setQuestions;
    payload: {
        constraintQuestions: Question[];
        dreamTextQuestions: Question[];
    };
};
type SetRespondents = {
    type: ActionType.setRespondents;
    payload: Respondent[];
};
type SetSelectedConstraintAnswer = {
    type: ActionType.setSelectedConstraintAnswer;
    payload: string;
};
type SetSelectedConstraintQuestionId = {
    type: ActionType.setSelectedConstraintQuestionId;
    payload: string;
};
type SetSelectedDreamTextIds = {
    type: ActionType.setSelectedDreamTextIds;
    payload: string[];
};
type SetSelectedKeywordIds = {
    type: ActionType.setSelectedKeywordIds;
    payload: string[][];
};
// type SetSelectedKeywordIds = {
//     type: ActionType.setSelectedKeywordIds;
//     payload: string[];
// };
type SetSelectedRespondents = {
    type: ActionType.setSelectedRespondents;
    payload: Respondent[];
};
type SetSelectedSurveyIds = {
    type: ActionType.setSelectedSurveyIds;
    payload: string[];
};
type SetSurveys = { type: ActionType.setSurveys; payload: Survey[] };
type SetWordCounts = {
    type: ActionType.setWordCounts;
    payload: { min: string; max: string };
};
type SetWordCountValidity = {
    type: ActionType.setWordCountValidity;
    payload: boolean[];
};
type SetDateRange = {
    type: ActionType.setDateRange;
    payload: string;
};

export type ActionTypes =
    | Reset
    | SetFreeSearchInputValue
    | SetDateRange
    | SetDateRangeEnd
    | SetEndBce
    | SetStartBce
    | SetDateRangeStart
    | SetKeywordCategories
    | SetQuestions
    | SetRespondents
    | SetSelectedConstraintAnswer
    | SetSelectedConstraintQuestionId
    | SetSelectedDreamTextIds
    | SetSelectedFreeSearchWords
    | SetSelectedKeywordIds
    | SetSelectedRespondents
    | SetSelectedSurveyIds
    | SetSurveys
    | SetWordCounts
    | SetWordCountValidity
    | SetSelectedFreeSearchOperators
    | SetSelectedKeywordOperators;
