import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Dialog from '@material-ui/core/Dialog';
import { baseFont, breakpoints, colors } from '../../../theme';

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 0 36px 0;
    @media (max-width: ${breakpoints.smallMax}) {
        padding-bottom: 92px;
    }
    background: ${colors.white};
    position: relative;
`;

export const Background = styled.div<{ hidden?: boolean; wrap?: boolean }>`
    ${p =>
        p.hidden &&
        css`
            display: none;
        `}
    margin: 0 auto;
    width: 100%;
    max-width: 667px;
    background: rgba(112, 112, 112, 0.05);
    @media (min-width: ${breakpoints.largeMin}) {
        width: 936px;
        max-width: 936px;
    }
    overflow: auto;
    white-space: ${p => (p.wrap ? 'initial' : 'nowrap')};
`;

export const Scrollable = styled.div`
    position: relative;
    width: 667px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 936px;
    }
`;

export const YAxisTitle = styled.div`
    position: absolute;
    ${baseFont({ weight: 700 })}
    color: ${colors.titleGray};
    transform: rotate(-90deg);
    top: 40%;
    left: -16px;
    @media(min-width: ${breakpoints.largeMin}) {
      left: 72px;
    }
`;

export const ChartArea = styled.div`
    width: 530px;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 600px;
    }
`;

export const AxisBounds = styled.div<{ height: number }>`
    width: 100%;
    height: ${p => `${p.height}px`};
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const SingleBar = styled.div<{ width: number; padding: number }>`
    height: 100%;
    width: ${p => `${p.width}px`};
    margin-left: ${p => `${p.padding / 2}px`};
    margin-right: ${p => `${p.padding / 2}px`};
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
`;

interface CellProps {
    color: string;
    height: number;
    fade: boolean;
}

export const Cell = styled.div<CellProps>`
    width: 100%;
    background: ${p => p.color};
    height: ${p => `${p.height}px`};
    &:not(:last-child) {
        margin-top: 2px;
    }
    display: ${p => (p.height === 0 ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${baseFont({ size: 10 })}
    color: ${colors.white};
    @media (max-width: ${breakpoints.mediumMax}) {
      font-weight: bold;
      font-size: 12px;
    }
    opacity: ${p => (p.fade ? 0.4 : 1)};
`;

export const LabelsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const SingleLabel = styled.div<{ width: number }>`
    width: ${p => `${p.width}px`};
    min-width: 0;
    text-align: center;
    padding: 12px 4px;
    color: ${colors.darkGray};
    ${baseFont()}
    @media (max-width: ${breakpoints.mediumMax}) {
      font-weight: bold;
      font-size: 12px;
    }
`;

export const LabelText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LabelTotal = styled.div`
    padding: 6px 0;
    opacity: 0.69;
    font-weight: 400;
`;

export const XAxisTitle = styled.div<{ tableView?: boolean }>`
    display: flex;
    ${p =>
        p.tableView &&
        css`
            @media (max-width: ${breakpoints.smallMax}) {
                display: none;
            }
        `}
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: ${p => (p.tableView ? '8' : '28')}px;
    ${baseFont()}
    color: rgba(44, 44, 44, 0.7);
    @media (max-width: ${breakpoints.mediumMax}) {
        font-size: 12px;
        margin-top: 4px;
        margin-bottom: ${p => (p.tableView ? '8' : '36')}px;
    }
`;

export const ButtonWrapper = styled.div`
    margin: 36px 0 36px 36px;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: ${breakpoints.largeMin}) {
        display: none;
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
`;

export const Title = styled.div`
    margin: 0;
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: 56px;
    }
    ${baseFont({ weight: 900 })}
    text-transform: uppercase;
    color: ${colors.darkGray};
    text-align: center;
    width: 100%;
    letter-spacing: 1.4px;
    white-space: initial;
`;

export const Subtitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${baseFont({ weight: 700, size: 12 })}
    color: ${colors.darkGray};
    text-align: center;
    width: 100%;
    margin-bottom: 36px;
    @media (min-width: ${breakpoints.largeMin}) {
      font-size: 14px;
      margin-bottom: 48px;
      font-weight: 400;
    }
`;

export const DataViewButtonRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -66px;
    right: 36px;
    @media (min-width: ${breakpoints.largeMin}) {
        top: -24px;
        right: 24px;
    }
`;

export const DataViewButton = styled.button<{ active?: boolean }>`
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: ${p => (p.active ? colors.darkGray : colors.lightGray)};
`;

export const Legend = styled.div`
    margin: 0 auto;
    display: block;
    width: calc(100% - 32px);
    background: ${colors.white};
    margin-bottom: 24px;
    padding: 24px;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 744px;
        margin-bottom: 28px;
        padding-left: 96px;
    }
    text-align: left;
`;

export const LegendTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(44, 44, 44, 0.7);
    ${baseFont({ size: 12 })}
    margin-bottom: 16px;
    white-space: initial;
    width: calc(100% - 48px);
    position: relative;
    padding-right: 16px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-bottom: 20px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 14px;
        margin-bottom: 28px;
    }
    > div {
        position: absolute;
        right: -24px;
    }
`;

export const LegendListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.mediumMin}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const LegendListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    ${baseFont({ weight: 700, size: 12 })}
    color: ${colors.darkGray};
    width: 100%;
    padding-bottom: 14px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(50% - 32px);
        &:nth-child(odd) {
            margin-right: 16px;
        }
        &:nth-child(even) {
            margin-left: 16px;
        }
    }
    @media(min-width: ${breakpoints.largeMin}) {
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 28px;
    }
`;

export const LegendListItemColor = styled.div<{ color: string }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    background: ${p => p.color};
    margin-right: 12px;
`;

export const LegendListItemText = styled.div`
    width: calc(100% - 27px);
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ConstraintsContainer = styled.div<{
    empty?: boolean;
    tableView?: boolean;
}>`
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-left: 40px;
    color: ${colors.darkGray};
    ${baseFont({ size: 12 })}
    margin-bottom: 24px;
    ${p =>
        p.tableView &&
        css`
            padding-left: 0;
            text-align: center;
        `}
    @media (min-width: ${breakpoints.largeMin}) {
        width: 744px;
        padding-left: 96px;
        ${p =>
            p.tableView &&
            css`
                padding-left: 0;
                width: 100%;
            `}
        font-size: 14px;
        margin-bottom: 72px;
    }
    ${p =>
        p.empty &&
        css`
            @media (max-width: ${breakpoints.mediumMax}) {
                display: none;
            }
        `}
`;

export const ConstraintsTitle = styled.div<{ tableView?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(44, 44, 44, 0.7);
    ${p =>
        p.tableView &&
        css`
            justify-content: center;
        `}
`;

export const ConstraintsList = styled.div`
    display: block;
    padding-top: 12px;
    font-weight: 700;
    @media (min-width: ${breakpoints.largeMin}) {
        font-weight: 400;
    }
    white-space: initial;
`;

export const EditButton = styled.div<{ add?: boolean; rotate?: boolean }>`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 1px solid #707070;
    @media (max-width: ${breakpoints.mediumMax}) {
        display: none;
    }
    svg {
        width: ${p => (p.add ? '24' : '16')}px;
        height: ${p => (p.add ? '24' : '16')}px;
    }
    color: ${colors.darkGray};
    margin-left: 14px;
    ${p =>
        p.rotate &&
        css`
            margin: 14px 0 0 0;
            transform: rotate(180deg);
        `}
`;

export const Modal = styled(Dialog)`
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.62);
    }
    .MuiDialog-paper {
        width: calc(100% - 72px);
        padding: 80px 24px;
        @media (min-width: ${breakpoints.mediumMin}) {
            width: 608px;
            max-width: 608px;
            padding: 72px 176px 84px;
        }
        @media (min-width: ${breakpoints.largeMin}) {
            width: 552px;
            max-width: 552px;
            padding: 64px 84px 72px;
        }
    }
`;
export const ModalContents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    h2 {
        ${baseFont({ weight: 900, size: 12 })}
        color: ${colors.darkGray};
        padding: 0;
        margin: 16px 0 8px;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        @media(min-width: ${breakpoints.largeMin}) {
            font-weight: 800;
            font-size: 14px;
            letter-spacing: 0.42px;
        }
    }
    p {
        margin: 0;
        padding: 0;
        color: ${colors.titleGray};
        letter-spacing: 0.16px;
        ${baseFont({ size: 16 })}
        line-height: 24px;
        span {
            font-weight: 700;
        }
        @media(min-width: ${breakpoints.largeMin}) {
            font-size: 14px;
            letter-spacing: initial;
            line-height: 22px;
        }
    }
`;

export const AlertWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    svg {
        width: 100%;
    }
`;

export const ModalButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    button {
        outline: none;
        border: none;
        text-decoration: none;
        cursor: pointer;
        ${baseFont({ weight: 700 })}
        color: ${colors.cornHarvest};
        letter-spacing: 0.42;
        background: transparent;
        width: 80px;
        padding: 16px 0 0 0;
        &:hover, &:active, &:focus {
            border: none;
            outline: none;
            text-decoration: none;
        }
    }
`;

export const ErrorMessage = styled.div`
    padding: 0 0 88px 0;
    text-align: center;
    margin: 0 auto;
    color: ${colors.titleGray};
    ${baseFont()}
`;
