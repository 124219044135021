import React from 'react';
import LoadingIndicator from '../../shared/LoadingIndicator';
import {
    CategoryCount,
    Checkmark,
    ConstraintItem,
    ConstraintItemsWrapper
} from './styled';
import { Question } from '../../api/types';
import { Consumer } from '../../../types';

interface Props {
    onSelectionChange: Consumer<Question | null>;
    questions: Question[];
    questionsLoading?: boolean;
    selectedQuestionId: string;
}

const DefineAxis: React.FC<Props> = ({
    onSelectionChange,
    questions,
    questionsLoading,
    selectedQuestionId
}) => {
    const handleItemClick: Consumer<string> = id => {
        if (isItemSelected(id)) {
            onSelectionChange(null);
        } else if (selectedQuestionId === '') {
            const selectedQuestion = questions.find(
                question => question.id === id
            );
            selectedQuestion && onSelectionChange(selectedQuestion);
        } else {
            return;
        }
    };

    const isItemSelected: (id: string) => boolean = id =>
        selectedQuestionId === id;

    return (
        <>
            {questionsLoading && <LoadingIndicator />}
            {!questionsLoading && (
                <>
                    <CategoryCount>
                        {`${questions.length} categor${
                            questions.length === 1 ? 'y' : 'ies'
                        } available within your survey selection`}
                    </CategoryCount>
                    <ConstraintItemsWrapper>
                        {questions.map(question => (
                            <ConstraintItem
                                disabled={
                                    !isItemSelected(question.id) &&
                                    selectedQuestionId !== ''
                                }
                                key={question.id}
                                onClick={() => handleItemClick(question.id)}
                                selected={isItemSelected(question.id)}
                            >
                                <Checkmark
                                    selected={isItemSelected(question.id)}
                                />
                                {question.title}
                            </ConstraintItem>
                        ))}
                    </ConstraintItemsWrapper>
                </>
            )}
        </>
    );
};

export default DefineAxis;
