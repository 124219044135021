import {
    DateRangeFilter,
    RespondentFilters,
    Respondent,
    SearchParameters,
    State,
    WordCountFilter
} from './types';
import { extractUuidFromFullPath } from '../../utils';
import { surveyCategorySortOrder } from './constants';
import moment, {Moment} from "moment";
import {Category, Survey} from "../api/types";

export interface Option {
    label: string;
    value: string;
}
interface OptionGroup {
    label: string;
    options: Option[];
}

export interface ExtendedOption extends  Option{
    label: string;
    value: string;
    start: Moment;
    end: Moment;
}

interface GenericStateObject {
    id: string;
    text: string;
}

type OptionsFromState = (state: State) => Option[];
type OptionsFromStateCategories = (state: State, index: number) => Option[];
type OptionFromState = (state: State) => Option | null;
type OptionGroupsFromState = (state: State) => OptionGroup[];

interface SurveyFilters {
    questions: string[];
    respondents: string[];
    'responseSets.answers.wordCount[lte]'?: number;
    'responseSets.answers.wordCount[gte]'?: number;
    'responseSets.createdAt[before]'?: string;
    'responseSets.createdAt[after]'?: string;
}

interface QuestionFilters {
    surveys: string[];
    respondents: string[];
    'answers.wordCount[gte]'?: number;
    'answers.wordCount[lte]'?: number;
    'surveys.responseSets.createdAt[before]'?: string;
    'surveys.responseSets.createdAt[after]'?: string;
}

const mapKeywordsToOptionGroupsObjects: (
    keywords: Category[]
) => OptionGroup[] = keywords =>
    keywords.map(keyword => ({
        label: keyword.name,
        options: keyword.subCategories.map(subCategory => ({
            label: `${subCategory.name
                .charAt(0)
                .toUpperCase()}${subCategory.name.slice(1)}`,
            value: subCategory.id
        }))
    }));

const mapSurveysToOptionGroupsObjects: (
    surveys: Survey[]
) => OptionGroup[] = surveys => {
    const categoriesWithDuplicates = surveys.map(
        survey => survey.category || ''
    );
    const categories = categoriesWithDuplicates
        .filter(
            (category, i) => categoriesWithDuplicates.indexOf(category) === i
        )
        .sort(
            (a, b) =>
                surveyCategorySortOrder.indexOf(a) -
                surveyCategorySortOrder.indexOf(b)
        );
    return categories.map(category => ({
        label: category !== '' ? category : 'Unsorted',
        options: surveys
            .filter(survey => survey.category === category)
            .sort((a, b) => {
                const aWords = a.name.split(' ');
                const firstAWordAsNumber = parseInt(aWords[0]);
                const bWords = b.name.split(' ');
                const firstBWordAsNumber = parseInt(bWords[0]);
                if (!isNaN(firstAWordAsNumber) && !isNaN(firstBWordAsNumber)) {
                    if (firstAWordAsNumber > firstBWordAsNumber) {
                        return -1;
                    }
                    if (firstBWordAsNumber > firstAWordAsNumber) {
                        return 1;
                    }
                }
                return 0;
            })
            .map(survey => ({
                label: survey.name,
                value: survey.id
            }))
    }));
};

const mapSurveysToOptionObjects: (surveys: Survey[]) => Option[] = surveys =>
    surveys.map(survey => ({
        label: `${survey.name}`,
        value: survey.id
    }));

const mapStateObjectsToOptionObjects: (
    object: GenericStateObject[]
) => Option[] = objects =>
    objects.map(object => ({
        label: object.text,
        value: object.id
    }));

const mapRespondentsToOptionObjects: (
    respondents: Respondent[]
) => Option[] = respondents =>
    respondents.map(respondent => ({
        label: respondent.userName,
        value: respondent.id
    }));

export const getQuestionFilterList: (state: State) => string[] = state => {
    if (state.selectedConstraintQuestionId === '') {
        return state.selectedDreamTextIds.map(id =>
            extractUuidFromFullPath(id)
        );
    } else {
        return [
            extractUuidFromFullPath(state.selectedConstraintQuestionId),
            ...state.selectedDreamTextIds.map(id => extractUuidFromFullPath(id))
        ];
    }
};

export const getWordCountRangeAsFilter: (
    state: State
) => WordCountFilter = state => {
    const min = parseInt(state.minWordCount);
    const max = parseInt(state.maxWordCount);
    return {
        ...(!isNaN(min) && { gte: min }),
        ...(!isNaN(max) && { lte: max })
    };
};

export const getDateRangeAsFilter: (
    state: State
) => DateRangeFilter = state => {
    const before = state.dateRangeEnd;
    const after = state.dateRangeStart;
    return {
        ...(before !== null && { before: before.format('YYYY-MM-DD') }),
        ...(after !== null && { after: after.format('YYYY-MM-DD') })
    };
};

export function getSurveyFilters (state: State): SurveyFilters {

    const surveyFilters: SurveyFilters = {
        questions: getQuestionFilterList(state),
        respondents: state.selectedRespondentIds.map(id =>
            extractUuidFromFullPath(id)
        ),
    };

    const wordCountRange = getWordCountRangeAsFilter(state);
    if (wordCountRange.lte) {
        surveyFilters['responseSets.answers.wordCount[lte]'] = wordCountRange.lte;
    }
    if (wordCountRange.gte) {
        surveyFilters['responseSets.answers.wordCount[gte]'] = wordCountRange.gte;
    }

    const dateRange = getDateRangeAsFilter(state);
    if (dateRange.before) {
        surveyFilters['responseSets.createdAt[before]'] = dateRange.before;
    }
    if (dateRange.after) {
        surveyFilters['responseSets.createdAt[after]'] = dateRange.after;
    }
    return surveyFilters;
}

export function getQuestionFilters (state: State): QuestionFilters {
    const questionFilters: QuestionFilters = {
        surveys: state.selectedSurveyIds.map(id => extractUuidFromFullPath(id)),
        respondents: state.selectedRespondentIds.map(id =>
            extractUuidFromFullPath(id)
        )
    };

    const wordCountRange = getWordCountRangeAsFilter(state);
    if (wordCountRange.gte !== undefined) {
        questionFilters['answers.wordCount[gte]'] = wordCountRange.gte;
    }
    if (wordCountRange.lte !== undefined) {
        questionFilters['answers.wordCount[lte]'] = wordCountRange.lte;
    }

    const dateRange = getDateRangeAsFilter(state);
    if (dateRange.before) {
        questionFilters['surveys.responseSets.createdAt[before]'] = dateRange.before;
    }
    if (dateRange.after) {
        questionFilters['surveys.responseSets.createdAt[after]'] = dateRange.after;
    }

    return questionFilters;
}

export function getRespondentFilters (
    state: State
): RespondentFilters {
    const respondentFilters: RespondentFilters = {
        surveys: state.selectedSurveyIds.map(id => extractUuidFromFullPath(id)),
        questions: getQuestionFilterList(state)
    };

    const wordCountFilter = getWordCountRangeAsFilter(state);
    if (wordCountFilter.lte) {
        respondentFilters['responseSets.answers.wordCount[lte]'] = wordCountFilter.lte;
    }
    if (wordCountFilter.gte) {
        respondentFilters['responseSets.answers.wordCount[gte]'] = wordCountFilter.gte;
    }

    const dateFilter = getDateRangeAsFilter(state);
    if (dateFilter.after) {
        respondentFilters['responseSets.createdAt[after]'] = dateFilter.after;
    }
    if (dateFilter.before) {
        respondentFilters['responseSets.createdAt[before]'] = dateFilter.before;
    }
    return respondentFilters;
}

export const getKeywordOptionsFromState: OptionGroupsFromState = state =>
    mapKeywordsToOptionGroupsObjects(state.keywords);

export const getSelectedKeywordsFromState: OptionsFromStateCategories = (state, index) =>
    mapKeywordsToOptionGroupsObjects(state.keywords)
        .flatMap(category => category.options)
        .filter(keyword => {
            return state.selectedKeywordIds[index] === undefined ? false : state.selectedKeywordIds[index].includes(keyword.value)
        });

export const getSurveyOptionsFromState: OptionGroupsFromState = state =>
    mapSurveysToOptionGroupsObjects(state.surveys);

export const getSelectedSurveysFromState: OptionsFromState = state =>
    mapSurveysToOptionObjects(
        state.surveys.filter(survey =>
            state.selectedSurveyIds.includes(survey.id)
        )
    );

export const getSelectedDateRangeFromState: OptionsFromState = state =>
    mapSurveysToOptionObjects(
        state.surveys.filter(survey =>
            state.selectedSurveyIds.includes(survey.id)
        )
    );

export const getDreamTextOptionsFromState: (state: State) => Option[] = state =>
    mapStateObjectsToOptionObjects(state.dreamTextQuestions);

export const getDateRangeOptions: () => Option[] = () => {
    return [
        {label: "Month, Day & Year", value: "MM-DD-YYYY"},
        {label: "Month & Year", value: "MM-YYYY"},
        {label: "Year", value: "YYYY"},
        {label: "Century", value: "century"},
    ]
}

export const getLogicOptions: () => Option[] = () => {
    return [
        {label: "OR", value: "or"},
        {label: "NOT", value: "not"},
    ]
}

export const getBCEOptions: () => Option[] = () => {
    return [
        {label: "CE", value: "false"},
        {label: "BCE", value: "true"},
    ]
}

export const getMonthOptions: () => Option[] = () => {
    return [
        {label: "January", value: "01"},
        {label: "February", value: "02"},
        {label: "March", value: "03"},
        {label: "April", value: "04"},
        {label: "May", value: "05"},
        {label: "June", value: "06"},
        {label: "July", value: "07"},
        {label: "August", value: "08"},
        {label: "September", value: "09"},
        {label: "October", value: "10"},
        {label: "November", value: "11"},
        {label: "December", value: "12"},
    ]
}

export const getCenturyOptions: () => ExtendedOption[] = () => {
    return [
        {label: "1st century", value: "01", start: moment("0001-01-01"), end: moment("0100-12-31")},
        {label: "2nd century", value: "02", start: moment("0101-01-01"), end: moment("0200-12-31")},
        {label: "3rd century", value: "03", start: moment("0201-01-01"), end: moment("0300-12-31")},
        {label: "4th century", value: "04", start: moment("0301-01-01"), end: moment("0400-12-31")},
        {label: "5th century", value: "05", start: moment("0401-01-01"), end: moment("0500-12-31")},
        {label: "6th century", value: "06", start: moment("0501-01-01"), end: moment("0600-12-31")},
        {label: "7th century", value: "07", start: moment("0601-01-01"), end: moment("0700-12-31")},
        {label: "8th century", value: "08", start: moment("0701-01-01"), end: moment("0800-12-31")},
        {label: "9th century", value: "09", start: moment("0801-01-01"), end: moment("0900-12-31")},
        {label: "10th century", value: "10", start: moment("0901-01-01"), end: moment("1000-12-31")},
        {label: "11th century", value: "11", start: moment("1001-01-01"), end: moment("1100-12-31")},
        {label: "12th century", value: "12", start: moment("1101-01-01"), end: moment("1200-12-31")},
        {label: "13th century", value: "13", start: moment("1201-01-01"), end: moment("1300-12-31")},
        {label: "14th century", value: "14", start: moment("1301-01-01"), end: moment("1400-12-31")},
        {label: "15th century", value: "15", start: moment("1401-01-01"), end: moment("1500-12-31")},
        {label: "16th century", value: "16", start: moment("1501-01-01"), end: moment("1600-12-31")},
        {label: "17th century", value: "17", start: moment("1601-01-01"), end: moment("1700-12-31")},
        {label: "18th century", value: "18", start: moment("1701-01-01"), end: moment("1800-12-31")},
        {label: "19th century", value: "19", start: moment("1801-01-01"), end: moment("1900-12-31")},
        {label: "20th century", value: "20", start: moment("1901-01-01"), end: moment("2000-12-31")},
        {label: "21st century", value: "21", start: moment("2001-01-01"), end: moment("2100-12-31")},
    ]
}

export const getYearOptions: () => Option[] = () => {
    const currentYear = new Date().getFullYear() + 1;
    return Array.from(Array(currentYear).keys()).map(e => {
        return {
            label: e.toString(), value: e.toString()
        }
    });
}

export const getSelectedDreamTextsFromState: OptionsFromState = state =>
    mapStateObjectsToOptionObjects(
        state.dreamTextQuestions.filter(question =>
            state.selectedDreamTextIds.includes(question.id)
        )
    );

export const getConstraintQuestionsFromState: OptionsFromState = state =>
    mapStateObjectsToOptionObjects(state.constraintQuestions).sort((a, b) => {
        const aWords = a.label.split(' ');
        const firstAWordAsNumber = parseInt(aWords[0]);
        const bWords = b.label.split(' ');
        const firstBWordAsNumber = parseInt(bWords[0]);
       if (!isNaN(firstAWordAsNumber) && isNaN(firstBWordAsNumber) ) {
           return 1
       }
       if (isNaN(firstAWordAsNumber) && !isNaN(firstBWordAsNumber) ) {
           return -1
       }
        if (!isNaN(firstAWordAsNumber) && !isNaN(firstBWordAsNumber)) {
            if (firstAWordAsNumber > firstBWordAsNumber) {
                return -1;
            }
            if (firstBWordAsNumber > firstAWordAsNumber) {
                return 1;
            }
        }
        return 0;
    });

export const getSelectedConstraintQuestionFromState: OptionFromState = state =>
    mapStateObjectsToOptionObjects(state.constraintQuestions).find(
        question => question.value === state.selectedConstraintQuestionId
    ) || null;

export const getConstraintAnswersFromState: OptionsFromState = state => {
    const selectedQuestion = state.constraintQuestions.find(
        question => question.id === state.selectedConstraintQuestionId
    );
    if (selectedQuestion && selectedQuestion.allowedAnswers) {
        return selectedQuestion.allowedAnswers.map(answer => {
            return {
                label: answer,
                value: answer
            };
        });
    } else {
        return [];
    }
};

export const getSelectedConstraintAnswerFromState: OptionFromState = state => {
    if (
        state.selectedConstraintQuestionId === '' ||
        state.selectedConstraintAnswer === ''
    ) {
        return null;
    }
    return {
        label: state.selectedConstraintAnswer,
        value: state.selectedConstraintAnswer
    };
};

export const getRespondentOptionsFromState: OptionsFromState = state =>
    mapRespondentsToOptionObjects(state.respondents);

export const getSelectedRespondentsFromState: OptionsFromState = state =>
    mapRespondentsToOptionObjects(
        state.respondents.filter(respondent =>
            state.selectedRespondentIds.includes(respondent.id)
        )
    );

export const getUrlParamsFromState: (
    state: State
) => Partial<SearchParameters> = state => {
    let parameters: Partial<SearchParameters> = {};
    if (state.freeSearchWords.length > 0) {
        parameters.freeSearchWords = state.freeSearchWords;
    }
    if (state.freeSearchOperators.length > 0) {
        parameters.freeSearchOperators = state.freeSearchOperators;
    }
    if (state.freeSearchInputValue.length > 0) {
        if (parameters.freeSearchWords === undefined) {
            parameters.freeSearchWords = [state.freeSearchInputValue];
        } else {
            parameters.freeSearchWords = [
                ...parameters.freeSearchWords,
                state.freeSearchInputValue
            ];
        }
    }
    if (state.selectedSurveyIds.length > 0) {
        parameters.surveyIds = state.selectedSurveyIds;
        parameters.surveyNames = state.selectedSurveyIds.map(id => {
            const survey = state.surveys.find(survey => survey.id === id);
            return survey ? survey.name : '';
        });
    }
    if (state.selectedDreamTextIds.length > 0) {
        parameters.dreamTextIds = state.selectedDreamTextIds;
        parameters.dreamTexts = state.selectedDreamTextIds.map(id => {
            const question = state.dreamTextQuestions.find(
                question => question.id === id
            );
            return question ? question.text : '';
        });
    }
    if (state.selectedConstraintAnswer !== '') {
        parameters.constraints = [
            {
                questionId: state.selectedConstraintQuestionId,
                answerText: state.selectedConstraintAnswer
            }
        ];
        const question = state.constraintQuestions.find(
            question => question.id === state.selectedConstraintQuestionId
        );
        if (question) {
            parameters.constraintText = `${question.text}: ${state.selectedConstraintAnswer}`;
        }
    }
    if (state.selectedKeywordIds.length > 0) {
        parameters.keywordIds = state.selectedKeywordIds;
        // parameters.keywordTexts = state.selectedKeywordIds.map(id => {
        //     const keyword = state.keywords.find(keyword =>
        //         keyword.subCategories.find(subCategory => subCategory.id === id)
        //     );
        //     if (keyword) {
        //         const subCategory = keyword.subCategories.find(
        //             subCategory => subCategory.id === id
        //         );
        //         if (subCategory) {
        //             return subCategory.name;
        //         }
        //     }
        //     return '';
        // });
    }
    if (state.selectedKeywordOperators.length > 0) {
        parameters.keywordOperators = state.selectedKeywordOperators;
    }
    if (state.minWordCount !== '') {
        parameters.minWordCount = state.minWordCount;
    }
    if (state.maxWordCount !== '') {
        parameters.maxWordCount = state.maxWordCount;
    }
    if (state.selectedRespondentIds.length > 0) {
        parameters.respondentIds = state.selectedRespondentIds;
        parameters.respondentNames = state.selectedRespondentUserNames;
    }
    if (state.dateRangeEnd !== null) {
        parameters.dateRangeEnd = state.dateRangeEnd.format();
    }
    if (state.dateRangeStart !== null) {
        parameters.dateRangeStart = state.dateRangeStart.format();
    }
    if (state.startBce !== null) {
        parameters.startBce = state.startBce;
    }
    if (state.endBce !== null) {
        parameters.endBce = state.endBce;
    }
    return parameters;
};

const selectors = {
    constraintAnswers: getConstraintAnswersFromState,
    constraintQuestions: getConstraintQuestionsFromState,
    dreamTextOptions: getDreamTextOptionsFromState,
    dateRangeOptions: getDateRangeOptions,
    logicOptions: getLogicOptions,
    bceOptions: getBCEOptions,
    monthOptions: getMonthOptions,
    centuryOptions: getCenturyOptions,
    yearOptions: getYearOptions,
    keywordOptions: getKeywordOptionsFromState,
    respondentOptions: getRespondentOptionsFromState,
    selectedConstraintAnswer: getSelectedConstraintAnswerFromState,
    selectedConstraintQuestion: getSelectedConstraintQuestionFromState,
    selectedDreamTexts: getSelectedDreamTextsFromState,
    selectedKeywords: getSelectedKeywordsFromState,
    selectedRespondents: getSelectedRespondentsFromState,
    selectedSurveys: getSelectedSurveysFromState,
    selectedDateRange: getSelectedDateRangeFromState,
    surveyOptions: getSurveyOptionsFromState,
    surveyFilters: getSurveyFilters,
    questionFilters: getQuestionFilters,
    respondentFilters: getRespondentFilters,
    urlParameters: getUrlParamsFromState
};

export default selectors;
