import { Constraint } from './types';

export interface GroupedConstraints {
    questionText: string;
    answerTexts: string[];
}

export const createGroupedConstraintStrings: (
    constraints: Constraint[]
) => string[] = constraints =>
    constraints
        .reduce((groups: GroupedConstraints[], constraint) => {
            const match = groups.findIndex(
                group => group.questionText === constraint.questionText
            );
            if (match === -1) {
                return [
                    ...groups,
                    {
                        questionText: constraint.questionText,
                        answerTexts: [constraint.answerText]
                    }
                ];
            } else {
                groups[match].answerTexts.push(constraint.answerText);
                return groups;
            }
        }, [])
        .map(
            grouped =>
                `${grouped.questionText}: ${grouped.answerTexts.join(', ')}`
        );
