import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { baseFont, breakpoints, colors } from '../../../theme';
import DefaultCopyright from '../../shared/Copyright';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    margin: 0 auto;
    padding-top: 120px;
    @media (max-width: ${breakpoints.smallMax}) {
        padding-bottom: 56px;
    }
`;

export const Hero = styled.div`
    width: calc(100% - 72px);
    margin: 0 auto;
    margin-bottom: 20px;
    padding-bottom: 63.8%;
    height: 0;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        padding-bottom: 0;
        width: calc(100% - 160px);
        height: initial;
        img {
            object-fit: initial;
            position: initial;
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        position: absolute;
        top: 72px;
        right: 0;
        height: 370px;
        width: 350px;
        img {
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
        }
    }
`;

export const Top = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 936px;
        align-items: flex-start;
        min-height: 444px;
        justify-content: flex-start;
        padding-bottom: 36px;
        margin-bottom: 80px;
    }
`;

export const Title = styled.div`
    display: flex;
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 552px;
    }
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    ${baseFont()}
    font-weight: 700;
    color: ${colors.titleGray};
    > div {
        &:first-child {
            margin-right: 26px;
        }
    }
    margin-bottom: 20px;
`;

export const DreamText = styled.p`
    margin-top: 0;
    padding: 0;
    font-family: 'Martel Sans', sans-serif;
    font-weight: 200;
    font-size: 24px;
    text-align: left;
    color: ${colors.titleGray};
    line-height: 1.4;
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 552px;
        font-size: 28px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        padding-bottom: 36px;
    }
    margin-bottom: 36px;
`;

export const TextInfo = styled.div`
    background: ${colors.categoryCards.peach};
    color: ${colors.cornHarvest};
    padding: 20px;
    ${baseFont()}
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        display: flex;
        flex-direction: row;
        width: calc(100% - 160px);
        > div {
            &:first-child {
                width: 220px;
            }
        }
    }
    margin-bottom: 36px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-bottom: 80px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-bottom: 0;
        width: 552px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    > div {
        &:first-child {
            margin-bottom: 20px;
        }
    }
`;

export const Details = styled.div`
    width: 100%;
    background: ${colors.lightestGray};
    @media (max-width: ${breakpoints.smallMax}) {
        margin-bottom: 24px;
    }
`;

export const DetailContents = styled.div`
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 936px;
        padding-right: 400px;
        margin: 0 auto;
    }
    ${baseFont()}
    margin: 0 auto;
    padding: 36px 0;
    color: ${colors.titleGray};
    > div {
        &:not(:last-child):not(:first-child) {
            margin-bottom: 20px;
        }
    }
`;

export const DetailID = styled.div`
    font-weight: 900;
    font-size: 12px;
    color: ${colors.darkGray};
    margin-bottom: 24px;
`;

export const Pair = styled.div`
    > div {
        &:first-child {
            margin-bottom: 8px;
        }
        &:last-child {
            font-weight: 700;
        }
    }
`;

export const Copyright = styled(DefaultCopyright)`
    @media (min-width: ${breakpoints.mediumMin}) {
        margin: 32px 0 24px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin: 64px 0;
    }
`;

export const SurveyName = styled(Link)`
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 552px;
    }
    text-decoration: none;
    outline: none;
    border: none;
    ${baseFont({ size: 22, weight: 700 })}
    margin: 0;
    padding: 0;
    color: ${colors.titleGray};
    &:active,
    &:focus {
        color: ${colors.titleGray};
        outline: none;
        border: none;
        text-decoration: none;
    }
`;
