import React from 'react';
import {
    ButtonRow,
    ClearAllButton as Button,
    ConstraintItemsWrapper,
    ConstraintsMiddleWrapper as MiddleWrapper,
    Counts,
    ZeroConstraintsMessage
} from './styled';
import ConstraintPanel from './ConstraintPanel';
import { Question } from '../../api/types';
import { Constraint } from '../types';
import { Consumer, Runnable } from '../../../types';

interface Props {
    constraintQuestions: Question[];
    onSelectionChange: Consumer<Constraint[]>;
    selectedConstraints: Constraint[];
}

const AddConstraints: React.FC<Props> = ({
    constraintQuestions,
    onSelectionChange,
    selectedConstraints
}) => {
    const isItemSelected: (text: string) => boolean = text =>
        selectedConstraints.find(constraint => constraint.text === text) !==
        undefined;

    const handleSelectionClick: Consumer<Constraint> = clickedConstraint => {
        const selected = isItemSelected(clickedConstraint.text);
        if (selected) {
            onSelectionChange(
                selectedConstraints.filter(
                    constraint => constraint.text !== clickedConstraint.text
                )
            );
        } else {
            onSelectionChange([...selectedConstraints, clickedConstraint]);
        }
    };

    const handleClearAllClick: Runnable = () => {
        onSelectionChange([]);
    };

    const totalAvailableConstraints: () => number = () =>
        constraintQuestions.reduce(
            (sum, question) => sum + (question.allowedAnswers ? question.allowedAnswers.length : 0),
            0
        );

    return (
        <>
            <MiddleWrapper>
                <Counts>
                    {`${totalAvailableConstraints()} constraint${
                        totalAvailableConstraints() === 1 ? '' : 's'
                    } available within your survey selection`}
                </Counts>
                <ButtonRow>
                    <Button onClick={handleClearAllClick}>Clear All</Button>
                </ButtonRow>
            </MiddleWrapper>
            {constraintQuestions.length > 0 && (
                <ConstraintItemsWrapper>
                    {constraintQuestions.map(question => (
                        <ConstraintPanel
                            key={question.id}
                            onConstraintClick={handleSelectionClick}
                            question={question}
                            selectedConstraints={selectedConstraints}
                        />
                    ))}
                </ConstraintItemsWrapper>
            )}
            {constraintQuestions.length === 0 && (
                <ZeroConstraintsMessage>
                    No constraints available.
                </ZeroConstraintsMessage>
            )}
        </>
    );
};

export default AddConstraints;
