import styled from 'styled-components';
import { breakpoints } from '../../../theme';
import CopyRight from '../../shared/Copyright';
import { baseInputStyles } from '../../shared/styled/FormInput';
import oldButton from '../../shared/Button';
import MuiInput from '@material-ui/core/TextField';
import MuiButton from '@material-ui/core/Button';
import DefaultPageTitle from '../../shared/PageTitle';
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 552px;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
    @media (max-width: 515px) {
        transition: all 0.5s;
        padding-bottom: 20px;
    }
    @media (max-width: 768px) {
        width: 452px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 552px;
    }
`;

export const PageTitle = styled(DefaultPageTitle)`
    font-size: 28px !important;
    font-weight: lighter !important;
    height: 43px !important;
    @media (max-width: 768px) {
        font-size: 24px !important;
        font-weight: lighter !important;
        line-height: 34px !important;
        height: 43px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        letter-spacing: 0px;
    }
`;

export const StyledCopyRight = styled(CopyRight)`
    position: absolute;
    margin-top: 0px;
    @media (max-height: 810px) {
        position: inherit;
    }
    @media (max-width: 666px) {
        position: inherit;
        margin: 0;
        padding: 12px 0 36px 0;
    }
    @media (min-width: 667px) {
        margin-bottom: 44px;
    }
    bottom: 0;
    width: 100%;
    height: 21px;
    left: 0;
`;

export const Page = styled.div`
    min-height: calc(100% - 48px);
    min-width: 280 px;
    height: fit-content;
    overflow: auto;
    position: relative;
    width: 100%;
    padding: 0 36px 0 35px;
`;

export const SubHeader = styled.p`
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    font-family: Nunito Sans;
    text-align: center;
    margin: 0 auto;
    color: #2C2C2C;
    opacity: 100%;
    min-height: 78px
}
`;
export const ThankYouSubHeader = styled.p`
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    font-family: Nunito Sans;
    text-align: center;
    margin: 0 auto;
    color: #2C2C2C;
    opacity: 100%;
    min-height: 43px;
    margin-bottom: 11px;
    @media (max-width: 768px) {
        max-width: 306px !important;
        min-height: 59px;
        margin-bottom: 15px;
    }
}
`;
export const Header = styled.div`
    height: 43px;
    width: 360px;
    margin: 70px auto 18px auto;
    @media (max-width: 666px) {
        margin-top: 102px;
    }
    max-width: 100%;
`;
export const ThankYouHeader = styled.div`
    height: 43px;
    width: 360px;
    margin: 22px auto 13px auto;
    max-width: 100%;
`;
export const Content = styled.div`
    width: 100%;
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const ThankYouContent = styled.div`
    width: 100%;
    display: flex;
    margin-top: 108px;
    flex-direction: column;
    align-items: center;
`;

export const Input = styled(MuiInput)`
    width: 100%;
    label {
        max-width: 90%;
        font-family: Nunito Sans !important;
        transform: translate(18px, 20px) scale(1);
        transition: all 0.3s;
        &[data-shrink='true'] {
            transition: all 0.3s;
            transform: translate(18px, 9px) scale(1) !important;
            font-size: 11px !important;
            height: 31px;
            letter-spacing: 0.1px;
            line-height: 16px;
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #2c2c2c;
    }
    &.MuiFormControl-root {
        margin-bottom: 12px !important;
    }
    .MuiInputBase-root {
        ${baseInputStyles()}
        width: 100%;
        color: #2c2c2c;
        background-color: #f4f4f4;
        min-height: 56px;
        padding: 20px 18px;
        font-family: 'Nunito Sans' !important;
        font-size: 18px !important;
        &.Mui-error {
            border: 1px solid red !important;
        }
    }
    .MuiInputBase-input {
        padding: 27px 0 10px;
        letter-spacing: 0.18px !important;
    }
`;
export const TextBoxInput = styled(Input)`
    .MuiInputBase-root {
        min-height: 114px !important;
        height: fit-content !important;
        max-height: 210px;
        resize: none !important;
        align-items: baseline !important;
        padding: 28px 17px 15px 18px !important;
        @media (max-width: 471px) {
            padding-top: 40px !important;
        }
    }
    textarea {
        min-height: 3.5em;
        max-height: 172px !important;
        @media (max-width: 471px) {
            max-height: 160px !important;
        }
        overflow: auto !important;
    }
    .MuiInputBase-input {
        padding: 0;
        max-height: 100%;
        overflow: auto !important;
    }
`;
export const ButtonWrapper = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 515px) {
        margin-top: 25px;
        flex-direction: column-reverse;
    }
`;
export const Button = styled(oldButton)`
    margin: 0;
    color: #2c2c2c;
    width: 197px;
    max-width: 100%;
    // white-space: nowrap;
    padding: 10px;
    text-align: center;

    @media (max-width: 515px) {
        margin: auto;
        &:last-child {
            margin-bottom: 12px;
        }
    }
    &:hover {
        background-color: #2c2c2c !important;
    }
    &:disabled {
        cursor: not-allowed;
        color: #707070 !important;
        border-color: #707070 !important;
        background-color: transparent !important;
    }
`;

export const TextButton = styled(MuiButton)`
    text-transform: none !important;
    padding: 0px !important;
    color: #2c2c2c !important;
    font-family: Nunito Sans !important;
    // font-weight: bold !important;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    position: absolute !important;
    line-height: 18px !important;
    top: 38px;
    left: 33px;
    &:hover {
        background-color: transparent !important;
    }
    @media (min-width: 667px) {
        display: none !important;
    }
    .MuiSvgIcon-root {
        font-size: 24px !important;
    }
`;

export const ThankYouIconWrapper = styled.div`
    height: 175px;
`;
export const ExportButtonWrapper = styled.div`
    margin-top: 22px;
`;
export const FullWrapper = styled.div`
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
    @media (min-width: 667px) {
        height: calc(100vh - 72px);
    }
    @media (max-width: 666px) {
        min-height: 100vh;
        height: fit-content;
        padding-bottom: 56px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;
