import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { baseFont, breakpoints, colors } from '../../../theme';

export const TemplateCardWrapper = styled.div`
    width: calc(100% - 72px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 192px);
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1128px;
    }
`;

export const TemplateCard = styled(DefaultLink)`
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 64px;
    img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(50% - 8px);
        &:nth-child(even) {
            margin-left: 8px;
        }
        &:nth-child(odd) {
            margin-right: 8px;
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 360px;
        &:nth-child(3n + 1) {
            margin-left: 0;
            margin-right: 12px;
        }
        &:nth-child(3n + 2) {
            margin-left: 12px;
            margin-right: 12px;
        }
        &:nth-child(3n + 3) {
            margin-left: 12px;
            margin-right: 0;
        }
    }
    h2,
    h3,
    p {
        text-decoration: none;
        ${baseFont()}
        padding: 0;
        margin: 0;
    }
    h2 {
        color: ${colors.black};
        font-size: 16px;
    }
    h3 {
        color: ${colors.lightGray};
        margin-bottom: 40px;
    }
    p {
        color: ${colors.titleGray};
        /* to make it look good for launch with no highlight text */
        /* @TODO - add back once there's highlight text to show */
        /* margin-top: 40px;
        margin-bottom: 16px; */
        margin: 8px 0;
    }
    button {
        text-decoration: none;
        outline: none;
        border: none;
        color: ${colors.cornHarvest};
        ${baseFont({ weight: 700 })}
        background: transparent;
        text-align: left;
        margin: 0;
        padding: 8px 0 0 0;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            outline: none;
            border: none;
            svg {
                margin-left: 12px;
            }
        }
        svg {
            transition: all 0.2s ease-in-out;
            margin-bottom: -7px;
            margin-left: 4px;
        }
    }
`;
