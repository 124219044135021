import {
    ActionType,
    Coordinate,
    Parameters,
    Reducer,
    State,
    TruthTable,
    SetAxisDataPayload
} from './types';
import { graphAreaWidth } from './constants';
import { createGroupedConstraintStrings } from '../utils';

const defaultParameters: Parameters = {
    surveyIds: [],
    xAxisQuestionId: '',
    yAxisQuestionId: '',
    constraints: [],
    title: ''
};

export const initialState: State = {
    barWidth: 0,
    barPadding: 0,
    colorScale: [],
    constraintAnswers: [],
    countsByXAxisIndex: [],
    data: [],
    hovering: false,
    hoveringTable: [],
    modalTrigger: null,
    modalVisible: false,
    parameters: defaultParameters,
    surveyMeta: { respondentCount: 0 },
    totalsByXAxisIndex: [],
    xAxisLabelWidth: 0,
    xAxisAnswers: [],
    xAxisQuestion: '',
    yAxisAnswers: [],
    yAxisQuestion: ''
};

export const reducer: Reducer = (state, action) => {
    switch (action.type) {
        case ActionType.setParams:
            return {
                ...state,
                parameters: action.payload,
                constraintAnswers: createGroupedConstraintStrings(
                    action.payload.constraints
                )
            };
        case ActionType.setData:
            return {
                ...state,
                ...action.payload,
                ...extractStateValuesFromAxisData(action.payload)
            };
        case ActionType.setHovering:
            return {
                ...state,
                hovering: action.payload.hovering,
                hoveringTable: getNewHoverTable(
                    state.hoveringTable,
                    action.payload.coordinate
                )
            };
        case ActionType.setModalVisible:
            return {
                ...state,
                modalVisible: action.payload.visible,
                modalTrigger: action.payload.source
            };
        default:
            return state;
    }
};

const extractStateValuesFromAxisData: (
    data: SetAxisDataPayload
) => Partial<State> = data => {
    const numberOfBars = data.xAxisAnswers.length;
    const section = graphAreaWidth / numberOfBars;
    const barWidth = section * 0.52;
    const barPadding = section - barWidth;
    const hoveringTable = data.yAxisAnswers.map(_ =>
        data.xAxisAnswers.map(_ => false)
    );
    return {
        barWidth,
        barPadding,
        xAxisLabelWidth: section,
        hoveringTable
    };
};

const getNewHoverTable: (
    table: TruthTable,
    coordinate: Coordinate
) => TruthTable = (table, coordinate) => {
    const newTable = [...table];
    newTable[coordinate.x][coordinate.y] = !table[coordinate.x][coordinate.y];
    return newTable;
};
