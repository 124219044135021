import { Constraint } from '../types';
import { GroupedConstraint } from '../builder/types';
import { extractUuidFromFullPath } from '../../../utils';

export const combineConstraints: (
    constraints: Constraint[]
) => GroupedConstraint[] = constraints =>
    constraints
        .map(constraint => {
            return {
                questionId: extractUuidFromFullPath(constraint.questionId),
                answerText: [constraint.answerText]
            };
        })
        .reduce(
            (
                accum: GroupedConstraint[],
                currentConstraint: GroupedConstraint
            ) => {
                if (accum.length === 0) {
                    accum.push(currentConstraint);
                    return accum;
                }
                const match = accum.findIndex(
                    constraint =>
                        constraint.questionId === currentConstraint.questionId
                );
                if (match === -1) {
                    accum.push(currentConstraint);
                    return accum;
                } else {
                    accum[match].answerText.push(
                        currentConstraint.answerText[0]
                    );
                    return accum;
                }
            },
            []
        );
