import React from 'react';
import {
    AlertWrapper,
    Modal,
    ModalButtonWrapper,
    ModalContents
} from '../analysis/results/styled';
import AlertIcon from '../shared/Alert';

interface Props {
    onContinueClick: () => void;
    onModalClose: () => void;
    trigger: boolean;
    showIcon: boolean;
    body?: string;
    header?: string;
}

const AlertModal: React.FC<Props> = ({
    onContinueClick,
    onModalClose,
    trigger,
    showIcon,
    body,
    header
}) => (
    <Modal open={trigger} onClose={onModalClose}>
        <ModalContents>
            {showIcon && (
                <AlertWrapper>
                    <AlertIcon />
                </AlertWrapper>
            )}
            {header !== '' && <h2>{header}</h2>}
            {body !== '' && (
                <p>
                    <span>Important info: </span>
                    {body}
                </p>
            )}
            <ModalButtonWrapper>
                <button onClick={onModalClose}>Cancel</button>
                <button onClick={onContinueClick}>Continue</button>
            </ModalButtonWrapper>
        </ModalContents>
    </Modal>
);

export default AlertModal;
