import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../theme';
import { ButtonName } from './MobileNavigationMenu';

interface NavButtonProps {
    external?: boolean;
    icon: React.ReactElement;
    name: ButtonName;
    path: string;
}

const linkStyles = () => `
    outline: none;
    border: none;
    background: transparent;
    width: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${colors.lightGray};
    span {
        display: none;
        font-family: 'Arial Regular', Helvetica, sans-serif;
        font-size: 10px;
        color: ${colors.white};
    }
    &:hover {
        color: ${colors.white};
        text-decoration: none;
    }
    &.active {
        color: ${colors.white};
        span {
            display: inline;
        }
        text-decoration: none;
    }
`;

const Button = styled(NavLink)`
    ${linkStyles()}
`;

const ExternalLink = styled.a`
    ${linkStyles()}
`;

const MobileNavButton: React.FC<NavButtonProps> = ({
    external = false,
    icon,
    name,
    path
}) =>
    !external ? (
        <Button exact={name === ButtonName.Feed} to={path}>
            <>
                {icon}
                {<span>{name}</span>}
            </>
        </Button>
    ) : (
        <ExternalLink href={path} target="_blank">
            {icon}
        </ExternalLink>
    );

export default MobileNavButton;
