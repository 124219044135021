import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, colors } from '../../theme';
import { ButtonName } from './MobileNavigationMenu';

interface NavButtonProps {
    icon: React.ReactElement;
    name: ButtonName;
    path: string;
}

const Button = styled(NavLink)`
    outline: none;
    border: none;
    background: transparent;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${colors.lightGray};
    text-decoration: none;
    svg {
        opacity: 0.5;
    }
    span {
        display: none;
        @media (min-width: ${breakpoints.largeMin}) {
            display: inline;
        }
        opacity: 1;
        font-size: 14px;
        color: ${colors.darkGray};
    }
    &:hover {
        color: ${colors.darkGray};
        text-decoration: none;
        svg {
            opacity: 1;
        }
    }
    &.active {
        color: ${colors.darkGray};
        font-weight: bold;
        svg {
            opacity: 1;
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {

        span {
            margin-left: 10px;
        }
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const NonMobileNavigationButton: React.FC<NavButtonProps> = ({
    icon,
    name,
    path
}) => {
    return (
        <Button to={path} style={{marginRight: 20}}>
            <>
                {icon}
                <span>{name}</span>
            </>
        </Button>
    );
};

export default NonMobileNavigationButton;
