import { FreeSearchAnswer } from './api';
import { AnswerWithHighlight } from '../../../types';

export const transformSearchResponseAnswers: (
    answers: FreeSearchAnswer[]
) => AnswerWithHighlight[] = answers =>
    answers.map(answer => ({
        answerText: answer._source.answer_text,
        id: answer._source.id,
        question: answer._source.question,
        responseSet: answer._source.response_set.id,
        sample: answer.highlight.answer_text[0]
    }));
