import styled from 'styled-components';
import { baseFont, breakpoints, colors } from '../../theme';

export const Navigation = styled.nav`
    @media (max-width: ${breakpoints.smallMax}) {
        display: none;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 100%;
        max-width: ${breakpoints.largeMin};
        margin: 0 auto;
    }
    text-align: center;
    padding: 0 34px;
    ${baseFont()}
    height: 72px;
    color: ${colors.darkGray};
    box-sizing: border-box;
`;

export const Block = styled.div`
    width: 180px;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 500px;
    }
`;

export const Left = styled(Block)`
    position: relative;
    text-align: left;
    font-size: 14px;
    z-index: 1;
    a {
        border: none;
        outline: none;
        text-decoration: none;
        color: ${colors.darkGray};
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: ${colors.darkGray};
        }
    }
`;

export const ExternalLink = styled.a`
    @media (max-width: ${breakpoints.mediumMax}) {
        position: absolute;
        left: 0;
        bottom: -24px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-left: 48px;
    }
`;

export const Center = styled.div`
    z-index: 1;
    flex-grow: 1;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 14px;
    }
`;

export const Right = styled(Block)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    svg {
        height: 16px;
        width: 16px;
    }
`;
