import React  from 'react';
import { Answer, Question } from '../api/types';
import styled from 'styled-components';
import QuestionEntry from './QuestionEntry';
import DreamEntry from './DreamEntry';
import { Moment } from 'moment';

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

//id to props
interface Props {
    question: Question,
    addAnswer: (answer: Answer) => void,
}

const SurveyQuestion: React.FC<Props> = ({ question, addAnswer }) => {
    console.log('question', question);

    const handleSelect = (answer: string, date: Moment|null) => {
        addAnswer({
            id: '',
            question,
            answerText: answer,
            wordCount: 0,
            responseSet: '',
            createdAt: date ? date.toISOString() : '',
            isBce: false
        });
    };

    return (
        <div>
            <Content>
                {!question.isDreamEntry ? (
                    <QuestionEntry question={question} handleSelect={handleSelect} />
                ) : (<DreamEntry question={question} handleSelect={handleSelect} />)}
            </Content>
        </div>
    );
};

export default SurveyQuestion;
