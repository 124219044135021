import React from 'react';
import styled from 'styled-components';
import { baseFont, breakpoints, colors } from '../../theme';

const Container = styled.p`
    margin: 12px 0 36px 0;
    padding: 0 16px;
    ${baseFont({ size: 12 })}
    color: ${colors.black};
    background: transparent;
    text-align: center;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin: 64px 0 48px 0;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin: 168px 0 56px 0;
    }
`;

const date = new Date();
const Copyright: React.FC<{ className?: string }> = ({ className }) => (
    <Container className={className}>
        &copy; {date.getFullYear()}. Sleep and Dream Database. All Rights Reserved.
    </Container>
);

export default Copyright;
