import styled, { css } from 'styled-components';
import { baseFont, breakpoints, colors, Theme } from '../../../theme';
import { HintStatus } from '../FormInput';

interface StyledInputProps {
    dual: boolean;
    hintStatus: HintStatus;
    theme: Theme;
}

export const Container = styled.div<{ dual: boolean }>`
    box-sizing: border-box;
    display: ${p => (p.dual ? 'block' : 'flex')};
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const DualContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const SingleInputContainer = styled.div<{ dual: boolean }>`
    position: relative;
    width: ${p => (p.dual ? 'calc(50% - 8px)' : '100%')};
    @media (min-width: ${breakpoints.largeMin}) {
        width: ${p => (p.dual ? 'calc(50% - 12px)' : '100%')};
    }
`;

export const Hint = styled.div<{ status: HintStatus; theme: Theme }>`
    position: absolute;
    top: 16px;
    left: 16px;
    color: ${colors.cornHarvest};
    ${baseFont({ size: 18 })}
    opacity: 0;
    z-index: 1;
    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    pointer-events: none;
    ${p =>
        !(p.status === HintStatus.hidden) &&
        css`
            opacity: 1;
            top: 8px;
            font-size: 11px;
        `};
    ${p =>
        p.status === HintStatus.complete &&
        css`
            color: ${p.theme === Theme.dark
                ? colors.white
                : 'rgba(44, 44, 44, 0.6)'};
        `};
    ${p =>
        p.status === HintStatus.error &&
        css`
            color: ${colors.bittersweet};
        `};
`;

export const baseInputStyles = () => css`
    width: 100%;
    box-sizing: border-box;
    background: ${p =>
        p.theme === Theme.dark ? colors.darkGray : colors.lightestGray};
    outline: none;
    border: none;
    border-radius: 4px;
    height: 56px;
    padding: 20px 16px;
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.darkGray)};
    ${baseFont({ size: 18 })}
    caret-color: ${colors.cornHarvest};
    &:invalid {
        border: none;
        outline: none;
        box-shadow: none;
    }
    ::-moz-placeholder {
        color: ${colors.darkGray};
        opacity: 0.7;
        text-overflow: ellipsis;
    }
    :-ms-input-placeholder {
        color: ${colors.darkGray};
        opacity: 0.7;
        text-overflow: ellipsis;
    }
    :-moz-placeholder {
        color: ${colors.darkGray};
        opacity: 0.7;
        text-overflow: ellipsis;
    }
    ${p =>
        p.theme === Theme.dark &&
        css`
            ::-webkit-input-placeholder {
                color: ${colors.white};
            }
            ::-moz-placeholder {
                color: ${colors.white};
            }
            :-ms-input-placeholder {
                color: ${colors.white};
            }
            :-moz-placeholder {
                color: ${colors.white};
            }
        `}
`;

export const Input = styled.input<StyledInputProps>`
    ${baseInputStyles()}
    ${p =>
        !(p.hintStatus === HintStatus.hidden) &&
        css`
            padding: 28px 16px 12px;
        `}
    ::-webkit-input-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    ::-moz-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    :-ms-input-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    :-moz-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
`;

export const TextArea = styled.textarea<StyledInputProps>`
    ${baseInputStyles()}
    height: 120px;
    padding: 10px;
  font-size: 14px;
    ::-webkit-input-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    ::-moz-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    :-ms-input-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
    :-moz-placeholder {
        opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 0.7)};
    }
`;

export const Label = styled.label<{ dual?: boolean; theme: Theme }>`
    ${baseFont()}
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 16px 0;
    color: ${p =>
        p.theme === Theme.dark
            ? 'rgba(255, 255, 255, 0.6)'
            : 'rgba(44, 44, 44, 0.6)'};
    ${p =>
        p.dual &&
        css`
            display: block;
            width: 100%;
        `}
`;
export const CenteredLabel = styled.label<{ dual?: boolean; theme: Theme }>`
    ${baseFont()}
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0;
    padding-top: 40px;
    margin: 0 0 16px 0;
    color: ${p =>
  p.theme === Theme.dark
    ? 'rgba(255, 255, 255, 0.6)'
    : 'rgba(44, 44, 44, 0.6)'};
    ${p =>
  p.dual &&
  css`
            display: block;
            width: 100%;
        `}
`;