import React from 'react';
import {
    Summary as SummaryContainer,
    SummaryDataCell,
    SummaryLabelCell
} from './styled';
import Input from '../../shared/FormInput';
import { Summary } from './types';
import { Consumer } from '../../../types';

interface Props {
    onInputChange: Consumer<string>;
    summaryData: Summary;
    value: string;
}
const NameAndCreate: React.FC<Props> = ({
    onInputChange,
    summaryData,
    value
}) => {
    return (
        <>
            <Input
                label={''}
                onInputChange={value => onInputChange(value as string)}
                onValidityChange={() => null}
                placeholder="Analysis Title"
                validity={[true]}
                value={value}
            />
            <SummaryContainer>
                <SummaryLabelCell>Data</SummaryLabelCell>
                <SummaryDataCell>
                    {summaryData.surveyNames.map(
                        (name, i) =>
                            `${name}${
                                i + 1 === summaryData.surveyNames.length
                                    ? ''
                                    : ', '
                            }`
                    )}
                </SummaryDataCell>
                <SummaryLabelCell>X Axis</SummaryLabelCell>
                <SummaryDataCell>
                    {summaryData.xAxisQuestionText}
                </SummaryDataCell>
                <SummaryLabelCell>Y Axis</SummaryLabelCell>
                <SummaryDataCell>
                    {summaryData.yAxisQuestionText}
                </SummaryDataCell>
                <SummaryLabelCell>Constraints</SummaryLabelCell>
                <SummaryDataCell>
                    {summaryData.constraintAnswerTexts.length === 0 &&
                        `No constraints selected`}
                    {summaryData.constraintAnswerTexts.length > 0 &&
                        summaryData.constraintAnswerTexts.join('; ')}
                </SummaryDataCell>
            </SummaryContainer>
        </>
    );
};

export default NameAndCreate;
