import React, {Reducer, useEffect, useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Moment} from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import {getPercentageText, transformWordSearchParametersToFilters} from './utils';
import {ActionType, ActionTypes, Metadata, State} from './types';
import {initialState, reducer} from './state';
import select, {Option} from './selectors';
import Title from '../shared/PageTitle';
import FreeSearch, {FiltersWrapper} from './FreeSearch';
import Multiselect from '../shared/MultiSelect';
import Select from '../shared/Select';
import {CenteredLabel, Label} from '../shared/styled/FormInput';
import FormInput from '../shared/FormInput';
import DatePicker from '../shared/DatePicker';
import SurveySummary from './SurveySummary';
import Tooltip from '../shared/Tooltip';
import {
    BlockContainer,
    BorderlessButton,
    Bottom,
    Button,
    ButtonContainer,
    ButtonRow,
    Column,
    Contents,
    Copyright,
    DataSetMetadata,
    FeaturedInputsWrapper,
    FlexContainer,
    IconWrapper,
    InputsContainer,
    MultiContainer,
    Panel,
    SearchMetadata,
    SectionTitle,
    SinglePickerContainer,
    SingleSelectWrapper,
    Summary,
    Top,
    Wrapper
} from './styled';
import YearMonthPicker from "../shared/YearMonthPicker";
import CenturyPicker from "../shared/CenturyPicker";
import {Theme} from "../../theme";
import AddCircle from "@material-ui/icons/AddCircle";
import {FilterButton} from "../shared/styled/SearchInput";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import useWordSearch from "../api/useWordSearch";
import {ApiType, Question, Survey, WordSearchRequest, WordTemplate} from "../api/types";
import useApiGet from "../api/useApiGet";

const AdvancedSearch: React.FC = () => {
    let history = useHistory();

    const [state, dispatch] = useReducer<Reducer<State, ActionTypes>>(reducer, initialState);
    const [metadata, setMetadata] = useState<Metadata>({
        dataSetEntries: 0,
        dataSetParticipants: 0,
        dataSetPercentage: '0%',
        searchEntries: 0,
        searchParticipants: 0,
        searchPercentage: '0%',
        totalEntries: 0,
        totalParticipants: 0
    });

    const [dateRangeSelector, setDateRangeSelector] = useState<Option | undefined>();
    const [freeSearchWords, setFreeSearchWords] = useState<string[][]>([[]]);
    const [freeSearchInputs, setFreeSearchInputs] = useState<string[]>(['']);
    const [last, setLast] = useState(0);
    const [lastCat, setLastCat] = useState(0);
    const [operators, setOperators] = useState<Option[]>([{label: '', value: ''}]);
    const [operatorsCat, setOperatorsCat] = useState<Option[]>([{label: '', value: ''}]);

    const [categoriesFilter, setCategoriesFilter] = useState<string[][]>([[]]);

    const {
        data: totalCountsData,
        loading: totalCountsLoading,
        error: totalCountsError
    } = useWordSearch({
        metadataOnly: true
    });

    const currentDataSetCountsRequest: Partial<WordSearchRequest> = {
        ...transformWordSearchParametersToFilters(
            select.urlParameters(state)
        ),
        metadataOnly: true
    };
    delete currentDataSetCountsRequest['searchText'];
    delete currentDataSetCountsRequest['searchTextOperators'];
    delete currentDataSetCountsRequest['subCategoryIds'];
    delete currentDataSetCountsRequest['subCategoryOperators'];

    const {
        data: currentDataSetCountsData,
        loading: currentDataSetCountsLoading,
        error: currentDataSetCountsError
    } = useWordSearch(currentDataSetCountsRequest);

    const {
        data: currentSearchCountsData,
        loading: currentSearchCountsLoading,
        error: currentSearchCountsError
    } = useWordSearch(
        {
            ...transformWordSearchParametersToFilters(
                select.urlParameters(state)
            ),
            metadataOnly: true,
        }
    );

    useEffect(() => {
        if (
            !totalCountsError &&
            !totalCountsLoading &&
            totalCountsData &&
            !currentDataSetCountsError &&
            !currentDataSetCountsLoading &&
            currentDataSetCountsData &&
            !currentSearchCountsError &&
            !currentSearchCountsLoading &&
            currentSearchCountsData
        ) {
            const totalEntries =
                totalCountsData.dreamEntryCount;
            const totalParticipants =
                totalCountsData.respondentCount;
            const dataSetEntries =
                currentDataSetCountsData.dreamEntryCount;
            const dataSetParticipants =
                currentDataSetCountsData.respondentCount;
            const searchEntries =
                currentSearchCountsData.dreamEntryCount;
            const searchParticipants =
                currentSearchCountsData.respondentCount;
            let dataSetPercentage = '0%';
            let searchPercentage = '0%';
            if (totalEntries > 0 && dataSetEntries > 0) {
                dataSetPercentage = getPercentageText(
                    dataSetEntries,
                    totalEntries
                );
            }
            if (dataSetEntries > 0 && searchEntries > 0) {
                searchPercentage = getPercentageText(
                    searchEntries,
                    dataSetEntries
                );
            }
            setMetadata({
                dataSetEntries,
                dataSetParticipants,
                dataSetPercentage,
                searchEntries,
                searchParticipants,
                searchPercentage,
                totalEntries,
                totalParticipants
            });
        }
    }, [
        totalCountsData,
        totalCountsError,
        totalCountsLoading,
        currentDataSetCountsData,
        currentDataSetCountsError,
        currentDataSetCountsLoading,
        currentSearchCountsData,
        currentSearchCountsError,
        currentSearchCountsLoading
    ]);

    //todo: the original query had surveys and then surveys with dream entries. Difference?
    const { data: surveys } = useApiGet<Survey>({
        ...select.surveyFilters(state),
        enabled: true
    }, ApiType.Survey);
    useEffect(() => {
        if (surveys) {
            dispatch({ type: ActionType.setSurveys, payload: surveys });
        }
    }, [surveys]);

    /* Keywords */

    const { data: wordTemplates, loading: wordTemplateLoading } = useApiGet<WordTemplate>({
        version: 2
    }, ApiType.WordTemplate);
    const wordTemplate = wordTemplates && wordTemplates.length ? wordTemplates[0] : null;

    useEffect(() => {
        if (wordTemplate) {
            dispatch({
                type: ActionType.setKeywordCategories,
                payload: wordTemplate.categories
            });
        }
    }, [wordTemplates]);

    const { data: questions } = useApiGet<Question>({
        ...select.questionFilters(state),
        isDreamEntry: true
    }, ApiType.Question);

    const { data: constraints } = useApiGet<Question>({
        ...select.questionFilters(state),
        isDreamEntry: false,
        enabled: true
    }, ApiType.Question);

    useEffect(() => {
        if (questions && constraints) {
            dispatch({
                type: ActionType.setQuestions,
                payload: {
                dreamTextQuestions: questions,
                    constraintQuestions: constraints}
            });
        }
    }, [questions, constraints]);

    const handleFreeSearchInputChange = (input: string[]) => {
        dispatch({
            type: ActionType.setFreeSearchInputValue,
            payload: input
        });
    };

    const handleFreeSearchWordChange = (words: string[][]) => {
        dispatch({
            type: ActionType.setSelectedFreeSearchWords,
            payload: words
        });
    };

    const handleFreeSearchOperatorChange = (operators: string[]) => {
        dispatch({
            type: ActionType.setSelectedFreeSearchOperators,
            payload: operators
        });
    };

    const handleKeywordOperatorChange = (operators: string[]) => {
        dispatch({
            type: ActionType.setSelectedKeywordOperators,
            payload: operators
        });
    };

    const handleKeywordSelectUpdate = (ids: string[], index: number) => {
         let localCategories = [...categoriesFilter];
         localCategories[index] = [...ids];
         setCategoriesFilter(localCategories);
        handleKeywordSelectChange(localCategories);
    };

    const handleKeywordSelectChange = (ids: string[][]) => {
        dispatch({
            type: ActionType.setSelectedKeywordIds,
            payload: ids
        });
    };



    const handleSurveySelectChange = (ids: string[]) => {
        dispatch({ type: ActionType.setSelectedSurveyIds, payload: ids });
    };

    const handleDreamTextSelectChange = (ids: string[]) => {
        dispatch({ type: ActionType.setSelectedDreamTextIds, payload: ids });
    };

    const handleConstraintQuestionSelectChange = (id: string) => {
        dispatch({
            type: ActionType.setSelectedConstraintQuestionId,
            payload: id
        });
        dispatch({
            type: ActionType.setSelectedConstraintAnswer,
            payload: ''
        });
    };

    const handleConstraintAnswerSelectChange = (answer: string) => {
        dispatch({
            type: ActionType.setSelectedConstraintAnswer,
            payload: answer
        });
    };

    const handleWordCountRangeChange = (counts: string[]) => {
        dispatch({
            type: ActionType.setWordCounts,
            payload: { min: counts[0], max: counts[1] }
        });
    };

    const handleWordCountValidityChange = (validity: boolean[]) => {
        dispatch({
            type: ActionType.setWordCountValidity,
            payload: validity
        });
    };

    const handleDateRangeSelectChange = (id: string) => {
        dispatch({ type: ActionType.setDateRange, payload: id });
    };

    const handleDateRangeStartChange = (date: Moment | null, isBce: string) => {
        dispatch({ type: ActionType.setDateRangeStart, payload: date });
        dispatch({ type: ActionType.setStartBce, payload: isBce });
    };

    const handleDateRangeEndChange = (date: Moment | null, isBce: string) => {
        dispatch({ type: ActionType.setDateRangeEnd, payload: date });
        dispatch({ type: ActionType.setEndBce, payload: isBce });
    };

    const handleSearchClick = () => {
        const searchParameters = select.urlParameters(state);
        const params = encodeURIComponent(JSON.stringify(searchParameters));
        history.push(
            `/search_results/${
                Object.keys(searchParameters).length ? params : ''
            }`
        );
    };

    const handleResetClick = () => {
        dispatch({ type: ActionType.reset });
    };

    const handleCancelClick = () => {
        history.push('/');
    };

    useEffect(() => {
        handleFreeSearchOperatorChange(operators.map(o => o.value));

    }, [operators]);
    useEffect(() => {
        handleKeywordOperatorChange(operatorsCat.map(o => o.value));

    }, [operatorsCat]);

    return (
        <>
            <Wrapper>
                <Top>
                    <Title>Advanced Search</Title>
                    <Button onClick={handleSearchClick}>Search</Button>
                </Top>
                <Panel>
                    <Summary
                        expandIcon={
                            <IconWrapper>
                                <ExpandMoreIcon color="inherit" />
                            </IconWrapper>
                        }
                    >
                        <SectionTitle>Define Data Set</SectionTitle>
                    </Summary>
                    <Contents>
                        <DataSetMetadata>
                            <div>
                                <span>Data Set:</span>
                                <span>
                                    {metadata.dataSetParticipants} /{' '}
                                    {metadata.totalParticipants} Participants
                                </span>
                            </div>
                            <div>
                                <span>
                                    {metadata.dataSetEntries} /{' '}
                                    {metadata.totalEntries} Entries
                                </span>
                                <span>
                                    {metadata.dataSetPercentage} of total
                                    library
                                </span>
                            </div>

                            <Tooltip
                                id="data-set-tooltip"
                                trigger={<InfoIcon color="inherit" />}
                            >
                                <p>
                                    Search a subset of the library by selecting
                                    filters below.
                                </p>
                                <p>
                                    Alternately, apply no filters to the data
                                    set and search the entire library.
                                </p>
                            </Tooltip>
                        </DataSetMetadata>
                        <InputsContainer>
                            <Column>
                                <MultiContainer>
                                    <Label>Surveys & Journals</Label>
                                    <Multiselect
                                        handleChange={handleSurveySelectChange}
                                        nested
                                        options={select.surveyOptions(state)}
                                        placeholder="Select a Survey or Journal"
                                        values={select.selectedSurveys(state)}
                                    />
                                </MultiContainer>
                                {state.selectedSurveyIds.map((id: any) => {
                                    const survey = state.surveys.find(
                                        (survey: { id: any; }) => survey.id === id
                                    );
                                    return (
                                        survey && (
                                            <SurveySummary
                                                key={survey.id}
                                                survey={survey}
                                            />
                                        )
                                    );
                                })}
                                <MultiContainer>
                                    <Label>Dream Text</Label>
                                    <Multiselect
                                        handleChange={
                                            handleDreamTextSelectChange
                                        }
                                        options={select.dreamTextOptions(state)}
                                        placeholder="Select Dream Texts"
                                        values={select.selectedDreamTexts(
                                            state
                                        )}
                                    />
                                </MultiContainer>
                                <MultiContainer>
                                    <Label>Constraints</Label>
                                    <Select
                                        handleChange={
                                            handleConstraintQuestionSelectChange
                                        }
                                        options={select.constraintQuestions(
                                            state
                                        )}
                                        placeholder="Select Question"
                                        value={select.selectedConstraintQuestion(
                                            state
                                        )}
                                    />
                                    <SingleSelectWrapper>
                                        <Select
                                            handleChange={
                                                handleConstraintAnswerSelectChange
                                            }
                                            isDisabled={
                                                state.selectedConstraintQuestionId ===
                                                ''
                                            }
                                            options={select.constraintAnswers(
                                                state
                                            )}
                                            placeholder="Select Answer"
                                            value={select.selectedConstraintAnswer(
                                                state
                                            )}
                                        />
                                    </SingleSelectWrapper>
                                </MultiContainer>
                            </Column>
                            <Column>
                                <BlockContainer>
                                    <FormInput
                                        dual
                                        label="Word Count"
                                        number
                                        onBlur={value =>
                                            handleWordCountRangeChange(
                                                value as string[]
                                            )
                                        }
                                        onValidityChange={
                                            handleWordCountValidityChange
                                        }
                                        placeholder={['Min', 'Max']}
                                        validity={[
                                            state.minWordInputValidity,
                                            state.maxWordInputValidity
                                        ]}
                                        value={[
                                            state.minWordCount,
                                            state.maxWordCount
                                        ]}
                                    />
                                </BlockContainer>
                                <MultiContainer>
                                    <Label dual style={{marginBottom: '15px'}}>Date Range
                                        <Tooltip
                                          id="date-range-tooltip"
                                          trigger={<InfoIcon color="inherit" style={{paddingTop: '8px'}}/>}
                                        >
                                            <p>
                                                Filter results based on specific date
                                                ranges and formats, including:
                                            </p>
                                            <p>
                                                <ul>
                                                    <li>Month, Day and Year (MM/DD/YYYY)</li>
                                                    <li>Month and Year (MM/YYYY)</li>
                                                    <li>Year (YYYY)</li>
                                                    <li>Century (e.g., 17th century)</li>
                                                </ul>
                                            </p>
                                        </Tooltip>
                                    </Label>

                                    <Select
                                      handleChange={e=> setDateRangeSelector(select.dateRangeOptions().find(val => val.value === e))}
                                      options={select.dateRangeOptions()}
                                      placeholder="Select date format"
                                      value={dateRangeSelector === undefined ? null : dateRangeSelector}
                                    />
                                </MultiContainer>
                                { dateRangeSelector !== undefined && dateRangeSelector.value === 'MM-DD-YYYY' &&
                                (
                                  <MultiContainer>
                                      <SinglePickerContainer style={{width: '100%'}}>
                                        <Label>Start</Label>
                                        <DatePicker
                                          date={state.dateRangeStart}
                                          id="start-date"
                                          onDateSelect={
                                              handleDateRangeStartChange
                                          }
                                          placeholder="MM/DD/YYYY"
                                        />
                                      </SinglePickerContainer>
                                      <SinglePickerContainer style={{width: '100%'}}>
                                          <Label>End</Label>
                                        <DatePicker
                                            date={state.dateRangeEnd}
                                            id="end-date"
                                            onDateSelect={
                                            handleDateRangeEndChange
                                            }
                                            placeholder="MM/DD/YYYY"
                                        />
                                      </SinglePickerContainer>
                                  </MultiContainer>)
                                }
                                { dateRangeSelector !== undefined && dateRangeSelector.value === 'MM-YYYY' &&
                                (
                                  <BlockContainer>
                                      <SinglePickerContainer style={{width: '100%'}}>
                                          <Label>Start</Label>
                                          <YearMonthPicker
                                            date={state.dateRangeStart}
                                            id="start-date"
                                            onDateSelect={
                                                handleDateRangeStartChange
                                            }
                                            placeholder="YYYY"
                                            onlyYear={false}
                                          />
                                      </SinglePickerContainer>
                                      <SinglePickerContainer style={{width: '100%', paddingTop: '48px'}}>
                                          <Label>End</Label>
                                          <YearMonthPicker
                                            date={state.dateRangeEnd}
                                            id="end-date"
                                            onDateSelect={
                                                handleDateRangeEndChange
                                            }
                                            placeholder="YYYY"
                                            onlyYear={false}
                                          />
                                      </SinglePickerContainer>
                                  </BlockContainer>)
                                }
                                { dateRangeSelector !== undefined && dateRangeSelector.value === 'YYYY' &&
                                (
                                  <FlexContainer>
                                      <SinglePickerContainer >
                                          <Label>Start</Label>
                                          <YearMonthPicker
                                            date={state.dateRangeStart}
                                            id="start-date"
                                            onDateSelect={
                                                handleDateRangeStartChange
                                            }
                                            placeholder="YYYY"
                                            onlyYear={true}
                                          />
                                      </SinglePickerContainer>
                                      <CenteredLabel>TO</CenteredLabel>
                                      <SinglePickerContainer>
                                          <Label>End</Label>
                                          <YearMonthPicker
                                            date={state.dateRangeEnd}
                                            id="end-date"
                                            onDateSelect={
                                                handleDateRangeEndChange
                                            }
                                            placeholder="YYYY"
                                            onlyYear={true}
                                          />
                                      </SinglePickerContainer>
                                  </FlexContainer>)
                                }
                                { dateRangeSelector !== undefined && dateRangeSelector.value === 'century' &&
                                (
                                  <MultiContainer>
                                      <SinglePickerContainer style={{width: '100%'}}>
                                          <Label>Start</Label>
                                          <CenturyPicker
                                            date={state.dateRangeStart}
                                            id="start-date"
                                            onDateSelect={
                                                handleDateRangeStartChange
                                            }
                                            placeholder=""
                                          />
                                      </SinglePickerContainer>
                                      <SinglePickerContainer style={{width: '100%'}}>
                                          <Label>End</Label>
                                          <CenturyPicker
                                            date={state.dateRangeEnd}
                                            id="end-date"
                                            onDateSelect={
                                                handleDateRangeEndChange
                                            }
                                            placeholder=""
                                          />
                                      </SinglePickerContainer>
                                  </MultiContainer>)
                                }
                            </Column>
                        </InputsContainer>
                    </Contents>
                </Panel>
                <SectionTitle>Search By Keywords <Tooltip
                  id="date-range-keywords"
                  trigger={<InfoIcon color="inherit" style={{paddingTop: '8px'}}/>}
                >
                    <p>
                        You can expand your search using a
                        combination of keywords in the <b>Free
                        Text Search</b> and <b>Filter by Category
                        fields</b>.
                    </p>
                    <br />
                    <p>
                        Combine "OR" with keywords to include
                        alternative results.
                    </p>
                    <br />
                    <p>
                        Combine "NOT" with keywords to exclude specific results.
                    </p>
                </Tooltip></SectionTitle>
                <FeaturedInputsWrapper>
                    <MultiContainer>
                        <Label>Free Search Text</Label>
                        <FreeSearch
                          onChange={handleFreeSearchWordChange}
                          onInputChange={handleFreeSearchInputChange}
                          selectedWords={freeSearchWords}
                          value={freeSearchInputs}
                          onFilterClick={() => {
                              setFreeSearchWords([...freeSearchWords, []]);
                              setFreeSearchInputs([...freeSearchInputs, '']);
                              setLast(last + 1);
                              setOperators([...operators, {label: 'OR', value: 'or'}]);
                          }}
                          index={0}
                          last={last === 0}
                          operators={operators}
                          onFilterRemoveClick={() => null}
                        />
                        {
                            freeSearchWords.map((e, index) => {
                                if (index === 0)
                                    return;
                                return (
                                  <FreeSearch
                                    onChange={handleFreeSearchWordChange}
                                    onInputChange={handleFreeSearchInputChange}
                                    selectedWords={freeSearchWords}
                                    value={freeSearchInputs}
                                    onFilterClick={() => {
                                        setFreeSearchWords([...freeSearchWords, []]);
                                        setFreeSearchInputs([...freeSearchInputs, '']);
                                        setLast(last + 1);
                                        setOperators([...operators, {label: 'OR', value: 'or'}]);
                                    }}
                                    index={index}
                                    last={last === index}
                                    operators={operators}
                                    onFilterRemoveClick={() => {
                                        freeSearchInputs.pop();
                                        freeSearchWords.pop();
                                        operators.pop();
                                        setLast( last - 1)
                                    }}
                                  />
                                );
                            })
                        }
                    </MultiContainer>
                    <MultiContainer>
                        <Label>Filter by Category</Label>
                        <Multiselect
                          handleChange={v => handleKeywordSelectUpdate(v, 0)}
                          nested
                          options={select.keywordOptions(state)}
                          placeholder="Select categories"
                          values={select.selectedKeywords(state, 0)}
                        />
                        {
                            lastCat === 0 && (
                              <FilterButton
                                data-testid="advanced-search-button"
                                theme={Theme.light}
                                add={true}
                                onClick={() => {
                                    setCategoriesFilter([...categoriesFilter, []]);
                                    setLastCat(lastCat + 1);
                                    setOperatorsCat([...operatorsCat, {label: 'OR', value: 'or'}]);
                                }}
                              >
                                  Add Filter
                                  <AddCircle />
                              </FilterButton>
                            )
                        }

                        {
                            categoriesFilter.map((e, index) => {
                                if (index === 0)
                                    return;
                                return (
                                  <>
                                    <div style={{'display': 'flex'}}>
                                      <div style={{'width': '35%'}}>
                                          <Select
                                            handleChange={e=> operatorsCat[index] = select.logicOptions().find(val => val.value === e) as Option}
                                            options={select.logicOptions()}
                                            value={operatorsCat[index]}
                                            placeholder={'Select operator'}
                                          />
                                      </div>
                                      <div style={{'width': '65%'}}>
                                          <Multiselect
                                            handleChange={v => handleKeywordSelectUpdate(v, index)}
                                            nested
                                            options={select.keywordOptions(state)}
                                            placeholder="Select categories"
                                            values={select.selectedKeywords(state, index)}
                                          />
                                      </div>
                                    </div>
                                      <FiltersWrapper>
                                          {
                                              lastCat === index && (
                                                <FilterButton
                                                  data-testid="advanced-search-button"
                                                  theme={Theme.light}
                                                  add={true}
                                                  onClick={() => {
                                                      setCategoriesFilter([...categoriesFilter, []]);
                                                      setLastCat(lastCat + 1);
                                                      setOperatorsCat([...operatorsCat, {label: 'OR', value: 'or'}]);
                                                  }}
                                                >
                                                    Add Filter
                                                    <AddCircle />
                                                </FilterButton>
                                              )
                                          }
                                          {lastCat === index && (
                                            <FilterButton
                                              data-testid="advanced-search-button"
                                              theme={Theme.light}
                                              onClick={e => {
                                                  categoriesFilter.pop();
                                                  setLastCat( lastCat - 1)
                                                  operatorsCat.pop();
                                              }}
                                              add={false}
                                            >
                                                Delete
                                                <RemoveCircleIcon />
                                            </FilterButton>
                                          )}
                                      </FiltersWrapper>
                                  </>
                                )
                            })
                        }
                    </MultiContainer>
                </FeaturedInputsWrapper>
                <Bottom>
                    <SectionTitle>Currently Searching</SectionTitle>
                    <SearchMetadata>
                        <div>
                            <span>Search:</span>
                            <span>
                                {metadata.searchParticipants} /{' '}
                                {metadata.dataSetParticipants} Participants
                            </span>
                        </div>
                        <div>
                            <span>
                                {metadata.searchEntries} /{' '}
                                {metadata.dataSetEntries} Entries
                            </span>
                            <span>{metadata.searchPercentage} of data set</span>
                        </div>

                        <Tooltip
                            id="search-tooltip"
                            trigger={<InfoIcon color="inherit" />}
                        >
                            <p>
                                These numbers represent how many entries in the
                                data set contain at least one occurence of the
                                selected categories or free search words.
                            </p>
                            <p>
                                If no data set was defined, it defaults to the
                                entire dream journal library.
                            </p>
                        </Tooltip>
                    </SearchMetadata>
                    <ButtonRow>
                        <div>
                            <ButtonContainer>
                                <BorderlessButton onClick={handleCancelClick}>
                                    Cancel
                                </BorderlessButton>
                            </ButtonContainer>
                        </div>
                        <div>
                            <ButtonContainer>
                                <BorderlessButton onClick={handleResetClick}>
                                    Reset
                                </BorderlessButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <Button onClick={handleSearchClick}>
                                    Search
                                </Button>
                            </ButtonContainer>
                        </div>
                    </ButtonRow>
                </Bottom>
            </Wrapper>
            <Copyright />
        </>
    );
};

export default AdvancedSearch;
