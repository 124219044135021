import React from 'react';

const Alert: React.FC = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 68.5 52.1"
        xmlSpace="preserve"
    >
        <g>
            <rect
                id="Rectangle_1625"
                x="30.5"
                y="19.7"
                className="st0"
                width="5"
                height="16"
                style={{ fill: '#e8e8e8' }}
            />
            <g id="Path_365" transform="translate(40.755 81.51) rotate(-135)">
                <path
                    className="st1"
                    d="M49.8-5.6L61.2,52L3.6,40.6L49.8-5.6z"
                    style={{ fill: 'none' }}
                />
                <path
                    className="st2"
                    d="M49.2-3.6L5.6,39.9l54.3,10.8L49.2-3.6 M49.8-5.6L61.2,52L3.6,40.6L49.8-5.6z"
                    style={{ fill: '#707070' }}
                />
            </g>
            <path
                id="Path_367"
                className="st3"
                d="M35,17v15.5"
                style={{ fill: 'none', stroke: '#707070' }}
            />
            <g id="Ellipse_365" transform="translate(40 38.255)">
                <circle
                    className="st2"
                    cx="-5"
                    cy="3"
                    r="1.5"
                    style={{ fill: '#707070' }}
                />
                <circle
                    className="st3"
                    cx="-5"
                    cy="3"
                    r="1"
                    style={{ fill: 'none', stroke: '#707070' }}
                />
            </g>
        </g>
    </svg>
);

export default Alert;
