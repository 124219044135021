import {DreamEntry, Keyword, Metadata, SearchParameters} from './types';
import {Category, WordSearch, WordSearchMetadata, WordSearchRequest} from "../api/types";
import {Answer} from './api';
import {extractUuidFromFullPath} from '../../utils';

export function transformWordSearchParametersToFilters (
    params: Partial<SearchParameters>
): Partial<WordSearchRequest> {
    const filters: Partial<WordSearchRequest> = {};

    const surveyIds = params.surveyIds;
    const dreamEntryIds = params.dreamTextIds;
    const respondentIds = params.respondentIds;
    const startDate = params.dateRangeStart;
    const endDate = params.dateRangeEnd;
    const startBce = params.startBce;
    const endBce = params.endBce;
    const minWordCount = params.minWordCount
        ? parseInt(params.minWordCount)
        : null;
    const maxWordCount = params.maxWordCount
        ? parseInt(params.maxWordCount)
        : null;
    const searchText = params.freeSearchWords;
    const searchTextOperators = params.freeSearchOperators;
    const subCategoryIds = params.keywordIds;
    const subCategoryOperators = params.keywordOperators;
    const constraints = params.constraints;

    if (surveyIds !== undefined) {
        filters.surveyIds = surveyIds.map(id => extractUuidFromFullPath(id));
    }
    if (dreamEntryIds !== undefined) {
        filters.dreamEntryIds = dreamEntryIds.map(id =>
            extractUuidFromFullPath(id)
        );
    }
    if (respondentIds !== undefined) {
        filters.respondentIds = respondentIds.map(id =>
            extractUuidFromFullPath(id)
        );
    }
    if (startDate !== undefined) {
        filters.startDate = startDate;
    }
    if (endDate !== undefined) {
        filters.endDate = endDate;
    }
    if (endBce !== undefined) {
        filters.endBce = endBce;
    }
    if (startBce !== undefined) {
        filters.startBce = startBce;
    }
    if (minWordCount !== null && !isNaN(minWordCount)) {
        filters.minWordCount = minWordCount;
    }
    if (maxWordCount !== null && !isNaN(maxWordCount)) {
        filters.maxWordCount = maxWordCount;
    }
    if (searchText !== undefined) {
        filters.searchText = searchText;
    }
    if (searchTextOperators !== undefined) {
        filters.searchTextOperators = searchTextOperators;
    }
    if (subCategoryIds !== undefined) {
        filters.subCategoryIds = subCategoryIds.map(block => {
            return block.map(id =>
              extractUuidFromFullPath(id))
          }
        );
    }
    if (subCategoryOperators !== undefined) {
        filters.subCategoryOperators = subCategoryOperators;
    }
    if (constraints !== undefined) {
        filters.constraints = constraints.map(constraint => ({
            ...constraint,
            questionId: extractUuidFromFullPath(constraint.questionId)
        }));
    }

    return filters;
};

export function transformMetadataObjectsIntoKeywordsArray (
    results: WordSearchMetadata
): Keyword[] {
    const keywords = Object.keys(results);
    const keywordsWithSubcategories = keywords.map(keyword => {
        const subCategories = Object.keys(results[keyword].buckets);
        return {
            keyword,
            subcategories: subCategories.map(subcategory => {
                const counts = results[keyword].buckets[subcategory].doc_count;
                return {
                    word: subcategory,
                    counts
                };
            })
        };
    });
    const hasFreeSearchText =
        keywordsWithSubcategories.find(
            keyword => keyword.subcategories.length === 1
        ) !== undefined;
    if (hasFreeSearchText) {
        const freeSearchTexts = keywordsWithSubcategories.filter(
            keyword => keyword.subcategories.length === 1
        );
        const freeSearchKeyword: Keyword = {
            keyword: 'Free Text Search',
            subcategories: freeSearchTexts.flatMap(
                freeText => freeText.subcategories
            )
        };
        const withFreeSearchesRemoved = keywordsWithSubcategories.filter(
            keyword => keyword.subcategories.length > 1
        );
        return [...withFreeSearchesRemoved, freeSearchKeyword];
    } else {
        return keywordsWithSubcategories;
    }
};

export const getPercentageText: (filtered: number, total: number) => string = (
    filtered,
    total
) => {
    const calculatedPercentage = Math.round((filtered / total) * 1000) / 10;
    return calculatedPercentage === 0 && filtered !== 0
        ? '< 0.1%'
        : `${calculatedPercentage}%`;
};

export const transformAnswersToDreamEntryObjects: (
    answers: Answer[]
) => DreamEntry[] = answers =>
    answers.map(entry => {
        const answer = entry._source;
        const wordCount = entry.fields['answer_text.word_count'][0];
        const { response_set } = answer;
        const { respondent } = response_set;
        let date = answer.dream_date || answer.created_at;
        let createdAt = new Date().getTime();
        let displayDate = '';
        if (date) {
            const dateRegex = /(\d{4})-(\d{2})-(\d{2})/;
            const dateMatches = date.match(dateRegex);
            displayDate = dateMatches
                ? `${dateMatches[2]}-${dateMatches[3]}-${dateMatches[1]}`
                : '';
            createdAt = Date.parse(date);
        }

        const linkTarget = `/response_sets/${
            response_set.id
        }/${extractUuidFromFullPath(answer.id)}`;
        const questionText = answer.question.title;

        return {
            createdAt,
            displayDate,
            linkTarget,
            questionText,
            respondent: respondent ? {
                id: respondent.id || '',
                userName: respondent.user_name || ''
            }: {
                id: '',
                userName: ''
            },
            text: answer.answer_text,
            wordCount
        };
    });

export function getKeywordsFromKeywordIds (
    categories: Category[],
    selectedKeywordIds: string[][]
): string[][] {
    const subCategories = categories.flatMap(
      category => category.subCategories
    );
    return selectedKeywordIds.flatMap(id => {
        const superMatch = id.map(e => {
            const match = subCategories.find(
              subCategory =>
                extractUuidFromFullPath(subCategory.id) ===
                extractUuidFromFullPath(e)
            );
            if (match) {
                return match.keywords;
            }
            return [];
        });

        if (superMatch) {
            return superMatch;
        }
        return [];
    });
}

export const getMetadataFromDataResponses: (
    total: WordSearch,
    dataSet: WordSearch,
    search: WordSearch
) => Metadata = (total, dataSet, search) => {
    const totalEntries = total.dreamEntryCount;
    const totalParticipants = total.respondentCount;
    const dataSetEntries = dataSet.dreamEntryCount;
    const dataSetParticipants = dataSet.respondentCount;
    const searchEntries = search.dreamEntryCount;
    const searchParticipants = search.respondentCount;
    let dataSetPercentage = '0%';
    let searchPercentage = '0%';
    if (totalEntries > 0 && dataSetEntries > 0) {
        dataSetPercentage = getPercentageText(dataSetEntries, totalEntries);
    }
    if (dataSetEntries > 0 && searchEntries > 0) {
        searchPercentage = getPercentageText(searchEntries, dataSetEntries);
    }
    return {
        dataSetEntries,
        dataSetParticipants,
        dataSetPercentage,
        searchEntries,
        searchParticipants,
        searchPercentage,
        totalEntries,
        totalParticipants
    };
};

export const downloadCSV = async (url: string) => {
    const file = await fetch(url);
    const fileText = await file.text();
    let a = document.createElement('a');
    a.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(fileText)
    );

    a.setAttribute('download', 'dream-export.csv');
    a.click();
    return;
};
