import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import * as Styled from './styled/ResponseDetail';
import LoadingIndicator from '../shared/LoadingIndicator';
import {getRandomImageUrl} from '../../utils';
import {formatDate} from '../api/utils';
import {Path} from '../../types';
import useApiGetOne from "../api/useApiGetOne";
import {Answer, ApiType, Respondent, ResponseSet, Survey} from "../api/types";
import useApiGet from "../api/useApiGet";

const randomImageUrl = getRandomImageUrl();

interface Match {
    id: string;
    featuredDream: string;
}
interface Props extends RouteComponentProps<Match> {}

const ResponseDetail: React.FC<Props> = ({ match }) => {
    const { data: responseSet, loading: responseSetLoading } = useApiGetOne<ResponseSet>(match.params.id, ApiType.ResponseSet);
    const { data: respondent, loading: respondentLoading } = useApiGetOne<Respondent>(responseSet && responseSet.respondent, ApiType.Respondent);
    const { data: answer, loading: answerLoading } = useApiGetOne<Answer>(match.params.featuredDream, ApiType.Answer);
    const { data: survey, loading: surveyLoading } = useApiGetOne<Survey>(responseSet && responseSet.survey, ApiType.Survey);

    const { data: details, loading: detailsLoading } = useApiGet<Answer>({
        isDreamEntry: false,
        responseSet: responseSet && responseSet.id
    }, ApiType.Answer);

    const loading = responseSetLoading || answerLoading;

    const link = survey ? Path.searchResults.replace(
        ':json?',
        encodeURIComponent(
            JSON.stringify({
                surveyIds: [survey.id],
                surveyNames: [survey.name]
            })
        )
    ) : '';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Wrapper>
            {loading && <LoadingIndicator />}
            {!loading && responseSet && answer && survey && respondent && (
                <>
                    <Styled.Top>
                        <Styled.SurveyName to={link}>
                            {survey.name}
                        </Styled.SurveyName>
                        <Styled.Title>
                            <div>{respondent.userName}</div>
                            <div>{formatDate(responseSet.createdAt)}</div>
                        </Styled.Title>
                        <Styled.Hero>
                            <img src={randomImageUrl} />
                        </Styled.Hero>
                        <Styled.DreamText>{answer.answerText}</Styled.DreamText>
                        <Styled.TextInfo>
                            <div>Words: {answer.wordCount}</div>
                            <div>{answer.question.title }</div>
                        </Styled.TextInfo>
                    </Styled.Top>
                    <Styled.Details>
                        <Styled.DetailContents>
                            <Styled.DetailID>
                                ID: {respondent.userName} details
                            </Styled.DetailID>
                            {details && details.map((detail, i) => (
                                <Styled.Pair key={i}>
                                    <div>{detail.question.title}</div>
                                    <div>{detail.answerText}</div>
                                </Styled.Pair>
                            ))}
                        </Styled.DetailContents>
                    </Styled.Details>
                    <Styled.Copyright />
                </>
            )}
        </Styled.Wrapper>
    );
};

export default withRouter(ResponseDetail);
