import React, { useState } from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { baseFont, breakpoints, colors } from '../../../theme';
import {Survey} from "../../api/types";

const Panel = styled(ExpansionPanel)`
    &.MuiExpansionPanel-root {
        width: calc(100% - 72px);
        margin: 0 auto;
        margin-bottom: 48px;
        padding-top: 8px;
        box-shadow: none;
        &::before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0 auto;
            margin-bottom: 48px;
        }
        @media (min-width: ${breakpoints.mediumMin}) {
            width: calc(100% - 160px);
        }
        @media (min-width: ${breakpoints.largeMin}) {
            width: 1056px;
        }
    }
`;

const Summary = styled(ExpansionPanelSummary)`
    &.MuiExpansionPanelSummary-root {
        border-bottom: 1px solid rgba(112, 112, 112, 0.23);
        padding: 0;
        margin: 0;
        min-height: 44px;
        position: relative;
        &.Mui-expanded {
            min-height: 44px;
        }
        .MuiExpansionPanelSummary-content {
            margin: 0;
            flex-direction: column;
            &.Mui-expanded {
                margin: 0;
            }
        }
    }
`;

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: block;
    color: ${colors.darkGray};
`;

const SummaryLineOne = styled.div`
   ${baseFont({ weight: 900 })}
    color: ${colors.darkGray};
    text-transform: uppercase;
    letter-spacing: 1.4px;
    position: absolute;
    left: 0;
    top: -8px;
`;

const SummaryLineTwo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${baseFont()}
    color: ${colors.darkGray};
    > div {
      &:last-of-type {
        font-weight: 700;
        letter-spacing: 0.42px;
      }
    }
`;

const Contents = styled(ExpansionPanelDetails)`
    &.MuiExpansionPanelDetails-root {
        padding: 0;
        flex-direction: column;
        @media (min-width: ${breakpoints.mediumMin}) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
`;

const SingleSurvey = styled.div`
    width: 100%;
    padding-top: 40px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(50% - 12px);
    }
    @media(min-width: ${breakpoints.largeMin}) {
        padding-top: 48px;
        padding-bottom: 40px;
        width: calc(33.3% - 29px);
    }
    ${baseFont()}
    color: ${colors.black};
    display: flex;
    flex-direction: column;
`;

const Name = styled.div`
    font-size: 18px;
`;

const Meta = styled.div`
    padding: 8px 0 16px;
    color: ${colors.lightGray};
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    > div {
        margin-right: 16px;
    }
`;

const Description = styled.div`
    color: ${colors.darkGray};
    a {
        font-weight: 700;
        color: ${colors.goldenrod};
        text-decoration: none;
    }
`;

interface Props {
    surveys: Survey[];
}

const SurveySummarySection: React.FC<Props> = ({ surveys }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Panel
            defaultExpanded
            onChange={(_, expanded) => setExpanded(expanded)}
        >
            <Summary
                expandIcon={
                    <IconWrapper>
                        <ExpandMoreIcon color="inherit" />
                    </IconWrapper>
                }
            >
                <SummaryLineOne>Surveys</SummaryLineOne>
                <SummaryLineTwo>
                    <div>Surveys Selected: {surveys.length}</div>
                    <div>{`${expanded ? 'Collapse' : 'Expand'} all`}</div>
                </SummaryLineTwo>
            </Summary>
            <Contents>
                {surveys.map((survey, i) => (
                    <SingleSurvey key={i}>
                        <Name>{survey.name}</Name>
                        <Meta>
                            <div>{survey.year}</div>
                            <div>{`${survey.respondentCount} Participant${
                                survey.respondentCount === 1 ? '' : 's'
                            }`}</div>
                        </Meta>
                        <Description dangerouslySetInnerHTML={{__html: survey.description || 'N/A'}} />
                    </SingleSurvey>
                ))}
            </Contents>
        </Panel>
    );
};

export default SurveySummarySection;
