import {ApiType, QueryConfig} from "./types";
import Api from "./Api";
import {useEffect, useState} from "react";
import {apiEquality} from "./utils";



const useApiGet = <Type>(params: any, apiType: ApiType, config?: QueryConfig): {
    data: Type[]|null,
    loading: boolean,
    error: any,
    refetch: () => Promise<Type[]|null>
} => {
    const [data, setData] = useState<Type[]|null>(null);
    const [error, setError] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [requestParams, setRequestParams] = useState<any>(params);

    const fetch = async (requestParams: any): Promise<Type[]|null> => {
        console.log('GET ', apiType.toString(), requestParams);
        const api = new Api();
        return await api.get<Type>(requestParams, apiType);
    };

    const get = async (requestParams: any) => {
        try {
            setLoading(true);
            const data = await fetch(requestParams);
            setLoading(false);
            setData(data);
            return data;
        } catch (e) {
            setError(e);
            setLoading(false);
            return null;
        }
    };

    let isActive = true;
    if (config && config.active !== undefined) {
        isActive = config.active;
    } else if (config && config.activeFn !== undefined) {
        isActive = config.activeFn();
    }

    useEffect(() => {
        if (isActive && !loading && !error) {
            if (data === null) {
                get(params);
            } else if (!apiEquality(requestParams, params)) {
                get(params);
                setRequestParams(params);
            }
        }
    }, [loading, data, params]);

    return {
        data,
        loading,
        error,
        refetch: (): Promise<Type[]|null> => {
            return get(requestParams);
        }
    };
};

export default useApiGet;
