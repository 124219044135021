import { ActionTypes, ActionType, State } from './types';

const editableFields = {
    dateRange: '',
    dateRangeEnd: null,
    startBce: 'false',
    endBce: 'false',
    dateRangeStart: null,
    freeSearchInputValue: [''],
    freeSearchWords: [],
    freeSearchOperators: [],
    maxWordCount: '',
    minWordCount: '',
    selectedConstraintAnswer: '',
    selectedConstraintQuestionId: '',
    selectedDreamTextIds: [],
    selectedKeywordIds: [],
    selectedKeywordOperators: [],
    selectedRespondentIds: [],
    selectedRespondentUserNames: [],
    selectedSurveyIds: []
};

export const initialState: State = {
    constraintQuestions: [],
    dreamTextQuestions: [],
    keywords: [],
    maxWordInputValidity: true,
    minWordInputValidity: true,
    respondents: [],
    surveys: [],
    ...editableFields
};

export const reducer = (
    state: State,
    action: ActionTypes
): State => {
    switch (action.type) {
        case ActionType.reset:
            return {
                ...state,
                ...editableFields
            };
        case ActionType.setFreeSearchInputValue:
            return {
                ...state,
                freeSearchInputValue: action.payload
            };
        case ActionType.setSelectedFreeSearchWords:
            return {
                ...state,
                freeSearchWords: action.payload
            };
        case ActionType.setSelectedFreeSearchOperators:
            return {
                ...state,
                freeSearchOperators: action.payload
            };
        case ActionType.setSelectedKeywordOperators:
            return {
                ...state,
                selectedKeywordOperators: action.payload
            };
        case ActionType.setKeywordCategories:
            return {
                ...state,
                keywords: action.payload
            };
        case ActionType.setSelectedKeywordIds:
            return {
                ...state,
                selectedKeywordIds: action.payload
            };
        case ActionType.setSurveys:
            return {
                ...state,
                surveys: action.payload
            };
        case ActionType.setRespondents:
            return {
                ...state,
                respondents: action.payload
            };
        case ActionType.setQuestions:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.setSelectedSurveyIds:
            return {
                ...state,
                selectedSurveyIds: action.payload
            };
        case ActionType.setSelectedDreamTextIds:
            return {
                ...state,
                selectedDreamTextIds: action.payload
            };
        case ActionType.setSelectedConstraintQuestionId:
            return {
                ...state,
                selectedConstraintQuestionId: action.payload
            };
        case ActionType.setSelectedConstraintAnswer:
            return {
                ...state,
                selectedConstraintAnswer: action.payload
            };
        case ActionType.setWordCounts:
            return {
                ...state,
                minWordCount: action.payload.min,
                maxWordCount: action.payload.max
            };
        case ActionType.setWordCountValidity:
            return {
                ...state,
                minWordInputValidity: action.payload[0],
                maxWordInputValidity: action.payload[1]
            };
        case ActionType.setSelectedRespondents:
            return {
                ...state,
                selectedRespondentIds: action.payload.map(
                    respondent => respondent.id
                ),
                selectedRespondentUserNames: action.payload.map(
                    respondent => respondent.userName
                )
            };
        case ActionType.setDateRangeEnd:
            return {
                ...state,
                dateRangeEnd: action.payload
            };
        case ActionType.setDateRangeStart:
            return {
                ...state,
                dateRangeStart: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
