import { State, Summary } from './types';
import { Question} from "../../api/types";
import { createGroupedConstraintStrings } from '../utils';

type StringFromState = (state: State) => string;
type ArrayFromState<T> = (state: State) => T[];
type BooleanFromState = (state: State) => boolean;

const getSelectionTextsFromState: ArrayFromState<string> = state => {
    return [
        `${
            state.selectedSurveyIds.length && state.currentStep > 0
                ? `${state.selectedSurveyIds.length} survey${
                      state.selectedSurveyIds.length === 1 ? '' : 's'
                  } selected`
                : ''
        }`,
        `${
            state.selectedXAxisQuestion && state.currentStep > 1
                ? state.selectedXAxisQuestion.title
                : ''
        }`,
        `${
            state.selectedYAxisQuestion && state.currentStep > 2
                ? state.selectedYAxisQuestion.title
                : ''
        }`,
        `${
            state.currentStep > 3
                ? `${state.selectedConstraints.length} constraint${
                      state.selectedConstraints.length === 1 ? '' : 's'
                  }`
                : ''
        }`
    ];
};

const getButtonTextFromState: StringFromState = state => {
    if (state.currentStep === 4) {
        return 'Create Analysis';
    }
    return 'Continue';
};

const getNextButtonDisabledFromState: BooleanFromState = state => {
    switch (state.currentStep) {
        case 0:
            return state.selectedSurveyIds.length < 1;
        case 1:
            return !state.selectedXAxisQuestion;
        case 2:
            return !state.selectedYAxisQuestion;
        default:
            return false;
    }
};

const getXAxisQuestionIdFromState: StringFromState = state => {
    if (state.selectedXAxisQuestion) {
        return state.selectedXAxisQuestion.id;
    }
    return '';
};

const getYAxisQuestionIdFromState: StringFromState = state => {
    if (state.selectedYAxisQuestion) {
        return state.selectedYAxisQuestion.id;
    }
    return '';
};

const getYAxisQuestionsFromState: ArrayFromState<Question> = state =>
    state.questions.filter(question =>
        state.selectedXAxisQuestion
            ? question.id !== state.selectedXAxisQuestion.id
            : true
    );

const getConstraintQuestionsFromState: ArrayFromState<Question> = state => {
    const xAxisQuestionId = state.selectedXAxisQuestion
        ? state.selectedXAxisQuestion.id
        : '';
    const yAxisQuestionId = state.selectedYAxisQuestion
        ? state.selectedYAxisQuestion.id
        : '';
    return state.questions.filter(
        question =>
            question.id !== xAxisQuestionId && question.id !== yAxisQuestionId
    );
};

const getSummaryDataFromState: (state: State) => Summary = state => {
    const xAxis = state.selectedXAxisQuestion
        ? state.selectedXAxisQuestion.title
        : '';
    const yAxis = state.selectedYAxisQuestion
        ? state.selectedYAxisQuestion.title
        : '';
    return {
        surveyNames: state.surveys
            .filter(survey => state.selectedSurveyIds.includes(survey.id))
            .map(survey => survey.name),
        xAxisQuestionText: xAxis,
        yAxisQuestionText: yAxis,
        constraintAnswerTexts: createGroupedConstraintStrings(
            state.selectedConstraints
        )
    };
};

const getResultsUrlParametersFromState: StringFromState = state => {
    const surveyIds = state.selectedSurveyIds;
    const xAxisQuestionId = state.selectedXAxisQuestion
        ? state.selectedXAxisQuestion.id
        : '';
    const yAxisQuestionId = state.selectedYAxisQuestion
        ? state.selectedYAxisQuestion.id
        : '';
    const constraints = state.selectedConstraints;
    const title = state.title;
    return encodeURIComponent(
        JSON.stringify({
            surveyIds,
            xAxisQuestionId,
            yAxisQuestionId,
            constraints,
            title
        })
    );
};

const selectors = {
    selectionTexts: getSelectionTextsFromState,
    buttonText: getButtonTextFromState,
    nextButtonDisabled: getNextButtonDisabledFromState,
    xAxisQuestionId: getXAxisQuestionIdFromState,
    yAxisQuestionId: getYAxisQuestionIdFromState,
    yAxisQuesions: getYAxisQuestionsFromState,
    constraints: getConstraintQuestionsFromState,
    summaryData: getSummaryDataFromState,
    urlString: getResultsUrlParametersFromState
};

export default selectors;
