import { Constraint } from '../types';
import { Parameters } from '../results/types';
import { Survey} from "../../api/types";
import {Question} from "../../api/types";

export interface GroupedConstraint {
    questionId: string;
    answerText: string[];
}

export interface Summary {
    surveyNames: string[];
    xAxisQuestionText: string;
    yAxisQuestionText: string;
    constraintAnswerTexts: string[];
}

export enum Step {
    'Choose Data',
    'Select X Axis',
    'Select Y Axis',
    'Add Constraints',
    'Name & Create'
}

export interface State {
    currentStep: number;
    initialized: boolean;
    initialValues: Partial<Parameters>;
    questions: Question[];
    selectedSurveyIds: string[];
    selectedXAxisQuestion: Question | null;
    selectedYAxisQuestion: Question | null;
    selectedConstraints: Constraint[];
    surveys: Survey[];
    title: string;
}

export type Reducer = (
    state: State,
    action: ActionTypes
) => State;

export enum ActionType {
    setInitialValues,
    initialized,
    setSurveys,
    setCurrentStep,
    incrementStep,
    setSelectedSurveyIds,
    setQuestions,
    setXAxis,
    setYAxis,
    setSelectedConstraints,
    goToPreviousStep,
    setTitle
}

type SetInitialValues = {
    type: ActionType.setInitialValues;
    payload: Partial<Parameters>;
};

type Initialized = {
    type: ActionType.initialized;
};

type SetSurveys = {
    type: ActionType.setSurveys;
    payload: Survey[];
};

type SetCurrentStep = {
    type: ActionType.setCurrentStep;
    payload: number;
};

type IncrementStep = {
    type: ActionType.incrementStep;
};

type SetSelectedSurveyIds = {
    type: ActionType.setSelectedSurveyIds;
    payload: string[];
};

type SetQuestions = {
    type: ActionType.setQuestions;
    payload: Question[];
};

type SetXAxis = {
    type: ActionType.setXAxis;
    payload: Question | null;
};

type SetYAxis = {
    type: ActionType.setYAxis;
    payload: Question | null;
};

type SetSelectedConstraints = {
    type: ActionType.setSelectedConstraints;
    payload: Constraint[];
};

type GoToPreviousStep = {
    type: ActionType.goToPreviousStep;
    payload: number;
};

type SetTitle = {
    type: ActionType.setTitle;
    payload: string;
};

export type ActionTypes =
    | SetInitialValues
    | Initialized
    | SetSurveys
    | SetCurrentStep
    | IncrementStep
    | SetSelectedSurveyIds
    | SetQuestions
    | SetXAxis
    | SetYAxis
    | SetSelectedConstraints
    | GoToPreviousStep
    | SetTitle;
