import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AnswerWithHighlight,
    Consumer,
    Path,
    Runnable,
    Supplier
} from '../../../types';
import SearchInput from '../SearchInput';
import SearchResults from '../SearchResults';
import { transformSearchResponseAnswers } from './utils';
import useWordSearch from "../../api/useWordSearch";

const resultListSize = 20;

interface Props {
    large?: boolean;
    placeholder?: string;
}

const DreamTextSearch: React.FC<Props> = ({
    large = false,
    placeholder = ''
}) => {
    const history = useHistory();

    const [searchText, setSearchText] = useState('');
    const [searchResultsVisible, setSearchResultVisibility] = useState(false);
    const [searchResults, setSearchResults] = useState<AnswerWithHighlight[]>(
        []
    );
    const [paginationCursor, setPaginationCursor] = useState(0);
    const [totalVisibleEntries, setTotalVisibleEntries] = useState(0);

    const { data, loading, error } = useWordSearch({
        searchText: [[searchText]],
        from: paginationCursor,
        size: resultListSize,
        matchPartials: true,
        sortBy: 'answer_text.word_count',
        sortDir: 'DESC'
    }, {
        active: searchText !== ''
    });

    useEffect(() => {
        if (loading && searchText !== '') {
            setSearchResultVisibility(true);
        }
        if (
            !loading &&
            !error &&
            data
        ) {
            const {
                answers,
                dreamEntryCount
            } = data;

            const newSearchResults = transformSearchResponseAnswers(answers);

            if (paginationCursor === 0) {
                setSearchResults(newSearchResults);
            } else if (searchResults.length === paginationCursor) {
                setSearchResults([...searchResults, ...newSearchResults]);
            }

            if (dreamEntryCount !== totalVisibleEntries) {
                setTotalVisibleEntries(dreamEntryCount);
            }
        }
    }, [data, error, loading, paginationCursor, totalVisibleEntries]);

    const handleSearch: Consumer<string> = text => {
        history.push(
            Path.searchResults.replace(
                ':json?',
                encodeURIComponent(JSON.stringify({ freeSearchWords: [text] }))
            )
        );
    };

    const handleUserInput: Consumer<string> = text => {
        setPaginationCursor(0);
        setSearchText(text);
    };

    const loadMoreEntries: Runnable = () => {
        setTimeout(() => {
            setPaginationCursor(paginationCursor + resultListSize);
        }, 1500);
    };

    const hasMoreResults: Supplier<boolean> = () =>
        searchResults.length < totalVisibleEntries;

    const handleResultsCloseClick: Runnable = () => {
        setSearchResultVisibility(false);
    };

    const results: Supplier<AnswerWithHighlight[]> = () =>
        searchText === '' ? [] : searchResults;

    const isPerformingInitialSearch: Supplier<boolean> = () =>
        loading && paginationCursor === 0;

    return (
        <>
            <SearchInput
                handleSearch={handleSearch}
                handleUserInput={handleUserInput}
                large={large}
                placeholder={placeholder}
                searchOnUserInput
                withAdvancedSearch
            />
            <SearchResults
                hasMore={hasMoreResults()}
                large={large}
                match={searchText}
                onCloseClick={handleResultsCloseClick}
                onScrollToBottom={loadMoreEntries}
                open={searchResultsVisible}
                results={results()}
                resultsAreLinks
                searching={isPerformingInitialSearch()}
            />
        </>
    );
};

export default DreamTextSearch;
