import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseIcon from '@material-ui/icons/Close';
import LoadingIndicator from '../shared/LoadingIndicator';
import { AnswerWithHighlight } from '../../types';
import ExportButton from '../shared/ExportButton';
import {
    CloseButton,
    Container,
    Grouping,
    InfiniteScrollEndMessage,
    NoResults,
    RelativeContainer,
    ResultList
} from './styled/SearchResults';
import { decode } from '../../utils';
import { SearchParameters } from '../advancedSearch/types';
import { useHistory } from 'react-router-dom';

interface Props {
    className?: string;
    hasMore: boolean;
    large?: boolean;
    match: string;
    onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
    onResultClick?: (resultId: string) => void;
    onScrollToBottom: () => void;
    open: boolean;
    results: AnswerWithHighlight[];
    resultsAreLinks?: boolean;
    searching: boolean;
}

enum Progress {
    Initial = 1,
    Searching,
    NoResults,
    Results
}

const SearchResults: React.FC<Props> = ({
    className,
    hasMore,
    large,
    onCloseClick,
    onResultClick,
    onScrollToBottom,
    open,
    results,
    resultsAreLinks,
    searching,
    match
}) => {
    const [progress, setProgress] = useState(Progress.Initial);
    const history = useHistory();
    useEffect(() => {
        if (searching) {
            setProgress(Progress.Searching);
        } else if (results.length === 0) {
            setProgress(Progress.NoResults);
        } else {
            setProgress(Progress.Results);
        }
    }, [results, searching]);

    const getContent = () => {
        switch (progress) {
            case Progress.Initial:
                return null;
            case Progress.Searching:
                return <LoadingIndicator />;
            case Progress.NoResults:
                return (
                    <NoResults>
                        <p>No matches found.</p>
                        <p>Please try another search.</p>
                    </NoResults>
                );
            case Progress.Results:
                return (
                    <>
                        <Grouping>Responses by matching dream text</Grouping>
                        <ResultList>
                            <ExportButton
                                onClick={() => {
                                    history.push('/exportCSV', {
                                        freeSearchWords: [match]
                                    });
                                }}
                            />
                            <InfiniteScroll
                                dataLength={results.length}
                                loader={<LoadingIndicator />}
                                hasMore={hasMore}
                                next={onScrollToBottom}
                                endMessage={
                                    <InfiniteScrollEndMessage>
                                        All matching entries have been loaded
                                    </InfiniteScrollEndMessage>
                                }
                                scrollableTarget="scrollable_wrapper"
                            >
                                {results.map(result => {
                                    return (
                                        <li key={result.id}>
                                            {resultsAreLinks && (
                                                <a
                                                    href={`/response_sets/${result.responseSet}/${result.id}`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.sample
                                                    }}
                                                ></a>
                                            )}
                                            {!resultsAreLinks && (
                                                <div
                                                    onClick={() =>
                                                        onResultClick &&
                                                        onResultClick(result.id)
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.sample
                                                    }}
                                                ></div>
                                            )}
                                        </li>
                                    );
                                })}
                            </InfiniteScroll>
                        </ResultList>
                    </>
                );
        }
    };

    return (
        <Container
            className={className}
            large={large || false}
            open={open}
            id="scrollable_wrapper"
        >
            <RelativeContainer open={open}>
                <CloseButton onClick={onCloseClick}>
                    <CloseIcon />
                </CloseButton>
                {getContent()}
            </RelativeContainer>
        </Container>
    );
};

export default SearchResults;
