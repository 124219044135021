import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';
import { baseFont, colors } from '../../theme';

const StyledTooltip = styled(ReactTooltip)`
    &.__react_component_tooltip {
        text-transform: none;
        background: ${colors.titleGray};
        ${baseFont()};
        color: ${colors.white};
        opacity: 1;
        padding: 24px;
        max-width: 300px;
        white-space: initial;
        &.place-bottom {
            &::after {
                border-bottom-color: ${colors.titleGray};
            }
        }
        h3 {
            font-weight: 700;
            margin: 0;
            margin-bottom: 16px;
            padding: 0;
        }
        p {
            margin: 0;
            padding: 0;
            &:first-of-type {
                margin-bottom: 8px;
                padding: 0;
            }
            span {
                font-weight: 700;
            }
            ul {
              padding: 0;
            }
        }
    }
`;

interface Props {
    id: string;
    overrides?: TooltipProps;
    trigger: React.ReactElement;
}

interface TriggerAttributes {
    'data-for': string;
    'data-tip': string;
}

const Tooltip: React.FC<Props> = ({ children, id, overrides, trigger }) => (
    <>
        {React.cloneElement<TriggerAttributes>(trigger, {
            'data-for': id,
            'data-tip': ''
        })}
        <StyledTooltip effect="solid" id={id} {...overrides} >
            {children}
        </StyledTooltip>
    </>
);

export default Tooltip;
