import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import DefaultArrowBack from '@material-ui/icons/ArrowBack';
import Check from '@material-ui/icons/Check';
import { baseFont, breakpoints, colors } from '../../../theme';
import DefaultButton from '../../shared/Button';
import DefaultSearchInput from '../../shared/SearchInput';
import Title from '../../shared/PageTitle';

export const Wrapper = styled.div`
    width: calc(100% - 72px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 750px;
    }
    margin: 0 auto;
    padding-top: 96px;
    padding-bottom: 92px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const BackButton = styled(DefaultLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: none;
    }
    position: absolute;
    text-decoration: none;
    top: 36px;
    left: 36px;
    ${baseFont()}
    color: ${colors.darkGray};
`;

export const ArrowBack = styled(DefaultArrowBack)`
    color: ${colors.darkGray};
    margin-right: 8px;
`;

export const StepTitle = styled(Title)`
    margin: 32px 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 72px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-bottom: 56px;
    }
`;

export const StepSubtitle = styled.h3`
    display: block;
    text-align: center;
    ${baseFont()}
    color: ${colors.cornHarvest};
    font-style: italic;
    margin-top: -40px;
    margin-bottom: 32px;
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: -64px;
        margin-bottom: 56px;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
`;

export const Button = styled(DefaultButton)`
    &:disabled {
        &:hover {
            color: initial;
            background: initial;
        }
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

export const FixedBottomBar = styled.div<{ hide: boolean }>`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: ${colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    height: 79px;
    transition: bottom 0.1s ease-in-out;
    @media (max-width: ${breakpoints.smallMax}) {
        ${p =>
            p.hide &&
            css`
                bottom: -79px;
            `}
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        padding: 0 76px;
        bottom: 0;
        height: 92px;
    }
    button {
        margin: 0;
    }
    border-top: 1px solid #e3e3e3;
`;

export const CurrentSelectionCounter = styled.div`
    ${baseFont()}
    color: ${colors.darkGray};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    > div {
        background: ${colors.goldenrod};
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: ${colors.white};
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }
`;

export const Placeholder = styled.div`
    ${baseFont()}
    color: ${colors.darkGray};
    span {
        color: ${colors.goldenrod}
    }
    margin-bottom: 24px;
`;

export const SearchInput = styled(DefaultSearchInput)`
    width: 100%;
`;

export const SurveyListWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

interface ListItemProps {
    disabled: boolean;
    selected: boolean;
}
export const SurveyListItem = styled.li<ListItemProps>`
    ${baseFont()}
    width: 100%;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.mediumMin}) {
        flex-direction: row;
        > div {
            &:first-of-type {
                margin-right: 76px;
            }
        }
    }
    align-items: flex-start;
    justify-content: space-between;
    background: rgba(44, 44, 44, ${p => (p.disabled ? '0.02' : '0.05')});
    color: rgba(44, 44, 44, ${p => (p.disabled ? '0.4' : '1')});
    &:first-of-type {
        margin-top: 32px;
    }
    margin-bottom: 20px;
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
    padding: 24px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    ${p =>
        !p.disabled &&
        css`
            &:hover {
                background: ${colors.titleGray};
                color: ${colors.white};
            }
        `}
    ${p =>
        p.selected &&
        css`
            background: ${colors.titleGray};
            color: ${colors.white};
        `}
`;

export const SurveyListItemTitle = styled.div`
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
`;

export const SurveyListItemDescription = styled.p`
    margin: 12px 0;
    a {
        font-weight: 700;
        color: ${colors.goldenrod};
        text-decoration: none;
    }
`;

export const SurveyListMeta = styled.div`
    display: flex;
    flex-direction: row;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 130px;
        flex-direction: column;
        align-items: flex-start;
    }
    justify-content: flex-start;
    align-items: center;
    & > div {
        &:not(:last-of-type) {
            margin-right: 16px;
        }
    }
`;

export const NoMatchingSurveysMessage = styled.p`
    ${baseFont()}
    color: ${colors.darkGray};
    text-align: center;
    width: 100%;
    
`;

export const Checkmark = styled(Check)<{ selected: boolean }>`
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    color: ${colors.white};
    position: absolute;
    top: 18px;
    right: 18px;
    width: 18px;
    height: 18px;
    ${p =>
        p.selected &&
        css`
            opacity: 1;
        `}
`;

export const MiddleWrapper = styled.div`
    margin: 36px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 64px;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const ConstraintsMiddleWrapper = styled(MiddleWrapper)`
    margin-top: 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 0;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    > button {
        &:first-of-type {
            margin-right: 24px;
        }
    }
`;

export const Counts = styled.div`
    background: ${colors.categoryCards.peach};
    color: ${colors.cornHarvest};
    ${baseFont()};
    padding: 16px 32px;
    margin: 0 0 18px 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin: 0 0 0 36px;
    }
`;

export const ClearAllButton = styled.button`
    border: none;
    outline: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    ${baseFont({ weight: 700 })}
    background: transparent;
    color: ${colors.darkGray};
    cursor: pointer;
`;

export const CategoryCount = styled(Counts)`
    display: inline-block;
    margin: 0 0 24px 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin: 0 0 32px 0;
    }
`;

export const ConstraintItem = styled.div<ListItemProps>`
    ${baseFont()}
    width: 100%;
    position: relative;
    text-align: center;
    display: block;
    background: rgba(44, 44, 44, ${p => (p.disabled ? '0.02' : '0.05')});
    color: rgba(44, 44, 44, ${p => (p.disabled ? '0.4' : '1')});
    margin-bottom: 20px;
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
    padding: 24px 64px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(50% - 12px);
    }
    ${p =>
        !p.disabled &&
        css`
            &:hover {
                background: ${colors.titleGray};
                color: ${colors.white};
            }
        `}
    ${p =>
        p.selected &&
        css`
            background: ${colors.titleGray};
            color: ${colors.white};
        `}
`;

export const ConstraintItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (min-width: ${breakpoints.mediumMin}) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const ZeroConstraintsMessage = styled.p`
    ${baseFont()}
    padding: 0;
    text-align: center;
    margin-top: 24px;
`;

export const Summary = styled.div`
    ${baseFont()}
    background: ${colors.categoryCards.peach};
    color: ${colors.cornHarvest};
    padding: 24px;
    margin: 18px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.mediumMin}) {
        justify-content: space-between;
    }
`;

export const SummaryLabelCell = styled.div`
    width: 100%;
    text-align: left;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 72px;
    }
`;

export const SummaryDataCell = styled.div`
    width: 100%;
    text-align: left;
    padding-left: 16px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        padding-left: 0;
        margin-bottom: 24px;
        width: calc(100% - 96px);
    }
`;

export const ResultsLink = styled(DefaultLink)`
    display: inline-block;
    ${baseFont({ weight: 700 })}
    outline: none;
    border: 1px solid ${colors.titleGray};
    border-radius: 4px;
    background: transparent;
    color: ${colors.titleGray};
    line-height: 1;
    padding: 10px 24px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.titleGray};
        color: ${colors.white};
    }
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
    }
`;

export const SectionDivider = styled.div`
    width: calc(100% - 72px);
    margin: 0 auto;
    text-align: left;
    ${baseFont({ weight: 900 })}
    color: ${colors.darkGray};
    text-transform: uppercase;
    letter-spacing: 1.4px;
    padding-bottom: 12px;
    margin-bottom: 48px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.23);
    @media(min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media(min-width: ${breakpoints.largeMin}) {
        width: 1056px;
        margin-bottom: 88px;
    }
    display: flex;
    justify-content: space-between;
`;

export const AnalysisResultsBackButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0 36px 0;
`;
