import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';

interface Props {
    onClick?: () => void;
    text?: string;
}

const StyledButton = styled(Button)`
    text-transform: none !important;
    padding: 0px !important;
    color: #2c2c2c !important;
    font-family: Nunito Sans !important;
    font-weight: bold !important;
    font-size: 14px !important;
    letter-spacing: 0.42px !important;
    .MuiButton-endIcon {
        margin-left: 5px !important;
        margin-right: -3px !important;
        margin-top: -1px; !important; 
    }
    .MuiSvgIcon-root {
        font-size: 18px !important;
        transform: rotate(180deg) !important;
    }
    white-space: nowrap;
`;

const ExportButton: React.FC<Props> = ({ onClick, text = 'Export CSV' }) => {
    return (
        <StyledButton onClick={onClick} endIcon={<OpenInBrowser />}>
            {text}{' '}
        </StyledButton>
    );
};

export default ExportButton;
