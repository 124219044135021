import { Constraint } from '../types';
import {AnalysisData} from "../../api/types";

export interface Parameters {
    surveyIds: string[];
    xAxisQuestionId: string;
    yAxisQuestionId: string;
    constraints: Constraint[];
    title: string;
}

export interface SurveyMeta {
    respondentCount: number;
    // @TODO - bring this back - SDDB-300
    //entries: number;
}

export enum DataDisplayType {
    StackedBarChart,
    Table
}

export interface xAxisTotalByAnswerText {
    xAxisAnswer: string;
    total: number;
}

export type TruthTable = boolean[][];

export interface State {
    barPadding: number;
    barWidth: number;
    colorScale: string[];
    constraintAnswers: string[];
    countsByXAxisIndex: number[][];
    data: AnalysisData[];
    hovering: boolean;
    hoveringTable: TruthTable;
    modalTrigger: ModalTriggerSourceButton | null;
    modalVisible: boolean;
    parameters: Parameters;
    surveyMeta: SurveyMeta;
    totalsByXAxisIndex: number[];
    xAxisLabelWidth: number;
    xAxisQuestion: string;
    xAxisAnswers: string[];
    yAxisQuestion: string;
    yAxisAnswers: string[];
}

export enum ActionType {
    setData,
    setAxisData,
    setParams,
    setSurveyMeta,
    setHovering,
    setModalVisible
}

export interface Coordinate {
    x: number;
    y: number;
}

export interface SetDataPayload {
    data: AnalysisData[];
    colorScale: string[];
    xAxisAnswers: string[];
    xAxisQuestion: string;
    yAxisAnswers: string[];
    yAxisQuestion: string;
    surveyMeta: SurveyMeta;
}

export interface SetAxisDataPayload {
    colorScale: string[];
    xAxisAnswers: string[];
    xAxisQuestion: string;
    yAxisAnswers: string[];
    yAxisQuestion: string;
}

type SetData = {
    type: ActionType.setData;
    payload: SetDataPayload;
};

type SetAxisData = {
    type: ActionType.setAxisData;
    payload: SetAxisDataPayload;
};

type SetParams = {
    type: ActionType.setParams;
    payload: Parameters;
};

type SetSurveyMeta = {
    type: ActionType.setSurveyMeta;
    payload: SurveyMeta;
};

type SetHovering = {
    type: ActionType.setHovering;
    payload: {
        hovering: boolean;
        coordinate: Coordinate;
    };
};

export enum ModalTriggerSourceButton {
    editChart,
    editSurveys,
    editXAxis,
    editYAxis,
    editConstraints
}

type SetModalVisible = {
    type: ActionType.setModalVisible;
    payload: { visible: boolean; source: ModalTriggerSourceButton | null };
};

export type ActionTypes =
    | SetData
    | SetAxisData
    | SetParams
    | SetSurveyMeta
    | SetHovering
    | SetModalVisible;

export type Reducer = (
    state: Readonly<State>,
    action: Readonly<ActionTypes>
) => State;
