import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddCircle from '@material-ui/icons/AddCircle';
import {
    ClearButton,
    Container,
    SearchButton,
    AdvancedSearchButton
} from './styled/SearchInput';
import { Theme } from '../../theme';

interface Props {
    className?: string;
    clearable?: boolean;
    enableOnBlurSearch?: boolean;
    handleSearch: (value: string) => void;
    handleUserInput?: (value: string) => void;
    large?: boolean;
    placeholder?: string;
    searchOnUserInput?: boolean;
    theme?: Theme;
    withAdvancedSearch?: boolean;
}

const keycodes = {
    enter: 13
};

const SearchInput: React.FC<Props> = ({
    className,
    clearable = false,
    enableOnBlurSearch,
    handleSearch,
    handleUserInput,
    large = false,
    placeholder,
    searchOnUserInput = false,
    theme,
    withAdvancedSearch
}) => {
    const [value, setValue] = useState('');

    const checkForEnter: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.keyCode === keycodes.enter) {
            validateInput();
        }
    };

    const handleBlur = () => {
        if (!enableOnBlurSearch) {
            return null;
        }
        validateInput();
    };

    const validateInput = () => {
        if (value !== '') {
            handleSearch(value);
        }
    };

    const onInput: (val: string) => void = val => {
        if (searchOnUserInput && handleUserInput) {
            handleUserInput(val);
        }
        setValue(val);
    };

    const onClearClick: () => void = () => {
        setValue('');
        if (searchOnUserInput && handleUserInput) {
            handleUserInput('');
        }
    };

    return (
        <Container className={className} large={large} theme={theme}>
            <input
                data-testid="input-node"
                onBlur={handleBlur}
                onChange={e => onInput(e.target.value)}
                onKeyUp={checkForEnter}
                placeholder={placeholder}
                type="text"
                value={value}
            />
            {clearable && value !== '' && (
                <ClearButton large={large} onClick={onClearClick}>
                    <CloseIcon />
                </ClearButton>
            )}
            {(!clearable || value === '') && (
                <SearchButton
                    data-testid="search-button"
                    large={large}
                    onClick={validateInput}
                >
                    <SearchIcon />
                </SearchButton>
            )}

            {withAdvancedSearch && (
                <AdvancedSearchButton
                    to="/search"
                    data-testid="advanced-search-button"
                    theme={theme}
                >
                    Advanced Search
                    <AddCircle />
                </AdvancedSearchButton>
            )}
        </Container>
    );
};

export default SearchInput;

SearchInput.defaultProps = {
    enableOnBlurSearch: false,
    handleSearch: () => null,
    large: false,
    placeholder: '',
    theme: Theme.light,
    withAdvancedSearch: false
};
