import { css, FlattenSimpleInterpolation } from 'styled-components';

export const colors = {
    black: '#000000',
    darkGray: '#2c2c2c',
    titleGray: '#3b3b3b',
    lightGray: '#707070',
    lightestGray: '#f8f8f8',
    goldenrod: '#B68A07',
    white: '#ffffff',
    offWhite: '#fafafa',
    pink: '#f4e2d2',
    cornHarvest: '#937006',
    bittersweet: '#FC6964',
    categoryCards: {
        peach: '#fff8f2',
        blue: '#f3f5f7',
        green: '#f4f4f1',
        pink: '#fcf5f6'
    }
};

const sizeMax = {
    small: 666,
    medium: 1279
};

export const breakpoints = {
    smallMax: `${sizeMax.small}px`,
    mediumMin: `${sizeMax.small + 1}px`,
    mediumMax: `${sizeMax.medium}px`,
    largeMin: `${sizeMax.medium + 1}px`
};

export enum Theme {
    'dark' = 'dark',
    'light' = 'light'
}

interface Args {
    size?: number;
    weight?: number;
}

type baseFont = (args?: Args) => FlattenSimpleInterpolation;

export const baseFont: baseFont = args => {
    return css`
        font-family: 'Nunito Sans', sans-serif;
        font-size: ${args && args.size ? `${args.size}` : '14'}px;
        font-weight: ${args && args.weight ? `${args.weight}` : 400};
    `;
};
