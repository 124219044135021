import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InfoIcon from '@material-ui/icons/Info';
import { Keyword } from '../types';
import {
    ExpandableContainer,
    Contents,
    Info,
    Section,
    SectionWithTable,
    SelectWrapper,
    Summary
} from './styled';
import Select from '../../shared/Select';
import Tooltip from '../../shared/Tooltip';
import { extractUuidFromFullPath } from '../../../utils';
import {
    getPercentageText,
    transformMetadataObjectsIntoKeywordsArray
} from '../utils';
import {ApiType, Survey, WordSearchMetadata} from "../../api/types";
import useApiGet from "../../api/useApiGet";
import useWordSearch from "../../api/useWordSearch";

interface Option {
    label: string;
    value: string;
}

interface Props {
    desktop?: boolean;
    freeSearchWords: string[][];
    keywordIds: string[][];
    searchData: WordSearchMetadata | null;
    searchEntries: number;
    searchPercentage: string;
    showNoBaselineText: boolean;
    totalEntries: number;
}
const BaselinePanel: React.FC<Props> = ({
    desktop = false,
    freeSearchWords,
    keywordIds,
    searchData,
    searchEntries,
    searchPercentage,
    showNoBaselineText,
    totalEntries
}) => {
    const [expanded, setExpanded] = useState(false);
    const [searchMetadata, setSearchMetadata] = useState<Keyword[]>([]);
    const [baselineMetadata, setBaselineMetadata] = useState<Keyword[]>([]);
    const [selectedBaselineTotal, setSelectedBaselineTotal] = useState(0);
    const [selectedBaseline, setSelectedBaseline] = useState<Option>({
        label: '',
        value: ''
    });
    const [
        selectedBaselineSearchTotal,
        setSelectedBaselineSearchTotal
    ] = useState(0);
    const [
        selectedBaselinePercentage,
        setSelectedBaselinePercentage
    ] = useState('');
    const [baselines, setBaselines] = useState<Survey[]>([]);
    const [maleBaselineSurveyId, setMaleBaselineSurveyId] = useState('');
    const [femaleBaselineSurveyId, setFemaleBaselineSurveyId] = useState('');
    const [totalMaleBaselineEntries, setTotalMaleBaselineEntries] = useState(0);
    const [
        totalFemaleBaselineEntries,
        setTotalFemaleBaselineEntries
    ] = useState(0);

    useEffect(() => {
        if (searchData !== null) {
            const keywords = transformMetadataObjectsIntoKeywordsArray(
                searchData
            );
            setSearchMetadata(keywords);
        }
    }, [searchData]);

    const { data: baselineSurveys } = useApiGet<Survey>({
        baseline: true,
    }, ApiType.Survey);

    useEffect(() => {
        if (baselineSurveys) {
            const defaultBaseline = baselineSurveys.find(
                survey => survey.name === 'Baseline Survey'
            );
            if (defaultBaseline) {
                setSelectedBaseline({
                    value: defaultBaseline.id,
                    label: defaultBaseline.name
                });
            }
            const maleBaseline = baselineSurveys.find(
                survey => survey.name === 'Baseline Survey (Male)'
            );
            if (maleBaseline) {
                setMaleBaselineSurveyId(maleBaseline.id);
            }
            const femaleBaseline = baselineSurveys.find(
                survey => survey.name === 'Baseline Survey (Female)'
            );
            if (femaleBaseline) {
                setFemaleBaselineSurveyId(femaleBaseline.id);
            }
            setBaselines(baselineSurveys);
        }
    }, [baselineSurveys]);

    const { data: baselineMetadataTotal } = useWordSearch({
        surveyIds: [extractUuidFromFullPath(selectedBaseline.value)],
        metadataOnly: true
    }, {
        active: selectedBaseline.value !== ''
    });

    const { data: baselineMetadataSearch } = useWordSearch({
        searchText: freeSearchWords,
        surveyIds: [extractUuidFromFullPath(selectedBaseline.value)],
        subCategoryIds: keywordIds.map(block =>
            {
                return block.map(id => extractUuidFromFullPath(id))
            }
        )
    }, {
        active: selectedBaseline.value !== ''
    });

    useEffect(() => {
        if (baselineMetadataTotal && baselineMetadataSearch) {
            const baselineMetadata =
                baselineMetadataSearch.metadata;
            const keywords = transformMetadataObjectsIntoKeywordsArray(
                baselineMetadata
            );
            setBaselineMetadata(keywords);
            setSelectedBaselineTotal(
                baselineMetadataTotal.dreamEntryCount
            );
            setSelectedBaselineSearchTotal(
                baselineMetadataSearch.dreamEntryCount
            );
            let percentage = '0%';

            if (
                baselineMetadataTotal.dreamEntryCount > 0 &&
                baselineMetadataSearch.dreamEntryCount > 0
            ) {
                percentage = getPercentageText(
                    baselineMetadataSearch.dreamEntryCount,
                    baselineMetadataTotal.dreamEntryCount
                );
            }
            setSelectedBaselinePercentage(percentage);
        }
    }, [baselineMetadataTotal, baselineMetadataSearch]);

    const { data: maleBaselineCount } = useWordSearch({
        surveyIds: [extractUuidFromFullPath(maleBaselineSurveyId)],
        metadataOnly: true
    }, {
        active: maleBaselineSurveyId !== ''
    });

    const { data: femaleBaselineCount } = useWordSearch({
        surveyIds: [extractUuidFromFullPath(femaleBaselineSurveyId)],
        metadataOnly: true
    }, {
        active: femaleBaselineSurveyId !== ''
    });

    useEffect(() => {
        if (maleBaselineCount) {
            setTotalMaleBaselineEntries(
                maleBaselineCount.dreamEntryCount
            );
        }
        if (femaleBaselineCount) {
            setTotalFemaleBaselineEntries(
                femaleBaselineCount.dreamEntryCount
            );
        }
    }, [maleBaselineCount, femaleBaselineCount]);

    const onBaselineSelect: (id: string) => void = id => {
        const selected = baselines.find(baseline => baseline.id === id);
        if (selected) {
            setSelectedBaseline({ value: id, label: selected.name });
        }
    };

    return (
        <ExpandableContainer
            desktop={desktop}
            onChange={(_, expanded) => setExpanded(expanded)}
        >
            <Summary>
                <span>
                    Baselines
                    <span>
                        <Tooltip
                            id="baseline-tooltip"
                            overrides={{ place: 'bottom' }}
                            trigger={<InfoIcon color="inherit" />}
                        >
                            <h3>Baselines</h3>
                            <p>
                                The SDDb Baselines are two curated sets of "most
                                recent dreams" from {totalMaleBaselineEntries}{' '}
                                males and {totalFemaleBaselineEntries} females,
                                gathered by several researchers from a variety
                                of populations between the 1950s and the
                                present. They are aggregated here to represent
                                typical densities of the appearance of key words
                                or phrases in ordinary dreaming.
                            </p>
                            <p>
                                By comparing the word counts of your search
                                against the baseline counts, you can gain
                                insight as to how typical or atypical your
                                results may be.
                            </p>
                        </Tooltip>{' '}
                    </span>
                </span>
                {!expanded && <AddIcon />}
                {expanded && <RemoveIcon />}
            </Summary>
            <Contents>
                {showNoBaselineText && (
                    <Section>
                        <p>
                            A search must include free text(s) and / or
                            keyword(s) in order to generate baseline comparison
                            data.
                        </p>
                    </Section>
                )}
                {!showNoBaselineText && (
                    <SelectWrapper>
                        <Select
                            clearable={false}
                            placeholder="Choose Baseline"
                            handleChange={onBaselineSelect}
                            options={baselines.map(baseline => ({
                                label: baseline.name,
                                value: baseline.id
                            }))}
                            styleOverrides={{ background: 'transparent' }}
                            value={selectedBaseline}
                        />
                    </SelectWrapper>
                )}
                {!showNoBaselineText &&
                    searchMetadata.map((keyword, i) => {
                        const searchTotal = keyword.subcategories.reduce(
                            (sum, subcategory) => subcategory.counts + sum,
                            0
                        );
                        const baselineTotal = keyword.subcategories.reduce(
                            (sum, subcategory) => {
                                const baselineKeyword = baselineMetadata.find(
                                    baselineKeyword =>
                                        baselineKeyword.keyword ===
                                        keyword.keyword
                                );
                                if (baselineKeyword) {
                                    const baselineSubcategory = baselineKeyword.subcategories.find(
                                        baselineSubcategory =>
                                            baselineSubcategory.word ===
                                            subcategory.word
                                    );
                                    if (baselineSubcategory) {
                                        return sum + baselineSubcategory.counts;
                                    }
                                }
                                return sum;
                            },
                            0
                        );
                        return (
                            <SectionWithTable key={i}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{keyword.keyword}</th>
                                            <th>Search</th>
                                            <th>Baseline</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <Tooltip
                                            id="top-total-tooltip"
                                            overrides={{ place: 'right' }}
                                            trigger={
                                                <tr id="total" key="total">
                                                    <td>Entries</td>
                                                    <td>
                                                        {`${searchEntries} (${searchPercentage})`}
                                                    </td>
                                                    <td>
                                                        {`${selectedBaselineSearchTotal} (${selectedBaselinePercentage})`}
                                                    </td>
                                                </tr>
                                            }
                                        >
                                            Dream Entries that contain at least
                                            one word from the category search
                                        </Tooltip>

                                        <tr id="spacer" />
                                        {keyword.subcategories
                                            .sort((a, b) => b.counts - a.counts)
                                            .map((subcategory, i) => {
                                                let baselinePercent = 0;
                                                let baselineCounts = 0;
                                                const baselineKeyword = baselineMetadata.find(
                                                    baselineKeyword =>
                                                        baselineKeyword.keyword ===
                                                        keyword.keyword
                                                );
                                                if (baselineKeyword) {
                                                    const baselineSubcategory = baselineKeyword.subcategories.find(
                                                        baselineSubcategory =>
                                                            baselineSubcategory.word ===
                                                            subcategory.word
                                                    );
                                                    if (
                                                        baselineSubcategory &&
                                                        selectedBaselineTotal >
                                                            0
                                                    ) {
                                                        baselineCounts =
                                                            baselineSubcategory.counts;
                                                        baselinePercent =
                                                            Math.round(
                                                                (baselineSubcategory.counts /
                                                                    selectedBaselineTotal) *
                                                                    1000
                                                            ) / 10;
                                                    }
                                                }
                                                let searchPercent = 0;
                                                if (totalEntries > 0) {
                                                    searchPercent =
                                                        Math.round(
                                                            (subcategory.counts /
                                                                totalEntries) *
                                                                1000
                                                        ) / 10;
                                                }
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {subcategory.word}
                                                        </td>
                                                        <td>
                                                            {`${subcategory.counts} (${searchPercent}%)`}
                                                        </td>
                                                        <td>
                                                            {`${baselineCounts} (${baselinePercent}%)`}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        <tr id="spacer" />
                                        <Tooltip
                                            id="bottom-total-tooltip"
                                            overrides={{ place: 'right' }}
                                            trigger={
                                                <tr
                                                    id="total"
                                                    className="bottom"
                                                    key="total"
                                                >
                                                    <td>Total</td>
                                                    <td>{searchTotal}</td>
                                                    <td>{baselineTotal}</td>
                                                </tr>
                                            }
                                        >
                                            Total count of all occurrences of
                                            category words within the search
                                        </Tooltip>
                                    </tbody>
                                </table>
                            </SectionWithTable>
                        );
                    })}
                <Section>
                    <Info>
                        The SDDb Baselines are two curated sets of "most recent
                        dreams" from {totalMaleBaselineEntries} males and{' '}
                        {totalFemaleBaselineEntries} females, gathered by
                        several researchers from a variety of populations
                        between the 1950s and the present. They are aggregated
                        here to represent typical densities of the appearance of
                        key words or phrases in ordinary dreaming.
                    </Info>
                </Section>
            </Contents>
        </ExpandableContainer>
    );
};

export default BaselinePanel;
