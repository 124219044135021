import {useEffect} from "react";

export const formatDate = (date: string): string => {
    const dateRegex = /(\d{4})-(\d{2})-(\d{2})/;
    const dateMatches = date.match(dateRegex);
    return dateMatches
        ? `${dateMatches[2]}-${dateMatches[3]}-${dateMatches[1]}`
        : '';
};

export const removeApiPrefix: (path: string) => string = path => {
    return path.replace('/api/sddb', '');
}

export const extractIdFromPath: (path: string) => string = path => {
    //split on / and take the last element
    const splitPath = path.split('/');
    return splitPath[splitPath.length - 1];
}

export function apiEquality (a1: any, a2: any): boolean {
    if (a1 === a2) {
        return true;
    } else if (typeof a1 !== typeof a2) {
        return false;
    }

    if (typeof a1 === 'object') {
        return apiObjectEquality(a1, a2);
    } else {
        return a1 === a2;
    }
}

export function apiObjectEquality (o1: any, o2: any): boolean {
    if (o1 === null || o1 === undefined || o2 === null || o2 === undefined) {
        return o1 === o2;
    }

    const o1Keys = Object.keys(o1);
    const o2Keys = Object.keys(o2);
    let equality = o1Keys.length === o2Keys.length
    if (equality) {
        o1Keys.forEach((key: string) => {
            equality = equality && apiEquality(o1[key], o2[key]);
        });
    }
    return equality;
}

export function buildQueryString(params: any): string {
    const buildParameter = (key: string): string => {
        const value: any = params[key];
        if (key.endsWith('[]') && Array.isArray(value)) {
            if (value.length > 0) {
                const urlParts: string[] = [];
                value.forEach(v => {
                    urlParts.push(key + '=' + v);
                });
                return urlParts.join('&');
            } else {
                return '';
            }
        } else {
            return key + '=' + value;
        }
    };

    const keys = Object.keys(params);
    const urlParts = keys.map(key => buildParameter(key));
    return urlParts.filter(urlPart => urlPart !== '').join('&');
}
