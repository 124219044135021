import {ApiType} from "./types";
import Api from "./Api";
import {useState} from "react";

const useApiCreate = <TInput, TOutput>(apiType: ApiType): {
    data: TOutput|null,
    create: (request: Partial<TInput>) => Promise<TOutput|null>,
    loading: boolean,
    error: any
} => {
    const [data, setData] = useState<TOutput|null>(null);
    const [error, setError] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);

    const create = async (request: Partial<TInput>): Promise<TOutput|null> => {
        const api = new Api();
        if (!request) {
            throw new Error('Cannot create without data.');
        }
        return await api.create<TOutput>(request, apiType);
    };

    const performCreate = async (request: Partial<TInput>): Promise<TOutput|null> => {
        try {
            setLoading(true);
            const data = await create(request);
            setLoading(false);
            setData(data);
            return data;
        } catch (e) {
            setError(e);
            setLoading(false);
            return null;
        }
    };

    return {
        data,
        loading,
        error,
        create: performCreate
    };
};

export default useApiCreate;
