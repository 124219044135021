import React  from 'react';
import { Answer, Question, Survey } from '../api/types';
import styled from 'styled-components';
import QuestionEntry from './QuestionEntry';
import DreamEntry from './DreamEntry';
import { Moment } from 'moment';

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid black;
    background-color: #ccc;
    padding: 10px;
    margin: 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
`;

//id to props
interface Props {
    survey: Survey
}

const SurveyUserAgreement: React.FC<Props> = ({ survey }) => {
    return (
        <div>
            <Content>
                <h2>Sleep and Dream Database User Agreement</h2>

                <b>1. Introduction</b>
                <p>
                    Welcome to the Sleep and Dream Database (SDDb). By submitting your survey responses and dream-related data to our website, you are agreeing to the terms and conditions outlined in this User Agreement. Please read this agreement carefully before proceeding.
                </p>

                <b>2. Data Storage and Protection</b>
                <p>
                    We take the privacy and security of your personal information seriously. All information you provide will be stored on secure servers, and we will implement appropriate technical and organizational measures to protect your data against unauthorized access, disclosure, alteration, or destruction.
                </p>

                <b>3. Use of Information</b>
                <p>
                    Your survey responses and dream-related data will be used solely for research purposes by the SDDb team. We will not share, sell, or disclose your information to any third parties without your explicit consent, except as needed for transient data processing or as required by law.
                </p>

                <b>4. Transient Data Processing</b>
                <p>
                    In the analysis and processing of your survey responses and dream-related data, we may send such data to third parties. We ensure that the data sent to these third parties are not stored and are only used for the purposes of data analysis. These third parties include, but are not limited to the following:
                    <ul>
                        <li>Elsewhere (an online dream journal and AI-powered analysis system partnered with the SDDb)</li>
                        <li>Google APIs for Natural Language Processing</li>
                        <li>OpenAI systems for AI-based analysis</li>
                    </ul>
                </p>

                <b>5. Consent</b>
                <p>
                    By submitting your data to the SDDb, you are providing your consent for us to collect, store, and use your information for research purposes in accordance with this User Agreement.
                </p>

                <b>6. Publication and Notification</b>
                <p>
                    If any research conducted using your data is published, we will make reasonable efforts to inform you of the publication. However, please note that we cannot guarantee notification in all cases.
                </p>

                <b>7. Public or Private Submissions</b>
                <p>
                    For most surveys, you have the option to make your survey answers and dream reports public or keep them private. If you choose to make your submissions public, they may be accessible to other users of the SDDb website and potentially to the general public. If you choose to keep your submissions private, they will only be accessible to the SDDb research team.

                    If a user elects to make their survey responses public, this also allows the collected data to be used for other research studies within the SDDb.

                    Some surveys are of such a nature that they require that the survey responses and dream reports be shared publicly and the survey will state this clearly by defaulting the choice of privacy to “public” within the form submission area and providing an explanatory note.
                </p>

                <b>8. Withdrawal of Consent</b>
                <p>
                    You have the right to withdraw your survey responses at any time. However, please note that once your data has been used in research, we cannot retroactively remove it from the study or any publications that may have resulted from it.

                    To request that your data be withdrawn from the SDDb, please contact us at team@elsewhere.to, providing us with the name, email, and respondent code associated with your submission.
                </p>

                <b>9. Changes to Agreement</b>
                <p>
                    We reserve the right to update or modify this User Agreement at any time. Any changes will be posted on our website, and we will make reasonable efforts to inform you of any significant changes.
                </p>

                <b>10. Contact Us</b>
                <p>
                    If you have any questions or concerns about this User Agreement or the handling of your data, please contact us at team@elsewhere.to
                </p>

                <p>
                    By submitting your survey responses and dream-related data to the Sleep and Dream Database, you acknowledge that you have read, understood, and agree to be bound by this User Agreement.
                </p>
            </Content>
        </div>
    );
};

export default SurveyUserAgreement;
