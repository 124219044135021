import styled, { css } from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { baseFont, breakpoints, colors } from '../../theme';
import DefaultButton from '../shared/Button';
import DefaultCopyright from '../shared/Copyright';

export const Panel = styled(ExpansionPanel)`
    &.MuiExpansionPanel-root {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 48px;
        padding-top: 48px;
        box-shadow: none;
        &::before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0 auto;
            margin-bottom: 48px;
        }
    }
`;

export const Summary = styled(ExpansionPanelSummary)`
    &.MuiExpansionPanelSummary-root {
        padding: 0;
        margin: 0;
        min-height: 44px;
        position: relative;
        &.Mui-expanded {
            min-height: 44px;
        }
        .MuiExpansionPanelSummary-content {
            margin: 0;
            flex-direction: column;
            &.Mui-expanded {
                margin: 0;
            }
        }
    }
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: block;
    color: ${colors.darkGray};
`;

export const SectionTitle = styled.div`
   ${baseFont({ weight: 900 })}
    color: ${colors.darkGray};
    text-transform: uppercase;
    letter-spacing: 1.4px;
`;

export const Contents = styled(ExpansionPanelDetails)`
    &.MuiExpansionPanelDetails-root {
        padding: 0;
        flex-direction: column;
        @media (min-width: ${breakpoints.mediumMin}) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
`;

export const Wrapper = styled.div`
    width: calc(100% - 72px);
    margin: 0 auto;
    padding: 90px 0 0 0;
    @media (max-width: ${breakpoints.smallMax}) {
        padding-bottom: 106px;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 740px;
    }
    background: ${colors.white};
`;

export const Top = styled.div`
    position: relative;
    button {
        @media (min-width: ${breakpoints.largeMin}) {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }
`;

export const FeaturedInputsWrapper = styled.div`
    margin: 48px 0 0 0;
    padding: 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        > div {
            width: calc(50% - 8px);
            margin: 0;
        }
    }
`;

export const Button = styled(DefaultButton)`
    margin-top: 24px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 16px;
    }
`;

export const BorderlessButton = styled(Button)`
    border: none;
`;

export const MultiContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 48px 0 0 0;
`;

export const BlockContainer = styled.div<{ inlineMedium?: boolean }>`
    display: block;
    padding: 48px 0 0 0;
    ${p =>
        p.inlineMedium &&
        css`
            @media (min-width: ${breakpoints.mediumMin}) {
                display: inline-block;
            }
        `}
`;

export const FlexContainer = styled.div<{ inlineMedium?: boolean }>`
    display: flex;
    padding: 48px 0 0 0;
    ${p =>
  p.inlineMedium &&
  css`
            @media (min-width: ${breakpoints.mediumMin}) {
                display: inline-block;
            }
        `}
`;

export const InputsContainer = styled.div`
    @media (min-width: ${breakpoints.mediumMin}) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        > div {
            width: calc(50% - 8px);
        }
    }
`;

export const SinglePickerContainer = styled.div`
    display: inline-block;
    width: calc(50% - 8px);
    @media (min-width: ${breakpoints.largeMin}) {
        width: calc(50% - 12px);
    }
    &:first-of-type {
        margin-right: 8px;
        @media (min-width: ${breakpoints.largeMin}) {
            margin-right: 12px;
        }
    }
    &:last-of-type {
        margin-left: 8px;
        @media (min-width: ${breakpoints.largeMin}) {
            margin-left: 12px;
        }
    }
`;

export const SingleSelectWrapper = styled.div`
    margin-top: 24px;
`;

export const SurveySummaryWrapper = styled.div`
    ${baseFont()};
    font-style: italic;
    color: rgba(44, 44, 44, 0.7);
    margin-top: 24px;
`;

export const SummaryText = styled.p`
    margin: 0;
    padding: 0;
    text-align: left;
    a {
        font-weight: 700;
        color: ${colors.goldenrod};
        text-decoration: none;
    }
`;

export const SurveyMeta = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    font-size: 12px;
`;

export const SurveyCounts = styled.div`
    span {
        :first-child {
            padding-right: 24px;
        }
    }
`;

export const DataSetMetadata = styled.div`
    position: relative;
    width: 100%;
    background: ${colors.categoryCards.peach};
    color: ${colors.cornHarvest};
    ${baseFont()};
    padding: 16px;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: flex;
        flex-direction: row;
    }
    > div {
        &:first-child {
            margin: 0 0 2px 0;
            @media (min-width: ${breakpoints.mediumMin}) {
                margin: 0 24px 0 0;
            }
            > span {
                &:first-child {
                    margin-right: 8px;
                }
            }
        }
        &:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            @media (min-width: ${breakpoints.mediumMin}) {
                flex-direction: row;
                flex-wrap: wrap;
            }
            > span {
                @media (min-width: ${breakpoints.mediumMin}) {
                    &:first-child {
                        margin-right: 16px;
                    }
                    &:nth-child(2) {
                        margin-right: 24px;
                    }
                }
            }
        }
    }
    > svg {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 18px;
        height: 18px;
    }
`;

export const SearchMetadata = styled(DataSetMetadata)`
    margin-top: 28px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 16px;
    }
`;

export const Column = styled.div``;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 60px;
    > div {
        &:last-child {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 40px;
        margin-bottom: 72px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
    }
`;

export const ButtonContainer = styled.div`
    &:first-child {
        margin-right: 8px;
    }
`;

export const Bottom = styled.div`
    position: relative;
    margin-top: 56px;
`;

export const NoResultsMessage = styled.div`
    color: hsl(0, 0%, 60%);
    padding: 8px 12px;
    text-align: center;
    ${baseFont()}
`;

export const Copyright = styled(DefaultCopyright)`
    @media (max-width: ${breakpoints.smallMax}) {
        margin-top: -110px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: 88px;
    }
`;
