import styled from 'styled-components';
import { baseFont, breakpoints } from '../../../theme';
import bg from '../../../assets/Dreams-of-Lucid-Awareness@3x.png';
import DefaultTitle from '../../shared/PageTitle';
import DefaultDivider from '../../shared/Divider';

export const Content = styled.div`
    position: absolute;
    width: 100%;
    top: 120px;
    @media (min-width: ${breakpoints.largeMin}) {
        top: 168px;
    }
    left: 0;
    right: 0;
    padding-bottom: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    ${baseFont()}
`;

export const CardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
        max-width: 745px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1140px;
        max-width: initial;
        justify-content: flex-start;
        > div {
            &:nth-child(3n + 1) {
                margin-right: 30px;
            }
            &:nth-child(3n + 2) {
                margin-right: 30px;
            }
        }
    }
`;

export const Title = styled(DefaultTitle)`
    margin-bottom: 40px;
    @media (min-width: ${breakpoints.largeMin}) {
        margin-bottom: 32px;
    }
`;

export const Divider = styled(DefaultDivider)`
    margin: 56px 0 14px 0;
    @media (min-width: ${breakpoints.largeMin}) {
        margin: 84px 0 48px;
    }
`;

export const Background = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 320px;
    background-image: url(${bg});
    background-size: 725px;
    background-position: -160px -310px;
    background-repeat: no-repeat;
    &::after {
        display: block;
        content: '';
        width: 100%;
        height: 320px;
        background: rgba(255, 255, 255, 0.91);
    }
    z-index: -1;
    @media (min-width: ${breakpoints.largeMin}) {
        width: 100%;
        max-width: ${breakpoints.largeMin};
        margin: 0 auto;
        background-position: 12px -386px;
        background-size: 810px;
    }
`;
