import { State, xAxisTotalByAnswerText } from './types';

type ValueFromState<T> = (state: State) => T;

const getTotalByXAxisAnswerText: (state: State, text: string) => number = (
    state,
    text
) =>
    state.data
        .filter(datum => datum.xAnswer === text)
        .reduce((sum, datum) => sum + datum.count, 0);

const getTotalsByXAxisAnswerTexts: ValueFromState<
    xAxisTotalByAnswerText[]
> = state =>
    state.xAxisAnswers.map(answer => ({
        xAxisAnswer: answer,
        total: getTotalByXAxisAnswerText(state, answer)
    }));

const getAnalysisTitle: ValueFromState<string> = state =>
    (state.parameters && state.parameters.title) || 'Analysis Results';

const selectors = {
    totalByXAxisAnswerText: getTotalByXAxisAnswerText,
    title: getAnalysisTitle,
    totalsByXAxisAnswerTexts: getTotalsByXAxisAnswerTexts
};

export default selectors;
