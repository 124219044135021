import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import { Theme } from '../../theme';
import { NavButtonWrapper, Wrapper } from './styled/DatePicker';
import { HintStatus } from './FormInput';
import {DualContainer, Hint} from './styled/FormInput';
import { Consumer } from '../../types';
import select, {Option} from "../advancedSearch/selectors";
import Select from "./Select";
import {Input, YearInput} from "../advancedSearch/FreeSearch";
import {BlockContainer} from "../advancedSearch/styled";

const NavButton: React.FC<{ reverse?: boolean; theme: Theme }> = ({
    reverse,
    theme
}) => (
    <NavButtonWrapper reverse={reverse ? reverse : false} theme={theme}>
        <Arrow />
    </NavButtonWrapper>
);

interface Props {
    date: Moment | null;
    id: string;
    onDateSelect: (date: Moment | null, isBce: string) => void;
    placeholder: string;
    theme?: Theme;
    onlyYear: boolean;
}

const YearMonthPicker: React.FC<Props> = ({
    date,
    id,
    onDateSelect,
    placeholder,
    theme,
  onlyYear
}) => {
    const [focused, setFocus] = useState<boolean | null>(false);
    const [_date, setDate] = useState<Moment | null>(date);
    const [isBce, setBce] = useState<Option|undefined>({label: 'CE', value: 'false'});
    const [year, setYear] = useState<string | undefined>();
    const [month, setMonth] = useState<Option | undefined>();
    const [hintStatus, setHintStatus] = useState<HintStatus>(HintStatus.hidden);


    useEffect(() => {
        setDate(date);
    }, [date]);

    useEffect(() => {
        if (year !== undefined) {
            let newMonth = month === undefined ? '01' : month.value;

            if (onlyYear){
                newMonth = id === 'start-date' ? '01' : '12';
            }

            const day = id === 'start-date' ? '01' : moment(year + '-' + newMonth).endOf('month').daysInMonth();
            const newDate = moment(year + '-' + newMonth + '-' + day);


            setDate(newDate);
            // @ts-ignore
            onDateSelect(newDate, isBce.value ? isBce.value : 'false');
        }

    }, [year, month, isBce] );

    useEffect(() => {
        if (focused) {
            setHintStatus(HintStatus.active);
        } else {
            if (_date) {
                setHintStatus(HintStatus.complete);
            } else {
                setHintStatus(HintStatus.hidden);
            }
        }
    }, [focused, _date]);


    return (
        <BlockContainer theme={theme ? theme : Theme.light} style={{display: 'flex', paddingTop: '0'}}>
            {
                !onlyYear && (
                  <div style={{width: '40%'}}>
                      <Select
                        handleChange={e=> setMonth(select.monthOptions().find(val => val.value === e))}
                        options={select.monthOptions()}
                        placeholder="Month"
                        value={month === undefined ? null : month}
                      />
                  </div>

                )
            }
            <YearInput
              onChange={e => setYear(e.target.value)}
              placeholder="YYYY"
              type='number'
              min={1000}
              max={9999}
              value={year}
              whiteInput={false}
              onlyYear={onlyYear}
            />
            <Select
                styleOverrides={{width: '130%'}}
                handleChange={e=> setBce(select.bceOptions().find(val => val.value === e))}
                options={select.bceOptions()}
                placeholder="CE/BCE"
                value={isBce === undefined ? null : isBce}
                clearable={false}
            />
        </BlockContainer>
    );
};

export default YearMonthPicker;
