import styled, { css } from 'styled-components';
import { baseFont, colors, Theme } from '../../theme';

interface Props {
    hoverTextColor?: string;
    theme: Theme;
}

const Button = styled.button<Props>`
    display: block;
    margin: 0 auto;
    outline: none;
    background: transparent;
    padding: 10px 24px 10px 24px;
    line-height: 1;
    border: 1px solid
        ${p => (p.theme === Theme.dark ? colors.white : colors.titleGray)};
    border-radius: 4px;
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.titleGray)};
    ${baseFont()}
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${p =>
            p.theme === Theme.dark ? colors.white : colors.titleGray};
        color: ${p => (p.hoverTextColor ? p.hoverTextColor : colors.white)};
        ${p =>
            p.theme === Theme.dark &&
            css`
                color: ${colors.titleGray};
            `}
    }
`;

export default Button;
