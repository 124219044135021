import {QueryConfig, WordSearch, WordSearchRequest} from "./types";
import Api from "./Api";
import {useEffect, useState} from "react";
import {apiEquality} from "./utils";

const useWordSearch = (request: Partial<WordSearchRequest>|null, config?: QueryConfig): {
    data: WordSearch|null,
    loading: boolean,
    error: any,
    refetch: () => Promise<WordSearch|null>
} => {
    const [data, setData] = useState<WordSearch|null>(null);
    const [error, setError] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [oldRequest, setOldRequest] = useState<Partial<WordSearchRequest>|null>(request);

    const search = async (request: Partial<WordSearchRequest>): Promise<WordSearch|null> => {
        const api = new Api();
        if (!request) {
            throw new Error('Cannot perform word search without request.');
        }
        return api.wordSearch(request);
    };

    const performSearch = async (request: Partial<WordSearchRequest>): Promise<WordSearch | null> => {
        try {
            setLoading(true);
            const data = await search(request);
            setData(data);
            setLoading(false);
            return data;
        } catch (e) {
            setError(e);
            setLoading(false);
            return null;
        }
    };

    let isActive = true;
    if (config && config.active !== undefined) {
        isActive = config.active;
    } else if (config && config.activeFn !== undefined) {
        isActive = config.activeFn();
    }

    //initial load once we have a request object
    useEffect(() => {
        if (isActive && !loading && !error && request) {
            if (!data) {
                //initial load
                performSearch(request);
            } else if (data && !apiEquality(request, oldRequest)) {
                //refetching on request change
                performSearch(request);
                setOldRequest(request);
            }
        }
    }, [request, loading, error, data, isActive]);

    return {
        data,
        loading,
        error,
        refetch: (): Promise<WordSearch|null> => {
            if (request) {
                return performSearch(request);
            } else {
                return new Promise(() => null);
            }
        }
    };
};

export default useWordSearch;
