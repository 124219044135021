import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    ExpandableContainer,
    Contents,
    Section,
    StatsTable,
    Summary
} from './styled';
import {WordSearchStatistics} from "../../api/types";

interface Props {
    data: WordSearchStatistics | null;
    desktop?: boolean;
}
const WordCountStatisticsPanel: React.FC<Props> = ({
    data,
    desktop = false
}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <ExpandableContainer
            desktop={desktop || false}
            last
            onChange={(_, expanded) => setExpanded(expanded)}
        >
            <Summary>
                <span>Word Count Statistics</span>
                {!expanded && <AddIcon />}
                {expanded && <RemoveIcon />}
            </Summary>
            <Contents>
                <Section>
                    {data && (
                        <StatsTable>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total Number of Words:</td>
                                    <td>{data.sum ? data.sum : 0}</td>
                                </tr>
                                <tr>
                                    <td>Lowest Word Count:</td>
                                    <td>{data.min ? data.min : 0}</td>
                                </tr>
                                <tr>
                                    <td>Highest Word Count:</td>
                                    <td>{data.max ? data.max : 0}</td>
                                </tr>
                                <tr>
                                    <td>Mean Word Count:</td>
                                    <td>
                                        {data.avg ? data.avg.toFixed(3) : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Median Word Count:</td>
                                    <td>
                                        {data.median
                                            ? data.median.toFixed(3)
                                            : 0 }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Standard Deviation:</td>
                                    <td>
                                        {data.std_deviation
                                            ? data.std_deviation.toFixed(3)
                                            : 0 }
                                    </td>
                                </tr>
                            </tbody>
                        </StatsTable>
                    )}
                </Section>
            </Contents>
        </ExpandableContainer>
    );
};

export default WordCountStatisticsPanel;
