import { Label, TextArea } from '../shared/styled/FormInput';
import React, { useState } from 'react';
import { Option } from '../advancedSearch/selectors';
import { Question } from '../api/types';
import { HintStatus } from '../shared/FormInput';
import Tooltip from '../shared/Tooltip';
import DatePicker from '../shared/DatePicker';
import { Moment } from 'moment';

interface Props {
    question: Question,
    handleSelect: (answer: string, date: Moment|null) => void,
}

const DreamEntry: React.FC<Props> = ({ question, handleSelect }) => {
    const [answer, setAnswer] = useState<Option>({
        label: '',
        value: ''
    });
    const [date, setDate] = useState<Moment | null>(null);

    const handleChange = (answer: string) => {
      handleSelect(answer, date);
        setAnswer({
            label: answer,
            value: answer
        });
    };

    return (
        <div>
            <div style={{marginBottom: '15px'}}>
                <Tooltip
                    id="date-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>Dream Date</Label>}
                >
                    <p>
                        This is the date of the dream if you remember it. You can pick the closest date that you know, or leave it blank.
                    </p>
                </Tooltip>
                <DatePicker date={date} id={"dream-date-picker"} onDateSelect={(date: Moment|null, isBce: string) => {
                    setDate(date);
                }} placeholder={"Dream date (if remembered)"} />
            </div>
            <div style={{marginBottom: '15px'}}>
                <Tooltip
                    id="dream-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>{question.title}</Label>}
                >
                    <p>
                        {question.text}
                    </p>
                </Tooltip>
                <TextArea
                    hintStatus={HintStatus.hidden}
                    dual={false}
                    placeholder={"In my dream..."}
                    onChange={(e) => handleChange(e.target.value)}
                    value={answer.value}
                />
            </div>
        </div>
    );
}

export default DreamEntry;