import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonWrapper,
    Content,
    Header,
    Input,
    Page,
    StyledCopyRight,
    SubHeader,
    TextBoxInput,
    TextButton,
    ThankYouIconWrapper,
    Wrapper,
    ThankYouHeader,
    ThankYouContent,
    PageTitle,
    ThankYouSubHeader,
    ExportButtonWrapper,
    FullWrapper
} from './styled/ExportCSVStyles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ExportCsvData } from '../advancedSearch/types';
import ThankYouIcon from './icons/ThankYouIcon';
import ExportButton from '../shared/ExportButton';
import AlertModal from './AlertModal';
import LoadingIndicator from '../shared/LoadingIndicator';
import postCSVExportData from './api';
import { ENTRYPOINT } from '../../config/entrypoint';
import {downloadCSV, transformWordSearchParametersToFilters} from '../advancedSearch/utils';
import { extractUuidFromFullPath } from '../../utils';

interface AlertType {
    message: string;
    header: string;
    showIcon: boolean;
    alertVisible: boolean;
}
const ExportCSV: React.FC = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [link, setLink] = useState('');
    const [projectDetails, setProjectDetails] = useState('');
    const [thankYou, setThankYou] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<AlertType>({
        message: '',
        header: '',
        showIcon: false,
        alertVisible: false
    });
    const history = useHistory();
    const scrollToTop = () => {
        const page = document.getElementById('export-page');
        if (page) page.scrollTo(0, 0);
    };
    useEffect(() => {
        let apiUrl = ENTRYPOINT as string;
        if (window._env_ && window._env_.API_URL) {
            apiUrl = window._env_.API_URL;
        }
        const url = new URL(`${apiUrl}/sddb/exportCsv`);
        if (history.location.state) {
            console.log(transformWordSearchParametersToFilters(history.location.state))
            const tempData = transformWordSearchParametersToFilters(history.location.state);
            let csvData: ExportCsvData = {};
            csvData.keywordIds = tempData.subCategoryIds;
            csvData.dreamTextIds = tempData.dreamEntryIds;
            csvData.respondentIds = tempData.respondentIds;
            csvData.constraints = tempData.constraints;
            csvData.freeSearchWords = tempData.searchText;
            csvData.freeSearchOperators = tempData.searchTextOperators;
            csvData.keywordOperators = tempData.subCategoryOperators;
            csvData.surveyIds = tempData.surveyIds;

            if (csvData.keywordIds) {
                url.searchParams.append(
                        'keywordIds',
                        JSON.stringify(csvData.keywordIds)
                    );
            }
            if (csvData.freeSearchOperators) {
                url.searchParams.append(
                  'freeSearchOperators',
                  JSON.stringify(csvData.freeSearchOperators)
                );
            }
            if (csvData.freeSearchWords) {
                url.searchParams.append(
                  'freeSearchWords',
                  JSON.stringify(csvData.freeSearchWords)
                );
            }
            if (csvData.keywordOperators) {
                url.searchParams.append(
                  'keywordOperators',
                  JSON.stringify(csvData.keywordOperators)
                );
            }
            if (csvData.surveyIds) {
                const surveys = csvData.surveyIds;
                surveys.forEach(survey => {
                    url.searchParams.append(
                        'surveyIds[]',
                        extractUuidFromFullPath(survey)
                    );
                });
            }
            if (csvData.dreamTextIds) {
                const dreamTextIds = csvData.dreamTextIds;
                dreamTextIds.forEach(dreamText => {
                    url.searchParams.append(
                        'dreamTextIds[]',
                        extractUuidFromFullPath(dreamText)
                    );
                });
            }
            if (csvData.respondentIds) {
                const respondentIds = csvData.respondentIds;
                respondentIds.forEach(respondent => {
                    url.searchParams.append(
                        'respondentIds[]',
                        extractUuidFromFullPath(respondent)
                    );
                });
            }
            if (csvData.constraints) {
                const preConstraints: {
                    questionId: string;
                    answerText: string;
                }[] = csvData.constraints;
                const constraints: {
                    questionId: string;
                    answerText: string[];
                }[] = [];
                preConstraints.forEach(
                    (
                        preConstraint: {
                            questionId: string;
                            answerText: string;
                        },
                        index: number
                    ) => {
                        const found = constraints.find(_item => {
                            return (
                                _item.questionId ===
                                extractUuidFromFullPath(
                                    preConstraint.questionId
                                )
                            );
                        });
                        if (found) {
                            found.answerText.push(preConstraint.answerText);
                        } else {
                            constraints.push({
                                questionId: extractUuidFromFullPath(
                                    preConstraint.questionId
                                ),
                                answerText: [preConstraint.answerText]
                            });
                        }
                    }
                );
                constraints.forEach(constraint => {
                    url.searchParams.append(
                        'constraints[]',
                        JSON.stringify(constraint)
                    );
                });
            }
        }
        setLink(url.href);
    }, [history.location.state]);
    const handleBack = () => history.goBack();
    const submitForm = async (skip?: boolean) => {
        const data = skip
            ? { link }
            : {
                  firstName,
                  lastName,
                  email,
                  projectDetails,
                  link
              };
        try {
            const response = await postCSVExportData(data);
            if (response.status === 201) {
                return;
            } else throw response;
        } catch (error) {
            setLoading(false);
            return setAlert({
                message: `An error has occurred while trying to submit your form.`,
                header: 'Error:',
                showIcon: true,
                alertVisible: true
            });
        }
    };
    const handleFormData = async () => {
        try {
            if (firstName || lastName || email || projectDetails) {
                setLoading(true);
                await submitForm();
                await downloadCSV(link);
                setThankYou(true);
                setLoading(false);
                scrollToTop();
            } else {
                setAlert({
                    message: 'Please fill out an input on the form to submit.',
                    header: '',
                    showIcon: true,
                    alertVisible: true
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            return setAlert({
                message: `An error has occurred while trying to submit your form.`,
                header: 'Error:',
                showIcon: true,
                alertVisible: true
            });
        }
    };
    const handleSkip = async () => {
        try {
            setLoading(true);
            await downloadCSV(link);
            await submitForm(true);
            setThankYou(true);
            setLoading(false);
            scrollToTop();
        } catch (error) {
            setLoading(false);
            return setAlert({
                message: `An error has occurred while trying to download your CSV.`,
                header: 'Error:',
                showIcon: true,
                alertVisible: true
            });
        }
    };
    useEffect(() => {
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (email && !emailRegex.test(email)) return setEmailValid(false);
        return setEmailValid(true);
    }, [email, emailValid]);
    return (
        <FullWrapper id="export-page">
            <Page>
                <TextButton onClick={handleBack} startIcon={<ArrowBack />}>
                    Back to Search
                </TextButton>
                {thankYou ? (
                    <ThankYou goBack={handleBack} link={link} />
                ) : (
                    <Wrapper>
                        <Header>
                            <PageTitle>Export CSV</PageTitle>
                        </Header>
                        <SubHeader>
                            We are constantly working on a better experience for
                            you at the Sleep and Dream Database and are
                            committed to your privacy. Rest assured your data
                            will not be shared with a 3rd party.
                        </SubHeader>
                        <Content>
                            {loading ? (
                                <LoadingIndicator />
                            ) : (
                                <>
                                    <Input
                                        variant="filled"
                                        label="First Name"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e =>
                                            setFirstName(e.target.value)
                                        }
                                        type="text"
                                        value={firstName}
                                    />
                                    <Input
                                        variant="filled"
                                        label="Last Name"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e =>
                                            setLastName(e.target.value)
                                        }
                                        type="text"
                                        value={lastName}
                                    />
                                    <Input
                                        variant="filled"
                                        label="Email"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => setEmail(e.target.value)}
                                        type="text"
                                        value={email}
                                        helperText={
                                            !emailValid &&
                                            'Please enter valid email'
                                        }
                                        error={!emailValid}
                                    />
                                    <TextBoxInput
                                        variant="filled"
                                        label="Tell us about your project, where you are based, and any other thoughts."
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        inputProps={{
                                            maxLength: 255
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        onChange={e =>
                                            setProjectDetails(e.target.value)
                                        }
                                        value={projectDetails}
                                    />
                                    <ButtonWrapper>
                                        <Button onClick={handleSkip}>
                                            Skip and Export CSV
                                        </Button>
                                        <Button
                                            onClick={handleFormData}
                                            disabled={!emailValid}
                                        >
                                            Submit and Export CSV
                                        </Button>
                                    </ButtonWrapper>
                                </>
                            )}
                        </Content>
                        <AlertModal
                            onModalClose={() =>
                                setAlert({
                                    message: '',
                                    header: '',
                                    showIcon: false,
                                    alertVisible: false
                                })
                            }
                            onContinueClick={() =>
                                setAlert({
                                    message: '',
                                    header: '',
                                    showIcon: false,
                                    alertVisible: false
                                })
                            }
                            trigger={alert.alertVisible}
                            body={alert.message}
                            header={alert.header}
                            showIcon={alert.showIcon}
                        />
                    </Wrapper>
                )}
            </Page>
            <StyledCopyRight />
        </FullWrapper>
    );
};

interface Props {
    goBack: () => void;
    link: string;
}
const ThankYou: React.FC<Props> = ({ goBack, link }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Wrapper>
            <ThankYouContent>
                <ThankYouIconWrapper>
                    <ThankYouIcon />
                </ThankYouIconWrapper>
                <ThankYouHeader>
                    <PageTitle>Thank you!</PageTitle>
                </ThankYouHeader>
                <ThankYouSubHeader>
                    We appreciate your participation. Enjoy digging through
                    these dreams.
                </ThankYouSubHeader>
                <Button onClick={goBack}>Back to Search results</Button>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <ExportButtonWrapper>
                        <ExportButton
                            text="Try Downloading Again"
                            onClick={async () => {
                                setLoading(true);
                                await downloadCSV(link);
                                setLoading(false);
                            }}
                        />
                    </ExportButtonWrapper>
                )}
            </ThankYouContent>
        </Wrapper>
    );
};
export default ExportCSV;
