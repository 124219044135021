import React from 'react';
import NonMobileNavMenu from './NonMobileNavigationMenu';
import MobileNavigationMenu from './MobileNavigationMenu';

const NavigationMenu: React.FC = () => (
    <>
        <NonMobileNavMenu />
        <MobileNavigationMenu />
    </>
);

export default NavigationMenu;
