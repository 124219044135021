import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import * as Styled from './styled';
import Templates from './templates/Templates';
import AnalysisBuilder from './builder/AnalysisBuilder';
import AnalysisResults from './results/AnalysisResults';
import Title from '../shared/PageTitle';
import Tabs from '../shared/Tabs';
import { Path, Supplier } from '../../types';
import { PageType } from './types';

interface Match {
    json: string;
    type: PageType;
}

interface Props extends RouteComponentProps<Match> {}

const Analysis: React.FC<Props> = ({ match }) => {
    const [pageType, setPageType] = useState<PageType>(PageType.default);
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        if (match.params.type) {
            if (pageType !== match.params.type) {
                setPageType(match.params.type);
            }
            switch (match.params.type) {
                case PageType.customBuilder:
                case PageType.customResults:
                    setActiveTab(2);
                    break;
                case PageType.templateResults:
                    setActiveTab(1);
                    break;
                default:
                    setActiveTab(1);
            }
        } else {
            setPageType(PageType.default);
            setActiveTab(1);
        }
    }, [match.params.type]);

    const getContent: Supplier<JSX.Element> = () => {
        switch (activeTab) {
            case 1:
                if (pageType === PageType.templateResults) {
                    return <AnalysisResults />;
                }
                return <Templates />;
            case 2:
                if (pageType === PageType.customResults) {
                    return <AnalysisResults customResults />;
                }
                return (
                    <Redirect to={Path.analyze.replace(':type?', 'custom_builder').replace(':json?', '')} />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {pageType !== PageType.customBuilder && (
                <Styled.Wrapper>
                    <Title>Analyze Survey Data</Title>
                    <Styled.TabsWrapper>
                        <Tabs
                            defaultSelected={activeTab}
                            options={[
                                { text: 'Examples', value: 1 },
                                { text: 'Custom', value: 2 }
                            ]}
                            onTabChange={setActiveTab}
                        />
                    </Styled.TabsWrapper>
                    {getContent()}
                    <Styled.Copyright />
                </Styled.Wrapper>
            )}
            {pageType === PageType.customBuilder && <AnalysisBuilder />}
        </>
    );
};

export default withRouter(Analysis);
