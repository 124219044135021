import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Export from '@material-ui/icons/OpenInBrowser';

import StackedBarChart from './StackedBarChart';
import SurveySummarySection from './SurveySummarySection';
import {
    SectionDivider,
    AnalysisResultsBackButtonWrapper,
    ResultsLink
} from '../builder/styled';
import { Consumer, Path, Supplier } from '../../../types';
import { Survey} from "../../api/types";
import ExportButton from '../../shared/ExportButton';

interface Props {
    customResults?: boolean;
}

const Results: React.FC<Props> = ({ customResults = false, ...rest }) => {
    const [surveySummaries, setSurveySummaries] = useState<Survey[]>([]);
    const [dataFetched, setDataFetched] = useState(false);
    const history = useHistory();
    const processRawSurveyData = (surveys: Survey[]) => {
        setDataFetched(true);
        setSurveySummaries(surveys);
    };

    const getBackButtonLink: Supplier<string> = () =>
        customResults
            ? Path.analyze
                  .replace(':type?', 'custom_builder')
                  .replace(':json?', '')
            : Path.analyze.replace('/:type?/:json?', '');

    const getBackButtonText: Supplier<string> = () =>
        customResults ? 'Build Another Analysis' : 'Select Another Example';

    return (
        <>
            {surveySummaries.length > 0 && (
                <>
                    <SurveySummarySection surveys={surveySummaries} />
                    <SectionDivider>
                        Analysis
                        <ExportButton
                            onClick={() => {
                                const params = surveySummaries.map(
                                    summary => summary.id
                                );
                                history.push('/exportCSV', {
                                    surveyIds: params
                                });
                            }}
                        >
                            Export CSV <Export />
                        </ExportButton>
                    </SectionDivider>
                </>
            )}
            <StackedBarChart onDataFetched={processRawSurveyData} />
            {dataFetched && (
                <AnalysisResultsBackButtonWrapper>
                    <ResultsLink to={getBackButtonLink()}>
                        {getBackButtonText()}
                    </ResultsLink>
                </AnalysisResultsBackButtonWrapper>
            )}
        </>
    );
};

export default Results;
