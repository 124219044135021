import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import { Theme } from '../../theme';
import { NavButtonWrapper, Wrapper } from './styled/DatePicker';
import { HintStatus } from './FormInput';
import { Hint } from './styled/FormInput';
import { Consumer } from '../../types';
import select, {Option} from "../advancedSearch/selectors";
import Select from "./Select";

const NavButton: React.FC<{ reverse?: boolean; theme: Theme }> = ({
    reverse,
    theme
}) => (
    <NavButtonWrapper reverse={reverse ? reverse : false} theme={theme}>
        <Arrow />
    </NavButtonWrapper>
);

interface Props {
    date: Moment | null;
    id: string;
    onDateSelect: (date: Moment | null, isBce: string) => void;
    placeholder: string;
    theme?: Theme;
    showBce?: boolean;
}

const returnYears = (date = moment()) => {
  let years = []
  for(let i = 1; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
  }
  return years;
}

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
        <select style={{border:"1px solid #e4e7e7"}} value={month.month()} onChange={(e) => { onMonthSelect(month, parseInt(e.target.value))} } > {moment.months().map((label, i) => ( <option value={i} key={i}>{label}</option> ))} </select>
        </div>
        <div>
        <select style={{marginLeft:"0.25em",border:"1px solid #e4e7e7"}} value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {returnYears(month)}
            </select>
        </div>
    </div>

const DatePicker: React.FC<Props> = ({
    date,
    id,
    onDateSelect,
    placeholder,
    theme,
    showBce
}) => {
    const [focused, setFocus] = useState<boolean | null>(false);
    const [_date, setDate] = useState<Moment | null>(date);
    const [hintStatus, setHintStatus] = useState<HintStatus>(HintStatus.hidden);
    const [isBce, setBce] = useState<Option|undefined>({label: 'CE', value: 'false'});


    useEffect(() => {
        // @ts-ignore
        onDateSelect(_date, isBce.value !== undefined ? isBce.value : 'false');
    }, [_date, isBce]);

    useEffect(() => {
        if (focused) {
            setHintStatus(HintStatus.active);
        } else {
            if (_date) {
                setHintStatus(HintStatus.complete);
            } else {
                setHintStatus(HintStatus.hidden);
            }
        }
    }, [focused, _date]);

    return (
        <Wrapper hintStatus={hintStatus} theme={theme ? theme : Theme.light} style={{display: 'flex'}}>
            <Hint status={hintStatus} theme={theme ? theme : Theme.light}>
                {placeholder}
            </Hint>
            <div style={{width: showBce ? '50%' : '80%'}}>
                <SingleDatePicker
                    block={false}
                    date={_date}
                    focused={focused ? focused : false}
                    hideKeyboardShortcutsPanel={true}
                    id={id}
                    isOutsideRange={() => false}
                    navNext={<NavButton theme={theme ? theme : Theme.light} />}
                    navPrev={
                        <NavButton reverse theme={theme ? theme : Theme.light} />
                    }
                    noBorder={true}
                    numberOfMonths={1}
                    onDateChange={dt => setDate(dt)}
                    renderMonthElement={renderMonthElement}
                    onFocusChange={e => setFocus(e.focused)}
                    placeholder={placeholder}
                />
            </div>
            {showBce && (
                <div style={{width: '30%'}}>
                    <Select
                        handleChange={e=> setBce(select.bceOptions().find(val => val.value === e))}
                        options={select.bceOptions()}
                        placeholder="CE/BCE"
                        value={isBce === undefined ? null : isBce}
                        clearable={false}
                    />
                </div>
            )}
        </Wrapper>
    );
};

export default DatePicker;
