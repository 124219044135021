import React from 'react';
import styled from 'styled-components';
import { baseFont, breakpoints, colors, Theme } from '../../theme';

interface Props {
    className?: string;
    text?: string;
    theme?: Theme;
    'data-for'?: string;
    'data-tip'?: string;
}

interface StyledProps {
    theme: Theme;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${baseFont({ weight: 900 })}
`;

const Text = styled.div<StyledProps>`
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.darkGray)};
    font-size: 12px;
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 14px;
    }
    text-align: center;
    text-transform: uppercase;
`;

const Rectangle = styled.div<StyledProps>`
    width: 72px;
    height: 9px;
    background: ${p => (p.theme === Theme.dark ? 'transparent' : colors.white)};
    border: 1px solid
        ${p => (p.theme === Theme.dark ? colors.white : colors.lightGray)};
    margin-top: 10px;
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: 18px;
    }
`;

const Divider: React.FC<Props> = props => {
    const containerProps = { ...props };
    delete containerProps.children;
    delete containerProps.text;
    delete containerProps.theme;
    const { text, theme } = props;
    return (
        <Container {...containerProps}>
            <Text theme={theme}>{text}</Text>
            <Rectangle data-testid="divider-shape" theme={theme} />
        </Container>
    );
};

export default Divider;
