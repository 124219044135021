import React, { useEffect, useState } from 'react';
import {
    Container,
    DualContainer,
    Hint,
    Input,
    Label,
    SingleInputContainer
} from './styled/FormInput';
import { Theme } from '../../theme';

enum Number {
    first,
    second
}

enum Focus {
    focus,
    blur
}

export enum HintStatus {
    hidden,
    active,
    complete,
    error
}

interface Props {
    dual?: boolean;
    label: string;
    number?: boolean;
    onBlur?: (value: string | string[]) => void;
    onInputChange?: (value: string | string[]) => void;
    onValidityChange: (validity: boolean[]) => void;
    placeholder: string | string[];
    theme?: Theme;
    validity: boolean[];
    value: string | string[];
}

const FormInput: React.FC<Props> = ({
    dual,
    label,
    number,
    onBlur = () => {},
    onInputChange = () => {},
    onValidityChange,
    placeholder,
    theme,
    validity,
    value
}) => {
    const [firstValue, updateFirstValue] = useState(
        dual ? value[0] : (value as string)
    );
    const [secondValue, updateSecondValue] = useState(dual ? value[1] : '');
    const [firstHintStatus, setFirstHintStatus] = useState(
        validity[0] ? HintStatus.hidden : HintStatus.error
    );
    const [secondHintStatus, setSecondHintStatus] = useState(HintStatus.hidden);
    const [_validity, setValidity] = useState(validity);

    useEffect(() => {
        setValidity(validity);
        if (dual) {
            if ((number && isNaN(parseInt(firstValue))) || firstValue === '') {
                if (validity[0]) {
                    setFirstHintStatus(HintStatus.hidden);
                } else {
                    setFirstHintStatus(HintStatus.error);
                }
            } else {
                setFirstHintStatus(HintStatus.complete);
            }
            if (
                (number && isNaN(parseInt(secondValue))) ||
                secondValue === ''
            ) {
                if (validity[1]) {
                    setSecondHintStatus(HintStatus.hidden);
                } else {
                    setSecondHintStatus(HintStatus.error);
                }
            } else {
                setSecondHintStatus(HintStatus.complete);
            }
        } else {
            if (firstValue === '') {
                setFirstHintStatus(HintStatus.hidden);
            } else {
                setFirstHintStatus(HintStatus.complete);
            }
        }
    }, [dual, validity]);

    const update = (number: Number, newValue: string) => {
        if (dual) {
            if (number === Number.first) {
                updateFirstValue(newValue);
                onInputChange([newValue, secondValue]);
                onValidityChange([true, _validity[1]]);
            } else {
                updateSecondValue(newValue);
                onInputChange([firstValue, newValue]);
                onValidityChange([_validity[0], true]);
            }
        } else {
            updateFirstValue(newValue);
            onInputChange(newValue);
            onValidityChange([true]);
        }
    };

    const handleInputFocusChange = (
        number: Number,
        focus: Focus,
        e?: React.FocusEvent<HTMLInputElement>
    ) => {
        let dispatch, value;
        if (number === Number.first) {
            dispatch = setFirstHintStatus;
            value = firstValue;
        }
        if (number === Number.second) {
            dispatch = setSecondHintStatus;
            value = secondValue;
        }
        if (focus === Focus.focus) {
            dispatch && dispatch(HintStatus.active);
        }
        if (focus === Focus.blur) {
            if (e) {
                if (
                    e.target.validity.patternMismatch ||
                    e.target.validity.badInput
                ) {
                    if (dual) {
                        if (number === Number.first) {
                            onInputChange([e.target.value, secondValue]);
                            onValidityChange([false, _validity[1]]);
                        } else {
                            onInputChange([firstValue, e.target.value]);
                            onValidityChange([_validity[0], false]);
                        }
                    } else {
                        onInputChange(e.target.value);
                        onValidityChange([false]);
                    }
                } else {
                    if (dual) {
                        if (number === Number.first) {
                            onValidityChange([true, _validity[1]]);
                            onBlur([e.target.value, secondValue]);
                        } else {
                            onValidityChange([_validity[0], true]);
                            onBlur([firstValue, e.target.value]);
                        }
                    } else {
                        onBlur(e.target.value);
                        onValidityChange([true]);
                    }
                    if (value === '') {
                        dispatch && dispatch(HintStatus.hidden);
                    } else {
                        dispatch && dispatch(HintStatus.complete);
                    }
                }
            }
        }
    };

    const getInputMarkup = () => {
        if (dual) {
            return (
                <DualContainer>
                    <SingleInputContainer dual={true}>
                        <Hint status={firstHintStatus} theme={theme}>
                            {placeholder[0]}
                        </Hint>
                        <Input
                            dual={true}
                            hintStatus={firstHintStatus}
                            onBlur={e =>
                                handleInputFocusChange(
                                    Number.first,
                                    Focus.blur,
                                    e
                                )
                            }
                            onChange={e => update(Number.first, e.target.value)}
                            onFocus={() =>
                                handleInputFocusChange(
                                    Number.first,
                                    Focus.focus
                                )
                            }
                            {...(number && {
                                pattern: '[0-9]*',
                                inputmode: 'numeric',
                                step: '1'
                            })}
                            placeholder={placeholder[0]}
                            theme={theme}
                            type="text"
                            formNoValidate
                            value={firstValue}
                        />
                    </SingleInputContainer>
                    <SingleInputContainer dual={true}>
                        <Hint status={secondHintStatus} theme={theme}>
                            {placeholder[1]}
                        </Hint>
                        <Input
                            dual={true}
                            hintStatus={secondHintStatus}
                            onBlur={e =>
                                handleInputFocusChange(
                                    Number.second,
                                    Focus.blur,
                                    e
                                )
                            }
                            onChange={e =>
                                update(Number.second, e.target.value)
                            }
                            onFocus={() =>
                                handleInputFocusChange(
                                    Number.second,
                                    Focus.focus
                                )
                            }
                            {...(number && {
                                pattern: '[0-9]*',
                                inputmode: 'numeric',
                                step: '1'
                            })}
                            placeholder={placeholder[1]}
                            theme={theme}
                            type="text"
                            value={secondValue}
                        />
                    </SingleInputContainer>
                </DualContainer>
            );
        } else {
            return (
                <SingleInputContainer dual={false}>
                    <Hint status={firstHintStatus} theme={theme}>
                        {placeholder}
                    </Hint>
                    <Input
                        dual={false}
                        hintStatus={firstHintStatus}
                        onBlur={e =>
                            handleInputFocusChange(Number.first, Focus.blur, e)
                        }
                        onChange={e => update(Number.first, e.target.value)}
                        onFocus={() =>
                            handleInputFocusChange(Number.first, Focus.focus)
                        }
                        {...(number && {
                            pattern: '[0-9]*',
                            inputmode: 'numeric',
                            step: '1'
                        })}
                        placeholder={placeholder as string}
                        theme={theme}
                        type="text"
                        value={firstValue}
                    />
                </SingleInputContainer>
            );
        }
    };

    return (
        <Container dual={dual || false}>
            <Label dual={dual || false} theme={theme}>
                {label}
            </Label>
            {getInputMarkup()}
        </Container>
    );
};

export default FormInput;

FormInput.defaultProps = {
    dual: false,
    label: '',
    placeholder: '',
    theme: Theme.light
};
