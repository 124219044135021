import {ApiType} from "./types";
import Api from "./Api";
import {useEffect, useState} from "react";

const useApiGetOne = <Type>(id: string|null|undefined, apiType: ApiType): {
    data: Type|null,
    loading: boolean,
    error: any,
    refetch: () => Promise<Type|null>
} => {
    const [data, setData] = useState<Type|null>(null);
    const [error, setError] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);

    const fetch = async (): Promise<Type|null> => {
        const api = new Api();
        if (!id) {
            throw new Error('Cannot fetch without ID.');
        }
        return await api.getOne<Type>(id, apiType);
    };

    const getOne = async (): Promise<Type | null> => {
        try {
            setLoading(true);
            const data = await fetch();
            setLoading(false);
            setData(data);
            return data;
        } catch (e) {
            setError(e);
            setLoading(false);
            return null;
        }
    };

    useEffect(() => {
        if (id && data === null) {
            getOne();
        }
    }, [id]);

    return {
        data,
        loading,
        error,
        refetch: (): Promise<Type|null> => {
            return getOne();
        }
    };
};

export default useApiGetOne;
