import { Label } from '../shared/styled/FormInput';
import { SelectWrapper } from '../advancedSearch/results/styled';
import Select from '../shared/Select';
import React, { useState } from 'react';
import { Option } from '../advancedSearch/selectors';
import { Question } from '../api/types';
import Tooltip from '../shared/Tooltip';
import moment, { Moment } from 'moment';

interface Props {
    question: Question,
    handleSelect: (answer: string, date: Moment|null) => void,
}

const QuestionEntry: React.FC<Props> = ({ question, handleSelect }) => {
    const [answer, setAnswer] = useState<Option | null>(null);
    //create new instnace of moment

    const handleChange = (answer: string) => {
      handleSelect(answer, moment());
        setAnswer({
            label: answer,
            value: answer
        });
    };

    return (
        <>
            <Tooltip
                id={question.id + '-tooltip'}
                overrides={{ place: 'bottom' }}
                trigger={<Label style={{ textAlign: 'center'}}>{question.title}</Label>}
            >
                <p>
                    {question.text}
                </p>
            </Tooltip>
            <SelectWrapper>
                <Select
                    clearable={false}
                    placeholder={"Select " + question.title}
                    handleChange={handleChange}
                    options={question.allowedAnswers.map(a => {
                        return {
                            label: a,
                            value: a
                        };
                    })}
                    styleOverrides={{ background: 'transparent' }}
                    value={answer}
                />
            </SelectWrapper>
        </>
    );
}

export default QuestionEntry;