import React, { useState } from 'react';
import { Consumer } from '../../../types';
import {
    Checkmark,
    ClearAllButton,
    Counts,
    MiddleWrapper,
    NoMatchingSurveysMessage,
    SearchInput,
    SurveyListItem,
    SurveyListItemDescription,
    SurveyListItemTitle,
    SurveyListMeta,
    SurveyListWrapper
} from './styled';
import {Survey} from "../../api/types";

interface Props {
    onSurveySelectionChange: Consumer<string[]>;
    selectedSurveyIds: string[];
    surveys: Survey[];
}

const ChooseData: React.FC<Props> = ({
    onSurveySelectionChange,
    selectedSurveyIds,
    surveys
}) => {
    const [searchText, setSearchText] = useState('');

    const handleSurveyListItemClick: Consumer<string> = clicked => {
        const selected = selectedSurveyIds.find(id => id === clicked);
        if (selected) {
            onSurveySelectionChange(
                selectedSurveyIds.filter(selected => selected !== clicked)
            );
        } else if (selectedSurveyIds.length >= 3) {
            return;
        } else {
            onSurveySelectionChange([...selectedSurveyIds, clicked]);
        }
    };

    const isItemSelected: (id: string) => boolean = id =>
        selectedSurveyIds.includes(id);

    const filteredSurveys: (surveys: Survey[]) => Survey[] = surveys =>
        surveys.filter(
            survey =>
                survey.name.toLowerCase().indexOf(searchText) !== -1 ||
                (survey.description &&
                    survey.description.toLowerCase().indexOf(searchText) !== -1)
        );

    return (
        <div>
            <SearchInput
                clearable
                handleSearch={() => null}
                handleUserInput={input => setSearchText(input.toLowerCase())}
                placeholder="Find Surveys"
                searchOnUserInput
            />
            <MiddleWrapper>
                <Counts>
                    {filteredSurveys(surveys).length} surveys{' '}
                    {filteredSurveys(surveys).length < surveys.length
                        ? 'found'
                        : 'in database'}
                </Counts>
                <ClearAllButton onClick={() => onSurveySelectionChange([])}>
                    Clear All
                </ClearAllButton>
            </MiddleWrapper>
            <SurveyListWrapper>
                {filteredSurveys(surveys).map(survey => (
                    <SurveyListItem
                        disabled={
                            !isItemSelected(survey.id) &&
                            selectedSurveyIds.length >= 3
                        }
                        key={survey.id}
                        onClick={() => handleSurveyListItemClick(survey.id)}
                        selected={isItemSelected(survey.id)}
                    >
                        <Checkmark selected={isItemSelected(survey.id)} />
                        <div>
                            <SurveyListItemTitle>
                                {survey.name}
                            </SurveyListItemTitle>
                            <SurveyListItemDescription dangerouslySetInnerHTML={{__html: survey.description || 'N/A'}} />
                        </div>
                        <div>
                            <SurveyListMeta>
                                <div>{survey.year}</div>
                                <div>{`${survey.respondentCount} Participant${
                                    survey.respondentCount === 1 ? '' : 's'
                                }`}</div>
                            </SurveyListMeta>
                        </div>
                    </SurveyListItem>
                ))}
                {filteredSurveys(surveys).length === 0 && (
                    <NoMatchingSurveysMessage>
                        No matching surveys found.
                    </NoMatchingSurveysMessage>
                )}
            </SurveyListWrapper>
        </div>
    );
};

export default ChooseData;
