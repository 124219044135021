import {Respondent} from "./components/advancedSearch/types";
import {Survey} from "./components/api/types";

export type Consumer<T> = (arr: T) => void;
export type Supplier<T> = () => T;
export type Runnable = () => void;

export enum Path {
    analyze = '/analyze/:type?/:json?',
    home = '/',
    library = '/library',
    responseSetDetail = '/response_sets/:id/:featuredDream?',
    search = '/search',
    searchResults = '/search_results/:json?',
    exportCSV = '/exportCSV',
    openSurveys = '/open_surveys',
    openSurvey = '/open_survey/:id',
}

export interface Answer {
    answerText: string;
    id: string;
    question: string;
    responseSet: string;
    wordCount?: number;
    isDreamEntry?: boolean;
}

export interface AnswerWithHighlight extends Answer {
    sample: string;
}

export interface ResponseSet {
   id: string;
   createdAt: string;
   respondent: Respondent;
   survey: Survey;
   answers: Answer[];
};
