import React, {useEffect, useReducer, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import {initialState, reducer} from './state';
import {decode, extractUuidFromFullPath} from '../../../utils';
import {combineConstraints} from './utils';
import {ActionType, DataDisplayType, ModalTriggerSourceButton, Parameters, SetAxisDataPayload} from './types';
import {PageType} from '../types';
import select from './selectors';
import Button from '../../shared/Button';
import LoadingIndicator from '../../shared/LoadingIndicator';
import Tooltip from '../../shared/Tooltip';
import DataTable from './DataTable';
import ConfirmationModal from './ConfirmationModal';
import {
    AxisBounds,
    Background,
    ButtonWrapper,
    Cell,
    ChartArea,
    ConstraintsContainer,
    ConstraintsList,
    ConstraintsTitle,
    DataViewButton,
    DataViewButtonRow,
    EditButton,
    LabelsContainer,
    LabelText,
    LabelTotal,
    Legend,
    LegendListContainer,
    LegendListItem,
    LegendListItemColor,
    LegendListItemText,
    LegendTitle,
    Scrollable,
    SingleBar,
    SingleLabel,
    Subtitle,
    Title,
    TitleWrapper,
    Wrapper,
    XAxisTitle,
    YAxisTitle
} from './styled';
import {colorScale, graphAreaHeight} from './constants';
import {templateAnalyses} from '../templates/constants';
import {Analysis, AnalysisRequest, ApiType, Question, Survey} from "../../api/types";
import useApiGet from "../../api/useApiGet";
import useApiCreate from "../../api/useApiCreate";
import useApiGetOne from "../../api/useApiGetOne";

interface Props extends RouteComponentProps<{ type: string; json: string }> {
    onDataFetched?: (surveys: Survey[]) => void;
}

const StackedBarChart: React.FC<Props> = ({
    match,
    onDataFetched = () => null
}) => {
    let history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [dataDisplayType, setDataDisplayType] = useState(
        DataDisplayType.StackedBarChart
    );

    /* Search parameters decoded from url */
    useEffect(() => {
        if (match.params.type === PageType.templateResults) {
            const template = templateAnalyses.find(
                template =>
                    template.url.toLowerCase() ===
                    match.params.json.toLowerCase()
            );
            if (template) {
                const parameters = template.params;
                dispatch({ type: ActionType.setParams, payload: parameters });
                if (state.parameters.surveyIds.length === 0) {
                    createAnalysis({
                        surveyIds: parameters.surveyIds.map(id =>
                            extractUuidFromFullPath(id)
                        ),
                        xAxisQuestionId: extractUuidFromFullPath(
                            parameters.xAxisQuestionId
                        ),
                        yAxisQuestionId: extractUuidFromFullPath(
                            parameters.yAxisQuestionId
                        ),
                        constraints: combineConstraints(parameters.constraints)
                    });
                }
            }
        }
        const parameters = decode<Parameters>(match.params.json);
        if (parameters !== undefined) {
            dispatch({
                type: ActionType.setParams,
                payload: parameters
            });
            if (state.parameters.surveyIds.length === 0) {
                createAnalysis({
                    surveyIds: parameters.surveyIds.map(id =>
                        extractUuidFromFullPath(id)
                    ),
                    xAxisQuestionId: extractUuidFromFullPath(
                        parameters.xAxisQuestionId
                    ),
                    yAxisQuestionId: extractUuidFromFullPath(
                        parameters.yAxisQuestionId
                    ),
                    constraints: combineConstraints(parameters.constraints)
                });
            }
        }
    }, [match.params]);


    const { data: xAxisQuestion, loading: xAxisQuestionLoading } = useApiGetOne<Question>(state.parameters.xAxisQuestionId, ApiType.Question);
    const { data: yAxisQuestion, loading: yAxisQuestionLoading } = useApiGetOne<Question>(state.parameters.yAxisQuestionId, ApiType.Question);

    const { data, create: createAnalysis, loading } = useApiCreate<AnalysisRequest, Analysis>(ApiType.Analysis);
    console.log('created analysis', data);

    const { data: surveys, loading: surveysLoading } = useApiGet<Survey>({
        'id[]': state.parameters.surveyIds && state.parameters.surveyIds.map(id => extractUuidFromFullPath(id)),
        enabled: true
    }, ApiType.Survey, {
        active : state.parameters.surveyIds && state.parameters.surveyIds.length > 0
    });

    useEffect(() => {
        if (
            !dataLoading() &&
            surveys &&
            xAxisQuestion && yAxisQuestion &&
            data
        ) {
            console.log('analysis loaded', data);
            const axisData: SetAxisDataPayload = {
                xAxisAnswers: xAxisQuestion.allowedAnswers,
                xAxisQuestion: xAxisQuestion.title,
                yAxisAnswers: yAxisQuestion.allowedAnswers,
                yAxisQuestion: yAxisQuestion.title,
                colorScale: colorScale
                    .slice(0, yAxisQuestion.allowedAnswers.length)
                    .reverse()
            };

            const filtered = data.analysisData.filter(
                datum =>
                    axisData.xAxisAnswers.includes(datum.xAnswer) &&
                    axisData.yAxisAnswers.includes(datum.yAnswer)
                    // this is probably bad
                    // the issue is that we now have allowed answers that weren't on the original surveys
                    // like gender now has "intersex" as an allowed answer but there will never be any data here
                    // the tradeoff is that we now exclude legit questions that no one ever answered
                    // so we need to figure out a better way to handle this
                    && datum.count > 0
            );
            const respondentCount = filtered.reduce(
                (sum, datum) => sum + datum.count,
                0
            );
            axisData.xAxisAnswers = axisData.xAxisAnswers.filter(
                answer =>
                    filtered.find(datum => datum.xAnswer === answer) !== undefined
            );
            dispatch({
                type: ActionType.setData,
                payload: {
                    surveyMeta: {
                        respondentCount
                    },
                    ...axisData,
                    data: data.analysisData
                }
            });
            onDataFetched && onDataFetched(surveys);
        } else {
            console.log('not ready', xAxisQuestion, yAxisQuestion, data, surveys);
        }
    }, [xAxisQuestion, yAxisQuestion, data, surveys]);

    const getSingleBarContents = (index: number) => {
        try {
            const xAxisAnswer = state.xAxisAnswers[index];
            const values = [...state.yAxisAnswers].reverse().map(answer => {
                const applicableValues = state.data.filter(
                    datum => datum.xAnswer === xAxisAnswer
                );
                const value = applicableValues.find(
                    value => value.yAnswer === answer
                );
                if (value === undefined) {
                    return 0;
                }
                return value.count;
            });
            const total = select.totalByXAxisAnswerText(state, xAxisAnswer);
            if (total === 0) {
                return <></>;
            }

            /*
              To make sure each bar is the same height,
              we have to account for the 2 pixel gap between each cell.
              Thus, the total number of cells times the 2 pixel gap is the total
              number of pixels we can't use for calculating our percentages.
              Additionally, if the value is 0, they have no height
              and are therefore removed from the calculation.
            */
            const emptyPixels = values.filter(value => value > 0).length * 2;
            const availablePixels = graphAreaHeight - emptyPixels;
            const highestValue = values.reduce((highest, current) => {
                if (current > highest) {
                    return current;
                }
                return highest;
            }, 0);

            return values.map((value, i) => {
                const yAxisAnswer = [...state.yAxisAnswers].reverse()[i];
                const height = (value / total) * availablePixels;
                const percent = `${Math.round((value / total) * 100)}%`;
                const fade = state.hovering && !state.hoveringTable[i][index];
                return (
                    <React.Fragment key={i}>
                        <Tooltip
                            id={`${index}-${i}`}
                            overrides={{
                                overridePosition: position => ({
                                    top: position.top + 8,
                                    left: position.left
                                }),
                                place: 'bottom'
                            }}
                            trigger={
                                <Cell
                                    color={state.colorScale[i]}
                                    height={height}
                                    onMouseEnter={() =>
                                        dispatch({
                                            type: ActionType.setHovering,
                                            payload: {
                                                hovering: true,
                                                coordinate: { x: i, y: index }
                                            }
                                        })
                                    }
                                    onMouseLeave={() =>
                                        dispatch({
                                            type: ActionType.setHovering,
                                            payload: {
                                                hovering: false,
                                                coordinate: { x: i, y: index }
                                            }
                                        })
                                    }
                                    fade={fade}
                                >
                                    {value === highestValue ? percent : ''}
                                </Cell>
                            }
                        >
                            <p>
                                {state.xAxisQuestion}: {xAxisAnswer}
                            </p>
                            <p>
                                {state.yAxisQuestion}: <span>{percent}</span>{' '}
                                answered
                            </p>
                            <p>"{yAxisAnswer}"</p>
                        </Tooltip>
                    </React.Fragment>
                );
            });
        } catch (e) {
            console.warn(e);
            return <React.Fragment key={index}/>;
        }
    };

    const handleEditButtonClick: (
        source: ModalTriggerSourceButton
    ) => void = source => {
        dispatch({
            type: ActionType.setModalVisible,
            payload: { source, visible: true }
        });
    };

    const sendUserToBuilder: () => void = () => {
        let params: Partial<Parameters> = { ...state.parameters };
        delete params.title;
        switch (state.modalTrigger) {
            case ModalTriggerSourceButton.editChart:
            case ModalTriggerSourceButton.editSurveys:
                return history.push('/analyze/custom_builder');
            case ModalTriggerSourceButton.editXAxis:
                delete params.xAxisQuestionId;
            case ModalTriggerSourceButton.editYAxis:
                delete params.yAxisQuestionId;
            case ModalTriggerSourceButton.editConstraints:
                delete params.constraints;
                return history.push(
                    `/analyze/custom_builder/${encodeURIComponent(
                        JSON.stringify(params)
                    )}`
                );
        }
    };

    const getConstraintEditButton: () => JSX.Element = () => {
        const icon =
            state.constraintAnswers.length === 0 ? <AddIcon /> : <CreateIcon />;
        return (
            <EditButton
                add={state.constraintAnswers.length === 0}
                onClick={() =>
                    handleEditButtonClick(
                        ModalTriggerSourceButton.editConstraints
                    )
                }
            >
                {icon}
            </EditButton>
        );
    };

    const dataLoading: () => boolean = () =>
        loading ||
        xAxisQuestionLoading ||
        yAxisQuestionLoading ||
        surveysLoading;

    return (
        <Wrapper>
            {dataLoading() && <LoadingIndicator />}
            {!dataLoading() && (
                <>
                    <Background>
                        <ButtonWrapper>
                            <Button
                                onClick={() =>
                                    handleEditButtonClick(
                                        ModalTriggerSourceButton.editChart
                                    )
                                }
                            >
                                Start Over
                            </Button>
                        </ButtonWrapper>
                        <TitleWrapper>
                            <Title>{select.title(state)}</Title>
                            <Subtitle>
                                {`Survey Summary: ${
                                    state.surveyMeta.respondentCount
                                } Participant${
                                    state.surveyMeta.respondentCount === 1
                                        ? ''
                                        : 's'
                                }`}
                                <EditButton
                                    onClick={() =>
                                        handleEditButtonClick(
                                            ModalTriggerSourceButton.editSurveys
                                        )
                                    }
                                >
                                    <CreateIcon />
                                </EditButton>
                            </Subtitle>
                            <DataViewButtonRow>
                                <DataViewButton
                                    active={
                                        dataDisplayType ===
                                        DataDisplayType.StackedBarChart
                                    }
                                    onClick={() =>
                                        setDataDisplayType(
                                            DataDisplayType.StackedBarChart
                                        )
                                    }
                                >
                                    <BarChartIcon color="inherit" />
                                </DataViewButton>
                                <DataViewButton
                                    active={
                                        dataDisplayType ===
                                        DataDisplayType.Table
                                    }
                                    onClick={() =>
                                        setDataDisplayType(
                                            DataDisplayType.Table
                                        )
                                    }
                                >
                                    <TableChartIcon color="inherit" />
                                </DataViewButton>
                            </DataViewButtonRow>
                        </TitleWrapper>
                    </Background>
                    <Background
                        hidden={
                            dataDisplayType !== DataDisplayType.StackedBarChart
                        }
                    >
                        <Scrollable>
                            <YAxisTitle>Total Responses (%)</YAxisTitle>
                            <ChartArea>
                                <AxisBounds height={graphAreaHeight}>
                                    {state.xAxisAnswers.length > 0 &&
                                        state.xAxisAnswers.map((_, i) => (
                                            <SingleBar
                                                key={i}
                                                width={state.barWidth}
                                                padding={state.barPadding}
                                            >
                                                {getSingleBarContents(i)}
                                            </SingleBar>
                                        ))}
                                </AxisBounds>
                                <LabelsContainer>
                                    {state.xAxisAnswers.map((answer, i) => {
                                        const count = select.totalByXAxisAnswerText(
                                            state,
                                            answer
                                        );
                                        const total =
                                            state.surveyMeta.respondentCount;
                                        const percentage = Math.round(
                                            (count / total) * 100
                                        );
                                        return (
                                            <Tooltip
                                                id={`xAxis-Label-${i}`}
                                                overrides={{
                                                    overridePosition: position => ({
                                                        top: position.top - 16,
                                                        left: position.left
                                                    }),
                                                    place: 'bottom'
                                                }}
                                                trigger={
                                                    <SingleLabel
                                                        key={i}
                                                        width={
                                                            state.xAxisLabelWidth
                                                        }
                                                    >
                                                        <LabelText>
                                                            {answer}
                                                        </LabelText>
                                                        <LabelTotal>
                                                            {select.totalByXAxisAnswerText(
                                                                state,
                                                                answer
                                                            )}
                                                        </LabelTotal>
                                                    </SingleLabel>
                                                }
                                            >
                                                <p>{answer}</p>
                                                <p>
                                                    {count} / {total}{' '}
                                                    respondents ({percentage}%)
                                                </p>
                                            </Tooltip>
                                        );
                                    })}
                                </LabelsContainer>
                            </ChartArea>
                        </Scrollable>
                    </Background>
                    <Background>
                        <XAxisTitle
                            tableView={
                                dataDisplayType === DataDisplayType.Table
                            }
                        >
                            {state.xAxisQuestion}
                            <EditButton
                                onClick={() =>
                                    handleEditButtonClick(
                                        ModalTriggerSourceButton.editXAxis
                                    )
                                }
                            >
                                <CreateIcon />
                            </EditButton>
                        </XAxisTitle>
                    </Background>
                    <Background
                        hidden={dataDisplayType !== DataDisplayType.Table}
                        wrap
                    >
                        <DataTable
                            data={state.data}
                            xAxisAnswers={state.xAxisAnswers}
                            xAxisQuestion={state.xAxisQuestion}
                            xAxisTotals={select.totalsByXAxisAnswerTexts(state)}
                            yAxisAnswers={state.yAxisAnswers}
                            yAxisEditButtonClickHandler={() =>
                                handleEditButtonClick(
                                    ModalTriggerSourceButton.editYAxis
                                )
                            }
                            yAxisQuestion={state.yAxisQuestion}
                        />
                    </Background>
                    <Background
                        hidden={
                            dataDisplayType !== DataDisplayType.StackedBarChart
                        }
                    >
                        <Legend>
                            <LegendTitle>
                                {state.yAxisQuestion}
                                <EditButton
                                    onClick={() =>
                                        handleEditButtonClick(
                                            ModalTriggerSourceButton.editYAxis
                                        )
                                    }
                                >
                                    <CreateIcon />
                                </EditButton>
                            </LegendTitle>
                            <LegendListContainer>
                                {state.yAxisAnswers.map((answer, i) => (
                                    <Tooltip
                                        id={`legend-item-${i}`}
                                        overrides={{
                                            overridePosition: position => ({
                                                top: position.top,
                                                left: position.left - 100
                                            }),
                                            place: 'top'
                                        }}
                                        trigger={
                                            <LegendListItem>
                                                <LegendListItemColor
                                                    color={
                                                        [
                                                            ...state.colorScale
                                                        ].reverse()[i]
                                                    }
                                                />
                                                <LegendListItemText
                                                    data-tip=""
                                                    data-for={`legend-item-${i}`}
                                                >
                                                    {answer}
                                                </LegendListItemText>
                                            </LegendListItem>
                                        }
                                    >
                                        <p>{answer}</p>
                                    </Tooltip>
                                ))}
                            </LegendListContainer>
                        </Legend>
                    </Background>
                    <Background>
                        <ConstraintsContainer
                            empty={state.constraintAnswers.length === 0}
                            tableView={
                                dataDisplayType === DataDisplayType.Table
                            }
                        >
                            <ConstraintsTitle
                                tableView={
                                    dataDisplayType === DataDisplayType.Table
                                }
                            >
                                Constraints
                                {getConstraintEditButton()}
                            </ConstraintsTitle>
                            <ConstraintsList>
                                {state.constraintAnswers.join('; ')}
                            </ConstraintsList>
                        </ConstraintsContainer>
                    </Background>
                </>
            )}
            {state.modalVisible && (
                <ConfirmationModal
                    onContinueClick={sendUserToBuilder}
                    onModalClose={() =>
                        dispatch({
                            type: ActionType.setModalVisible,
                            payload: { source: null, visible: false }
                        })
                    }
                    trigger={state.modalTrigger}
                />
            )}
        </Wrapper>
    );
};

export default withRouter(StackedBarChart);
