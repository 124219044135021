import React, { useEffect, useState } from 'react';
import { TabsContainer, TabButton } from './styled/Tabs';
import { Theme } from '../../theme';

interface TabsOption {
    text: string;
    value: number;
}

interface Props {
    defaultSelected?: number;
    onTabChange: (tab: number) => void;
    options: TabsOption[];
    theme?: Theme;
}

const Tabs: React.FC<Props> = ({
    defaultSelected,
    onTabChange,
    options,
    theme
}) => {
    const [selected, select] = useState(options[0]);

    useEffect(() => {
        const defaultSelection = options.find(
            option => option.value === defaultSelected
        );
        if (defaultSelection) {
            select(defaultSelection);
        }
    }, [defaultSelected]);

    const onTabClick = (option: TabsOption) => {
        if (option.value !== selected.value) {
            select(option);
            onTabChange(option.value);
        }
    };

    return (
        <TabsContainer>
            {options.map((option, i) => {
                return (
                    <TabButton
                        active={option.value === selected.value}
                        key={`tab-option-${i}`}
                        onClick={() => onTabClick(option)}
                        theme={theme}
                    >
                        {option.text}
                    </TabButton>
                );
            })}
        </TabsContainer>
    );
};

export default Tabs;

Tabs.defaultProps = {
    defaultSelected: 0,
    onTabChange: () => null,
    options: [],
    theme: Theme.light
};
