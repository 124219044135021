import { Constraint } from '../types';

export enum TemplateAnalysisUrl {
    EducationVRecall = 'education_level_versus_dream_recall',
    GenderVFalling = 'gender_versus_falling_dreams',
    MaritalStatusVTalking = 'marital_status_versus_talking_about_dreams',
    PoliticsVVisitation = 'political_ideology_versus_visitation_dreams',
    ReligionVRecall = 'religious_affiliation_versus_dream_recall',
    GenderVRecall = 'gender_versus_dream_recall_18_to_34_year_olds'
}

export interface TemplateAnalysis {
    url: TemplateAnalysisUrl;
    params: {
        title: string;
        surveyIds: string[];
        xAxisQuestionId: string;
        yAxisQuestionId: string;
        constraints: Constraint[];
    };
    title: string;
    imageUrl: string;
    highlight: string;
    respondentCount: number;
}
