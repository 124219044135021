import React from 'react';
import { Button, Card, Subtitle, Title } from './styled/Card';
import { Runnable } from '../../types';
import { colors } from '../../theme';

interface Props {
    buttonText: string;
    color?: string;
    img?: string;
    loading?: boolean;
    onButtonClick: Runnable;
    subtitle?: string;
    title: string;
}

const CategoryCard: React.FC<Props> = ({
    buttonText,
    color = colors.categoryCards.peach,
    img = '',
    loading = false,
    onButtonClick,
    subtitle = '',
    title
}) => (
    <Card color={color} img={loading ? '' : img}>
        {!loading && (
            <>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <Button hoverTextColor={color} onClick={onButtonClick}>
                    {buttonText}
                </Button>
            </>
        )}
    </Card>
);
export default CategoryCard;
