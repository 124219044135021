import { ActionType, Reducer, State } from './types';
import { Question} from "../../api/types";

export const initialState: State = {
    currentStep: 0,
    initialized: false,
    initialValues: {},
    questions: [],
    selectedSurveyIds: [],
    selectedXAxisQuestion: null,
    selectedYAxisQuestion: null,
    selectedConstraints: [],
    surveys: [],
    title: ''
};

export const reducer: Reducer = (state, action) => {
    switch (action.type) {
        case ActionType.setInitialValues:
            return {
                ...state,
                initialValues: action.payload
            };
        case ActionType.initialized:
            return {
                ...state,
                initialized: true
            };
        case ActionType.setSurveys:
            return {
                ...state,
                surveys: action.payload,
                ...checkForSurveyPresets(state)
            };
        case ActionType.setCurrentStep:
            return {
                ...state,
                currentStep: action.payload
            };
        case ActionType.incrementStep:
            return {
                ...state,
                currentStep: state.currentStep + 1
            };
        case ActionType.setSelectedSurveyIds:
            return {
                ...state,
                selectedSurveyIds: action.payload
            };
        case ActionType.setQuestions:
            return {
                ...state,
                questions: action.payload,
                ...checkForAxisPresets(state, action.payload)
            };
        case ActionType.setXAxis:
            return {
                ...state,
                selectedXAxisQuestion: action.payload
            };
        case ActionType.setYAxis:
            return {
                ...state,
                selectedYAxisQuestion: action.payload
            };
        case ActionType.setSelectedConstraints:
            return {
                ...state,
                selectedConstraints: action.payload
            };
        case ActionType.goToPreviousStep:
            const newFields: Partial<State> = {};
            switch (action.payload) {
                case 0:
                    newFields.selectedSurveyIds = [];
                case 1:
                    newFields.selectedXAxisQuestion = null;
                case 2:
                    newFields.selectedYAxisQuestion = null;
                case 3:
                    newFields.selectedConstraints = [];
                default:
                    newFields.title = '';
                    newFields.currentStep = action.payload;
            }
            return {
                ...state,
                ...newFields
            };
        case ActionType.setTitle:
            return {
                ...state,
                title: action.payload
            };
        default:
            return state;
    }
};

const checkForSurveyPresets: (state: State) => Partial<State> = state => {
    if (state.initialized) {
        return {};
    } else if (state.initialValues.xAxisQuestionId) {
        // we're still not initialized, so pass the survey ids and return
        return {
            selectedSurveyIds: state.initialValues.surveyIds
        };
    } else {
        // we're initialzed
        return {
            selectedSurveyIds: state.initialValues.surveyIds,
            currentStep: 1,
            initialized: true
        };
    }
};

const checkForAxisPresets: (
    state: State,
    questions: Question[]
) => Partial<State> = (state, questions) => {
    if (questions.length === 0 && state.initialValues.xAxisQuestionId) {
        return {};
    }
    if (state.initialized) {
        return {};
    } else if (state.initialValues.yAxisQuestionId) {
        return {
            selectedXAxisQuestion: questions.find(
                question => question.id === state.initialValues.xAxisQuestionId
            ),
            selectedYAxisQuestion: questions.find(
                question => question.id === state.initialValues.yAxisQuestionId
            ),
            initialized: true,
            currentStep: 3
        };
    } else {
        return {
            selectedXAxisQuestion: questions.find(
                question => question.id === state.initialValues.xAxisQuestionId
            ),
            initialized: true,
            currentStep: 2
        };
    }
};
