import React from 'react';
import * as Styled from './styled/Library';
import Search from '../shared/dreamTextSearch/DreamTextSearch';
import { libraryEntries } from '../advancedSearch/constants';
import TemplateCard from './TemplateCard';
import { colors } from '../../theme';

const cardColors: string[] = [
    colors.categoryCards.peach,
    colors.categoryCards.blue,
    colors.categoryCards.green,
    colors.categoryCards.peach,
    colors.categoryCards.peach,
    colors.categoryCards.pink
];

const Library: React.FC = () => (
    <>
        <Styled.Background />
        <Styled.Content>
            <Styled.Title>Explore the Dreams</Styled.Title>
            <Search placeholder="Find a dream" />
            <Styled.Divider text={`Batches O' Dreams`} />
            <Styled.CardsContainer>
                {libraryEntries.map((entry, i) => (
                    <TemplateCard
                        color={cardColors[i % cardColors.length]}
                        image={entry.image}
                        key={entry.title}
                        params={entry.params}
                        title={entry.title}
                        url={entry.url}
                    />
                ))}
            </Styled.CardsContainer>
        </Styled.Content>
    </>
);

export default Library;
