import React from 'react';
import { Link } from 'react-router-dom';
import PieChart from '@material-ui/icons/PieChart';
import CloudIcon from '@material-ui/icons/Cloud';
import SearchIcon from '@material-ui/icons/Search';
import PostAddIcon from '@material-ui/icons/PostAdd';
import * as Styled from './styled';
import NonMobileNavigationButton from './NonMobileNavigationButton';
import Tooltip from '../shared/Tooltip';
import { Path } from '../../types';

export enum ButtonName {
    AnalyzeData = 'Analyze Dreams',
    Participate = 'Participate',
    DreamLibrary = 'Explore the Dreams',
    Search = ''
}

interface ButtonMeta {
    icon: React.ReactElement;
    name: ButtonName;
    path: string;
}

const buttons: ButtonMeta[] = [
    {
        icon: <PieChart />,
        name: ButtonName.AnalyzeData,
        path: Path.analyze.replace(':type?/:json?', '')
    },
    {
        //a good icon for this would be a sharing icon
        icon: <PostAddIcon />,
        name: ButtonName.Participate,
        path: Path.openSurveys
    },
    {
        icon: <CloudIcon />,
        name: ButtonName.DreamLibrary,
        path: Path.library
    },
    {
        icon: <SearchIcon />,
        name: ButtonName.Search,
        path: Path.search
    }
];

const NonMobileNavigationMenu: React.FC = () => (
    <Styled.Navigation>
        <Styled.Left>
            <Link to={Path.home}>Sleep and Dream Database</Link>
            {false && <Styled.ExternalLink href="https://kellybulkeley.org/contact/" target="_blank">Contact</Styled.ExternalLink>}
        </Styled.Left>
        <Tooltip
            id="heading-tooltip"
            overrides={{ place: 'bottom' }}
            trigger={<Styled.Center>Dream Data Analysis</Styled.Center>}
        >
            <p>
                The Sleep and Dream Database is an open-access digital archive
                that supports new empirical research in the study of dreams. The
                SDDb is directed by Kelly Bulkeley, Ph.D.
            </p>
        </Tooltip>
        <Styled.Right>
            {buttons.map(button => (
                <NonMobileNavigationButton
                    icon={button.icon}
                    key={button.name}
                    name={button.name}
                    path={button.path}
                />
            ))}
        </Styled.Right>
    </Styled.Navigation>
);

export default NonMobileNavigationMenu;
