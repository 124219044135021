import styled, { css } from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import { Background as LibraryBackground } from '../../library/styled/Library';
import DefaultButton from '../../shared/Button';
import { BackButton as DefaultBackButton } from '../../analysis/builder/styled';
import { baseFont, breakpoints, colors } from '../../../theme';

export const MetadataContainer = styled.div<{ searchData?: boolean }>`
    position: relative;
    width: 100%;
    background: ${p =>
        p.searchData ? colors.categoryCards.peach : colors.white};
    color: ${colors.cornHarvest};
    ${baseFont()};
    padding: 16px;
    margin: ${p => (p.searchData ? '24px 0 16px 0' : '0 0 56px 0')};
    @media (min-width: ${breakpoints.mediumMin}) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 450px;
        margin: 0 auto;
        margin-bottom: 56px;
        ${p =>
            p.searchData &&
            css`
                margin: 24px 0 16px 0;
            `}
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: ${p => (p.searchData ? 488 : 630)}px;
        ${p =>
            p.searchData &&
            css`
                margin-left: 350px;
                margin-top: 0;
            `}
    }
    > div {
        &:not(:last-of-type) {
            @media (min-width: ${breakpoints.mediumMin}) {
                margin: 0 16px 0 0;
            }
            > span {
                &:first-child {
                    margin-right: 8px;
                    @media (min-width: ${breakpoints.mediumMin}) {
                        margin-right: 16px;
                    }
                }
            }
        }
        ${p =>
            p.searchData &&
            css`
                &:nth-child(3) {
                    @media (min-width: ${breakpoints.largeMin}) {
                        width: 100%;
                    }
                }
            `}
    }
    > svg {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 18px;
        height: 18px;
    }
`;

export const Wrapper = styled.div`
    width: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
        padding-bottom: 44px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1128px;
        padding-bottom: 88px;
    }
`;
export const ExportButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    width: 100%;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 450px;
        padding-bottom: 0px;
        padding-top: 40px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        height: 72px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 18px;
        width: 100%;
    }
`;
export const Background = styled(LibraryBackground)`
    background-color: ${colors.lightestGray};
    background-size: 850px;
    background-position: -68px -378px;
    width: calc(100% - 36px);
    left: 36px;
    z-index: 0;
    &::after {
        background: rgba(248, 248, 248, 0.94);
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 768px;
        right: 0;
        left: initial;
        background-position: 400px -338px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1128px;
        background-position: 860px -280px;
    }
`;

export const Top = styled.div`
    background: ${colors.lightestGray};
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: -72px;
        padding-top: 72px;
    }
    > div {
        &:first-child {
            position: relative;
            width: calc(100% - 72px);
            z-index: 1;
            margin: 0 auto;
            @media (max-width: ${breakpoints.smallMax}) {
                padding-top: 100px;
            }
            @media (min-width: ${breakpoints.largeMin}) {
                width: 744px;
            }
        }
    }
`;

export const BackButton = styled(DefaultBackButton)`
    left: 0;
`;

export const InputsWrapper = styled.div`
    padding: 0;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 88px);
        margin: 0 auto;
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        > div {
            width: calc(50% - 8px);
            margin: 0;
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 100%;
        > div {
            width: calc(50% - 12px);
        }
    }
`;

export const MultiContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 24px 0 0 0;
`;

export const Breadcrumbs = styled.div`
    margin: 48px 0 28px;
    text-align: center;
    ${baseFont()}
    color: ${colors.lightGray};
    @media (max-width: ${breakpoints.smallMax}) {
      display: none;
    }
`;

export const Breadcrumb = styled(Link)`
    border: none;
    outline: none;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: none;
        text-decoration: none;
    }
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
`;

export const MobileButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 28px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 21px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        display: none;
    }
`;

export const TopRow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: ${breakpoints.largeMin}) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: flex-end;
        margin: 0 0 18px;
    }
`;

export const SortMenu = styled.div`
    position: absolute;
    right: 0px;
    top: 8px;
    @media (max-width: ${breakpoints.mediumMax}) {
        display: none;
    }
`;

export const SortMenuList = styled.div<{ show: boolean }>`
    display: ${p => (p.show ? 'block' : 'none')};
    position: absolute;
    top: 48px;
    right: 0;
    width: 250px;
    border: 1px solid ${colors.titleGray};
    border-radius: 4px;
    padding: 20px 0 24px 32px;
    background: ${colors.white};
`;

export const SortMenuButton = styled(DefaultButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    height: 36px;
    width: 250px;
    justify-content: space-between;
    span {
        margin-right: 12px;
    }
    background: ${colors.white};
`;

export const FilterListWrapper = styled.div`
    width: calc(100% - 72px);
    margin: 0 auto;
    padding: 60px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > div {
        width: 100%;
        margin-bottom: 32px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        > div {
            ${baseFont()}
            &:first-child {
                font-weight: 700;
                color: ${colors.titleGray};
            }
            &:last-child {
                color: ${colors.lightGray};
                padding-left: 8px;
            }
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 100%;
        padding-left: 8px;
        padding-top: 16px;
    }
`;

export const MobileSort = styled.div<{ onBackdrop: boolean }>`
    position: relative;
    ${p =>
        p.onBackdrop &&
        css`
            z-index: 1;
        `}
`;

export const MobileSortMenu = styled.div<{ show: boolean }>`
    width: 257px;
    background: ${colors.white};
    visibility: ${p => (p.show ? 'visible' : 'hidden')};
    display: block;
    position: absolute;
    right: 0;
    top: 54px;
    opacity: ${p => (p.show ? 1 : 0)};
    transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid ${colors.titleGray};
    border-radius: 4px;
    padding: 20px 0 24px 32px;
`;

export const SortBackdrop = styled(Backdrop)`
    &.MuiBackdrop-root {
        background-color: rgba(96, 96, 96);
    }
`;

export const SortOption = styled.div<{ selected: boolean }>`
    position: relative;
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    color: ${colors.darkGray};
    ${baseFont()}
    ${p =>
        p.selected &&
        css`
            font-weight: 700;
        `}
`;

export const SortOptionBullet = styled.div`
    position: absolute;
    top: 6px;
    left: -16px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${colors.goldenrod};
`;

export const DesktopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

export const FilterColumn = styled.div`
    @media (max-width: ${breakpoints.mediumMax}) {
        display: none;
    }
    width: 326px;
    margin-right: 24px;
    margin-top: -105px;
    h2 {
        padding: 0;
        margin: 0;
        color: ${colors.darkGray};
        ${baseFont({ weight: 900 })}
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }
`;

export const DreamEntryListContainer = styled.div`
    width: 100%;
    @media (min-width: ${breakpoints.largeMin}) {
        width: calc(100% - 374px);
    }
`;

export const ProgressWrapper = styled.div`
    color: ${colors.pink};
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 40px;
`;

export const SingleDreamEntry = styled(Link)`
    text-decoration: none;
    &:focus, &:visited, &:active {
        text-decoration: none;
        color: ${colors.darkGray};
    }
    padding-bottom: 30px;
    ${baseFont()}
    color: ${colors.darkGray};
    &:not(:last-child) {
        border-bottom: 1px solid ${colors.titleGray};
    }
    &:not(:first-child) {
        margin-top: 40px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media(min-width: ${breakpoints.mediumMin}) {
        flex-direction: row;
    }
    @media(min-width: ${breakpoints.largeMin}) {
        padding-bottom: 20px;
    }
`;

export const Entry = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 75%;
        margin-bottom: 0;
        padding-right: 24px;
    }
`;

export const EntryTitle = styled.div`
    font-weight: 700;
    color: ${colors.titleGray};
    margin-bottom: 8px;
`;

export const EntryText = styled.div`
    line-height: 22px;
`;

export const DreamEntryMeta = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 700;
    color: ${colors.titleGray};
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 25%;
        padding-left: 36px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        padding-left: 0;
    }
`;

export const DreamEntryMetaEntry = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
    > div {
        &:first-child {
            font-size: 12px;
            color: ${colors.lightGray};
        }
    }
`;

export const EmptyResultsMessage = styled.p`
    text-align: center;
    ${baseFont({ size: 16 })}
    color: ${colors.darkGray};
`;

export const InfiniteScrollEndMessage = styled.p`
    text-align: center;
    ${baseFont()}
    color: ${colors.darkGray};
    padding-top: 16px;
    width: 100%;
`;

// Expandable Panels
export const ExpandableContainer = styled(ExpansionPanel)<{
    desktop: boolean;
    last?: boolean;
}>`
    ${p =>
        p.desktop &&
        css`
            display: none;
            @media (min-width: ${breakpoints.largeMin}) {
                display: block;
            }
        `}
    ${p =>
        !p.desktop &&
        css`
            display: block;
            @media (min-width: ${breakpoints.largeMin}) {
                display: none;
            }
        `}
    &.MuiExpansionPanel-root {
        width: 100%;
        margin-bottom: ${p => (p.last ? '36px' : '16px')};
        @media (min-width: ${breakpoints.mediumMin}) {
            width: 450px;
            margin-bottom: ${p => (p.last ? '40px' : '16px')};
        }
        @media (min-width: ${breakpoints.largeMin}) {
            width: 326px;
            margin-right: 24px;
            margin-bottom: 18px;
        }
        ${baseFont()}
        background: #f8f8f8;
        font-weight: 700;
        color: ${colors.darkGray};
        box-shadow: none;
        &::before {
            display: none;
        }
        &.Mui-expanded {
            margin-top: 0;
            margin-bottom: ${p => (p.last ? '36px' : '16px')};
            @media (min-width: ${breakpoints.mediumMin}) {
                margin-bottom: ${p => (p.last ? '40px' : '16px')};
            }
            @media (min-width: ${breakpoints.largeMin}) {
                margin-right: 24px;
                margin-bottom: 18px;
            }
        }
    }
`;

export const Summary = styled(ExpansionPanelSummary)`
    &.MuiExpansionPanelSummary-root {
        padding: 20px;
        padding: 0;
        margin: 0;
        min-height: 57px;
        position: relative;
        &.Mui-expanded {
            min-height: 57px;
        }
        .MuiExpansionPanelSummary-content {
            padding: 0 20px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            &.Mui-expanded {
                margin: 0;
            }
            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                span {
                    color: ${colors.lightGray};
                    padding-left: 18px;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
`;

export const Contents = styled(ExpansionPanelDetails)`
    &.MuiExpansionPanelDetails-root {
        padding: 0;
        flex-direction: column;
        @media (min-width: ${breakpoints.mediumMin}) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
`;

export const Section = styled.div`
    width: 100%;
    padding: 20px;
    border-top: 1px solid rgba(44, 44, 44, 0.23);
    font-weight: 400;
`;

export const SectionWithTable = styled(Section)`
    border-top: none;
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        thead {
            font-size: 11px;
            text-transform: uppercase;
            th {
                &:nth-child(1) {
                    width: 40%;
                    text-align: left;
                }
                &:nth-child(2) {
                    width: 30%;
                    font-size: 10px;
                    text-align: right;
                }
                &:nth-child(3) {
                    width: 30%;
                    font-size: 10px;
                    text-align: right;
                }
            }
        }
        tbody {
            font-size: 12px;
            tr#total {
                td {
                    &:first-child {
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                    padding-top: 2px;
                    padding-bottom: 12px;
                    border-bottom: 1px solid rgba(44, 44, 44, 0.23);
                }

                &.bottom {
                    td {
                        border-bottom: none;
                        border-top: 1px solid rgba(44, 44, 44, 0.23);
                        padding-top: 8px;
                    }
                }
            }
            tr#spacer {
                height: 8px;
            }
            td {
                padding-top: 8px;
                &:nth-child(2),
                &:nth-child(3) {
                    text-align: right;
                }
            }
        }
    }
`;

export const SelectWrapper = styled(Section)`
    @media (min-width: ${breakpoints.largeMin}) {
        padding: 8px;
    }
`;

export const StatsTable = styled.table`
    width: 100%;
    thead {
        th {
            width: 50%;
            &:nth-child(1) {
                min-width: 176px;
            }
        }
    }
    tbody {
        td {
            padding-bottom: 8px;
            &:nth-child(1) {
                text-align: right;
                padding-right: 18px;
            }
            &:nth-child(2) {
                text-align: left;
                padding-left: 18px;
            }
        }
    }
`;

export const Info = styled.p`
    background: ${colors.white};
    border-radius: 4px;
    width: 100%;
    margin: 0 auto;
    padding: 18px;
`;
