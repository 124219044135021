import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { baseFont, breakpoints, colors } from '../../../theme';

export const Card = styled(Link)`
    text-decoration: none;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    width: calc(100vw - 70px);
    max-width: 300px;
    border-radius: 4px;
    padding: 12px 16px;
    background: transparent;
    margin-top: 24px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(((100vw - 160px) / 2) - 16px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 360px;
    }
`;

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    ${baseFont({ size: 16 })}
    color: ${colors.black};
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 18px;
    }
`;

export const Subtitle = styled.h3`
    margin: 0;
    padding: 0;
    ${baseFont()}
    color: ${colors.lightGray};
    margin-bottom: 40px;
`;

export const Graph = styled.img`
    width: 100%;
    margin: 0 auto;
    display: block;
`;

export const Highlight = styled.p`
    /* to make it look good for launch with no highlight text */
    /* @TODO - add back once there's highlight text to show */
    /* margin: 40px 0 16px 0; */
    margin: 8px 0;
    padding: 0;
    ${baseFont()}
    color: ${colors.darkGray};
`;

export const Action = styled.button`
    text-decoration: none;
    outline: none;
    border: none;
    color: ${colors.cornHarvest};
    ${baseFont({ weight: 700 })}
    background: transparent;
    text-align: left;
    margin: 0;
    padding: 8px 0 0 0;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: none;
        svg {
            margin-left: 12px;
        }
    }
    svg {
        transition: all 0.2s ease-in-out;
        margin-bottom: -7px;
        margin-left: 4px;
    }
`;
