import React from 'react';
import Create from '@material-ui/icons/Create';
import styled, { css } from 'styled-components';
import { baseFont, breakpoints, colors } from '../../../theme';
import { EditButton } from './styled';
import { xAxisTotalByAnswerText } from './types';
import {AnalysisData} from "../../api/types";

const YAxisLabel = styled.div`
    ${baseFont()}
    color: rgba(44, 44, 44, 0.7);
    position: absolute;
    top: calc(100% - 36px);
    right: calc(50% + 388px);
    height: calc(100% - 36px);
    text-align: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    transform-origin: 0 0;
    @media (max-width: ${breakpoints.smallMax}) {
        display: none;
    }
    @media (max-width: ${breakpoints.mediumMax}) {
        font-size: 12px;
        right: calc(50% + 272px);
        top: calc(100% + 56px);
    }
`;

const RTable = styled.section`
    position: relative;
    display: table;
    border-collapse: collapse;
    margin-bottom: 28px;
    margin-left: 64px;
    width: calc(100% - 96px);
    ${baseFont({ size: 12 })}
    color: ${colors.darkGray};
    @media (max-width: ${breakpoints.smallMax}) {
      display: block;
      margin: 0 auto;
      width: calc(100% - 72px);
    }
`;

const Rthead = styled.header`
    display: none;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: table-header-group;
        font-weight: 700;
    }
`;

const Rtr = styled.p`
    display: table-row;
    &:nth-child(even) {
        background: ${colors.white};
    }
    @media (max-width: ${breakpoints.smallMax}) {
        display: block;
    }
`;

const Rth = styled.span<{ rowHeader?: boolean }>`
    display: table-cell;
    padding: 8px;
    border: 1px solid ${colors.lightGray};
    text-align: left;
    vertical-align: middle;
    ${p =>
        p.rowHeader &&
        css`
            @media (min-width: ${breakpoints.mediumMin}) {
                font-weight: 700;
            }
        `}
    @media (max-width: ${breakpoints.smallMax}) {
      display: ${p => (p.rowHeader ? 'block' : 'flex')};
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      ${p =>
          !p.rowHeader &&
          css`
              padding-left: 24px;
          `}
    }
`;

const MobileCellTitle = styled.span`
    display: inline-flex;
    flex-direction: column;
    width: 75%;
    margin-right: 16px;
    border-right: 1px solid ${colors.lightGray};
    > span {
        &:first-child {
            font-weight: 700;
        }
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        display: none;
    }
`;

const MobileBlockTitle = styled.span`
    display: block;
    font-weight: 700;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: none;
    }
`;

const Rtbody = styled.div`
    display: table-row-group;
    @media (max-width: ${breakpoints.smallMax}) {
        display: block;
    }
`;

interface Props {
    data: AnalysisData[];
    xAxisAnswers: string[];
    xAxisQuestion: string;
    xAxisTotals: xAxisTotalByAnswerText[];
    yAxisAnswers: string[];
    yAxisEditButtonClickHandler: () => void;
    yAxisQuestion: string;
}

const DataTable: React.FC<Props> = ({
    data,
    xAxisAnswers,
    xAxisQuestion,
    xAxisTotals,
    yAxisAnswers,
    yAxisEditButtonClickHandler,
    yAxisQuestion
}) => {
    return (
        <>
            <YAxisLabel>
                {yAxisQuestion}
                <EditButton onClick={yAxisEditButtonClickHandler} rotate>
                    <Create />
                </EditButton>
            </YAxisLabel>
            <RTable>
                <Rthead>
                    <Rtr>
                        <Rth></Rth>
                        {xAxisAnswers.map(answer => (
                            <Rth>{answer}</Rth>
                        ))}
                    </Rtr>
                </Rthead>
                <Rtbody>
                    {yAxisAnswers.map(yAnswer => (
                        <Rtr>
                            <Rth rowHeader>
                                <MobileBlockTitle>
                                    {yAxisQuestion}:
                                </MobileBlockTitle>
                                {yAnswer}
                            </Rth>
                            {xAxisAnswers.map(xAnswer => {
                                let percent = '0%';
                                const datum = data.find(
                                    datum =>
                                        datum.xAnswer === xAnswer &&
                                        datum.yAnswer === yAnswer
                                );
                                const total = xAxisTotals.find(
                                    total => total.xAxisAnswer === xAnswer
                                );
                                if (
                                    datum !== undefined &&
                                    total !== undefined &&
                                    total.total > 0
                                ) {
                                    percent = `${
                                        Math.round(
                                            (datum.count / total.total) * 10000
                                        ) / 100
                                    }%`;
                                }
                                return (
                                    <Rth>
                                        <MobileCellTitle>
                                            <span>{xAxisQuestion}:</span>
                                            <span>{xAnswer}</span>
                                        </MobileCellTitle>
                                        {percent}
                                    </Rth>
                                );
                            })}
                        </Rtr>
                    ))}
                </Rtbody>
            </RTable>
        </>
    );
};

export default DataTable;
