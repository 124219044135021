import React from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { baseFont, colors } from '../../../theme';
import { Question } from '../../api/types';
import { Constraint } from '../types';
import { Consumer } from '../../../types';
import { Checkmark, ConstraintItem, ConstraintItemsWrapper } from './styled';

const Panel = styled(ExpansionPanel)`
    &.MuiExpansionPanel-root {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
        padding-top: 8px;
        box-shadow: none;
        &::before {
            display: none;
        }
        &.Mui-expanded {
            margin: 0 auto;
            margin-bottom: 48px;
        }
    }
`;

const Summary = styled(ExpansionPanelSummary)`
    &.MuiExpansionPanelSummary-root {
        border-bottom: 1px solid rgba(112, 112, 112, 0.23);
        padding: 0;
        margin: 0;
        min-height: 44px;
        position: relative;
        &.Mui-expanded {
            min-height: 44px;
        }
        .MuiExpansionPanelSummary-content {
            margin: 0;
            flex-direction: row;
            ${baseFont({ size: 16 })}
            color: ${colors.titleGray};
            &.Mui-expanded {
                margin: 0;
            }
        }
    }
`;

export const Contents = styled(ExpansionPanelDetails)`
    &.MuiExpansionPanelDetails-root {
        padding: 0;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
    }
`;

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: block;
    color: ${colors.darkGray};
`;

interface Props {
    onConstraintClick: Consumer<Constraint>;
    question: Question;
    selectedConstraints: Constraint[];
}

const ConstraintPanel: React.FC<Props> = ({
    onConstraintClick,
    question,
    selectedConstraints
}) => {
    const constraints: Constraint[] = question.allowedAnswers && question.allowedAnswers.map(answer => ({
        questionId: question.id,
        questionText: question.title,
        answerText: answer,
        text: `${question.title}: ${answer}`
    }));

    const isSelected: (constraint: Constraint) => boolean = constraint =>
        selectedConstraints.find(
            selectedConstraint => selectedConstraint.text === constraint.text
        ) !== undefined;

    return (
        <Panel defaultExpanded={false}>
            <Summary
                expandIcon={
                    <IconWrapper>
                        <ExpandMoreIcon color="inherit" />
                    </IconWrapper>
                }
            >
                {question.title}
            </Summary>
            <Contents>
                <ConstraintItemsWrapper>
                    {constraints.map(constraint => (
                        <ConstraintItem
                            disabled={false}
                            key={constraint.text}
                            onClick={() => onConstraintClick(constraint)}
                            selected={isSelected(constraint)}
                        >
                            <Checkmark selected={isSelected(constraint)} />
                            {constraint.answerText}
                        </ConstraintItem>
                    ))}
                </ConstraintItemsWrapper>
            </Contents>
        </Panel>
    );
};

export default ConstraintPanel;
