import React, {useEffect, useReducer, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import SortIcon from '@material-ui/icons/Sort';
import InfoIcon from '@material-ui/icons/Info';
import InfiniteScroll from 'react-infinite-scroll-component';
import {decode, extractUuidFromFullPath} from '../../../utils';
import {ActionType, DreamEntry, Metadata, SearchParameters} from '../types';
import {
    BackButton,
    Background,
    Breadcrumb,
    Breadcrumbs,
    DesktopWrapper,
    DreamEntryListContainer,
    DreamEntryMeta,
    DreamEntryMetaEntry,
    EmptyResultsMessage,
    Entry,
    EntryText,
    EntryTitle,
    ExportButtonWrapper,
    FilterColumn,
    InfiniteScrollEndMessage,
    InputsWrapper,
    MetadataContainer,
    MobileButtonRow,
    MobileSort,
    MobileSortMenu,
    MultiContainer,
    SingleDreamEntry,
    SortBackdrop,
    SortMenu,
    SortMenuButton,
    SortMenuList,
    SortOption,
    SortOptionBullet,
    Top,
    TopRow,
    Wrapper
} from './styled';
import Title from '../../shared/PageTitle';
import TextHighlighter from '../../shared/TextHighlighter';
import FreeSearch, {FiltersWrapper} from '../FreeSearch';
import BaselinePanel from './BaselinePanel';
import WordCountStatisticsPanel from './WordCountStatisticsPanel';
import {ArrowBack} from '../../analysis/builder/styled';
import {Label} from '../../shared/styled/FormInput';
import Multiselect from '../../shared/MultiSelect';
import Tooltip from '../../shared/Tooltip';
import {
    getKeywordsFromKeywordIds,
    getMetadataFromDataResponses,
    transformAnswersToDreamEntryObjects,
    transformWordSearchParametersToFilters
} from '../utils';
import {libraryEntries} from '../constants';
import LoadingIndicator from '../../shared/LoadingIndicator';
import ExportButton from '../../shared/ExportButton';
import select, {Option} from "../selectors";
import {FilterButton} from "../../shared/styled/SearchInput";
import {Theme} from "../../../theme";
import AddCircle from "@material-ui/icons/AddCircle";
import Select from "../../shared/Select";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {initialState, reducer} from "../state";
import {
    ApiType,
    Category,
    WordSearchMetadata,
    WordSearchRequest,
    WordSearchStatistics,
    WordTemplate
} from "../../api/types";
import useApiGet from "../../api/useApiGet";
import useWordSearch from "../../api/useWordSearch";

enum SortOptionType {
    ascendingByLength = 'Shortest to Longest',
    descendingByLength = 'Longest to Shortest',
    ascendingByDate = 'Oldest to Newest (Response)',
    descendingByDate = 'Newest to Oldest (Response)',
    ascendingByDreamDate = 'Oldest to Newest (Dream)',
    descendingByDreamDate = 'Newest to Oldest (Dream)'
}

const sortOptions = [
    SortOptionType.ascendingByLength,
    SortOptionType.descendingByLength,
    SortOptionType.ascendingByDate,
    SortOptionType.descendingByDate,
    SortOptionType.ascendingByDreamDate,
    SortOptionType.descendingByDreamDate
];

const entryListChunkLength = 100;

interface SortOptionFilter {
    sortBy: 'answer_text.word_count' | 'created_at' | 'dream_date';
    sortDir: 'ASC' | 'DESC';
}

interface Props extends RouteComponentProps<{ json: string }> {}

const getSortFiltersFromSortOption: (
    option: SortOptionType
) => SortOptionFilter = option => {
    switch (option) {
        case SortOptionType.descendingByDate:
            return {
                sortBy: 'created_at',
                sortDir: 'DESC'
            };
        case SortOptionType.ascendingByDate:
            return {
                sortBy: 'created_at',
                sortDir: 'ASC'
            };
        case SortOptionType.descendingByDreamDate:
            return {
                sortBy: 'dream_date',
                sortDir: 'DESC'
            };
        case SortOptionType.ascendingByDreamDate:
            return {
                sortBy: 'dream_date',
                sortDir: 'ASC'
            };
        case SortOptionType.descendingByLength:
            return {
                sortBy: 'answer_text.word_count',
                sortDir: 'DESC'
            };
        case SortOptionType.ascendingByLength:
            return {
                sortBy: 'answer_text.word_count',
                sortDir: 'ASC'
            };
        default:
            return {
                sortBy: 'created_at',
                sortDir: 'DESC'
            };
    }
};

const AdvancedSearchResults: React.FC<Props> = ({ match }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [metadata, setMetadata] = useState<Metadata>({
        dataSetEntries: 0,
        dataSetParticipants: 0,
        dataSetPercentage: '0%',
        searchEntries: 0,
        searchParticipants: 0,
        searchPercentage: '0%',
        totalEntries: 0,
        totalParticipants: 0
    });
    const [
        wordSearchMetadata,
        setWordSearchMetadata
    ] = useState<WordSearchMetadata | null>(null);
    const [
        wordSearchStatistics,
        setWordSearchStatistics
    ] = useState<WordSearchStatistics | null>(null);
    const [parameters, setParameters] = useState<Partial<SearchParameters>>({});
    const [mobileSortScreenVisible, setMobileSortScreenVisible] = useState(
        false
    );
    const [desktopSortListVisible, setDesktopSortListVisible] = useState(false);
    const [sortOption, setSortOption] = useState(
        SortOptionType.descendingByDate
    );
    const [dreamEntries, setDreamEntries] = useState<DreamEntry[]>([]);
    const [emptySuccessfulResponse, setEmptySuccessfulResponse] = useState(
        false
    );
    const [paginationCursor, setPaginationCursor] = useState(0);
    const [keywords, setKeywords] = useState<string[][]>([[]]);

    const [categoryOptions, setCategoryOptions] = useState<
        Category[]
    >([]);
    const [selectedKeywordIds, setSelectedKeywordIds] = useState<string[][]>([]);
    const [selectedFreeSearchWords, setSelectedFreeSearchWords] = useState<
        string[][]
    >([[]]);
    const [freeSearchInputValue, setFreeSearchInputValue] = useState<string[]>(['']);

    const [lastCat, setLastCat] = useState(0);
    const [last, setLast] = useState(0);
    const [operators, setOperators] = useState<Option[]>([{label: '', value: ''}]);
    const [operatorsCat, setOperatorsCat] = useState<Option[]>([{label: '', value: ''}]);

    const handleKeywordSelectUpdate = (ids: string[], index: number) => {
        let localCategories = [...selectedKeywordIds];
        localCategories[index] = [...ids];
        setSelectedKeywordIds(localCategories);
    };

    const handleFreeSearchInputChange = (input: string[]) => {
        dispatch({
            type: ActionType.setFreeSearchInputValue,
            payload: input
        });
    };
    const history = useHistory();

    /* Keywords */
    const { data: wordTemplates, loading: wordTemplateLoading } = useApiGet<WordTemplate>({
        version: 2
    }, ApiType.WordTemplate);
    const wordTemplate = wordTemplates && wordTemplates.length ? wordTemplates[0] : null;
    useEffect(() => {
        if (wordTemplate) {
            setCategoryOptions(
                wordTemplate.categories
            );
        }
    }, [wordTemplates]);

    useEffect(() => {
        if (wordTemplate) {
            const keywords = getKeywordsFromKeywordIds(
                wordTemplate.categories,
                selectedKeywordIds
            );
            setKeywords(keywords);
        }
    }, [wordTemplates, selectedKeywordIds]);


    //total counts
    const {
        data: totalCountsData
    } = useWordSearch({
        metadataOnly: true
    });

    //data set counts
    const currentDataSetCountsRequest: Partial<WordSearchRequest> = {
        ...transformWordSearchParametersToFilters(
            parameters
        ),
        metadataOnly: true
    };
    delete currentDataSetCountsRequest['searchText'];
    delete currentDataSetCountsRequest['searchTextOperators'];
    delete currentDataSetCountsRequest['subCategoryIds'];
    delete currentDataSetCountsRequest['subCategoryOperators'];

    const {
        data: currentDataSetCountsData
    } = useWordSearch(currentDataSetCountsRequest);


    //actual search
    const dreamEntryRequest: Partial<WordSearchRequest> = {
        ...transformWordSearchParametersToFilters(
            parameters
        ),
        subCategoryIds: selectedKeywordIds.map(block =>
            {
                return block.map(id => extractUuidFromFullPath(id))
            }
        ),
        searchText: selectedFreeSearchWords,
        ...getSortFiltersFromSortOption(sortOption),
        size: entryListChunkLength,
        from: paginationCursor,
        metadataOnly: false
    };
    const {
        data: dreamEntryData,
        loading: dreamEntryLoading
    } = useWordSearch(dreamEntryRequest);

    useEffect(() => {
        if (
            totalCountsData &&
            currentDataSetCountsData &&
            dreamEntryData
        ) {
            setWordSearchStatistics(
                dreamEntryData.statistics
            );
            setWordSearchMetadata(dreamEntryData.metadata);

            const newDreamEntries = transformAnswersToDreamEntryObjects(
                dreamEntryData.answers
            );

            setEmptySuccessfulResponse(newDreamEntries.length === 0);

            if (paginationCursor === 0) {
                setDreamEntries(newDreamEntries);
            } else if (dreamEntries.length === paginationCursor) {
                setDreamEntries([...dreamEntries, ...newDreamEntries]);
            }

            const metadata = getMetadataFromDataResponses(
                totalCountsData,
                currentDataSetCountsData,
                dreamEntryData
            );
            setMetadata(metadata);
        }
    }, [dreamEntryData, totalCountsData, currentDataSetCountsData]);

    useEffect(() => {
        if (match.params.json === undefined) {
            return;
        }
        const params = decode<Partial<SearchParameters>>(match.params.json);
        if (params !== undefined) {
            setParameters(params);
            if (params.keywordIds !== undefined) {
                setSelectedKeywordIds(
                    params.keywordIds.map(block => {
                        return block.map(keyword =>
                          extractUuidFromFullPath(keyword))
                      }
                    )
                );
                setLastCat(params.keywordIds.length - 1)
            }
            if (params.freeSearchWords !== undefined) {

                setSelectedFreeSearchWords(params.freeSearchWords.filter(arr => {
                    if (arr[0] !== ''){
                        return arr;
                    }
                }));
                 setLast(params.freeSearchWords.length === 1 ? 0 : params.freeSearchWords.length -2)
            }
            if (params.freeSearchOperators) {
                setOperators(params.freeSearchOperators.map(op => {
                    if (op === ''){
                        return {label: '', value: ''}
                    }
                    if (op === 'or'){
                        return {label: 'OR', value: 'or'}
                    }
                    return {label: 'NOT', value: 'not'}
                }));
            }

            if (params.keywordOperators) {
                setOperatorsCat(params.keywordOperators.map(op => {
                    if (op === ''){
                        return {label: '', value: ''}
                    }
                    if (op === 'or'){
                        return {label: 'OR', value: 'or'}
                    }
                    return {label: 'NOT', value: 'not'}
                }));
            }
        } else {
            const template = libraryEntries.find(
                entry =>
                    entry.url.toLowerCase() === match.params.json.toLowerCase()
            );
            if (template !== undefined) {
                setParameters(template.params);
                if (template.params.keywordIds !== undefined) {
                    setSelectedKeywordIds(template.params.keywordIds);
                }
                if (template.params.freeSearchWords !== undefined) {
                    setSelectedFreeSearchWords(template.params.freeSearchWords);
                }
            }
        }
    }, [match.params.json]);

    const onSortOptionClick: (option: SortOptionType) => void = option => {
        setSortOption(option);
        setPaginationCursor(0);
        if (mobileSortScreenVisible) {
            setMobileSortScreenVisible(false);
        }
        if (desktopSortListVisible) {
            setDesktopSortListVisible(false);
        }
    };

    const loadMoreDreamEntries: () => void = () => {
        setTimeout(() => {
            setPaginationCursor(paginationCursor + entryListChunkLength);
        }, 1500);
    };

    const getWordsToHighlight = () => {
        let terms = keywords.flatMap(e=> e);
        // let terms = [...keywords];
        const freeSearchWords = selectedFreeSearchWords.flatMap(e=> e);
        if (freeSearchWords !== undefined) {
            terms = [...terms, ...freeSearchWords];
        }
        return terms;
    };

    const getCategoryOptions = () =>
        categoryOptions.map(keyword => ({
            label: keyword.name,
            options: keyword.subCategories.map(subCategory => ({
                label: `${subCategory.name
                    .charAt(0)
                    .toUpperCase()}${subCategory.name.slice(1)}`,
                value: extractUuidFromFullPath(subCategory.id)
            }))
        }));

    const getSelectedCategories = (index: number) =>{
        let resOp: Option[] = [];
        if (selectedKeywordIds[index] !== undefined){
            let options = getCategoryOptions().flatMap(category => category.options);
            options.forEach(option => {
                selectedKeywordIds[index].forEach(kw => {
                    if (kw === option.value){
                        resOp.push(option);
                    }
                })
            });
        }

        return resOp;
    }

    return (
        <>
            <Top>
                <div>
                    <BackButton
                        to={parameters.category ? '/library' : '/search'}
                    >
                        <ArrowBack />
                        Back to {parameters.category ? 'Library' : 'Search'}
                    </BackButton>
                    <Breadcrumbs>
                        <Breadcrumb to="/">Home</Breadcrumb> {'> '}
                        <Breadcrumb
                            to={`${
                                parameters.category ? '/library' : '/search'
                            }`}
                        >{`${
                            parameters.category ? 'Library' : 'Advanced Search'
                        }`}</Breadcrumb>
                    </Breadcrumbs>
                    <MetadataContainer>
                        <div>
                            <span>Data Set:</span>
                            <span>
                                {metadata.dataSetParticipants} /{' '}
                                {metadata.totalParticipants} Participants
                            </span>
                        </div>
                        <div>
                            {metadata.dataSetEntries} / {metadata.totalEntries}{' '}
                            Entries
                        </div>
                        <div>{metadata.dataSetPercentage} of total library</div>

                        <Tooltip
                            id="data-set-tooltip"
                            overrides={{ place: 'left' }}
                            trigger={<InfoIcon color="inherit" />}
                        >
                            <p>
                                These numbers represent the dream journals on
                                which your word search is performed.
                            </p>
                            <p>
                                To change this data set, go to the advanced
                                search page and use the Define Data Set tool.
                            </p>
                        </Tooltip>
                    </MetadataContainer>
                    <Title>
                        {parameters.category
                            ? parameters.category
                            : 'Search Results'}
                    </Title>
                    <InputsWrapper>
                        <MultiContainer>
                            <Label>Filter by Category</Label>

                            <Multiselect
                              handleChange={v => handleKeywordSelectUpdate(v, 0)}
                              nested
                              options={getCategoryOptions()}
                              placeholder="Select categories"
                              values={getSelectedCategories(0)}
                            />
                            {
                                lastCat === 0 && (
                                  <FilterButton
                                    data-testid="advanced-search-button"
                                    theme={Theme.light}
                                    add={true}
                                    onClick={() => {
                                        setSelectedKeywordIds([...selectedKeywordIds, []]);
                                        setLastCat(lastCat + 1);
                                        setOperatorsCat([...operatorsCat, {label: 'OR', value: 'or'}]);
                                    }}
                                  >
                                      Add Filter
                                      <AddCircle />
                                  </FilterButton>
                                )
                            }

                            {
                                selectedKeywordIds.map((e, index) => {
                                    if (index === 0)
                                        return;
                                    return (
                                      <>
                                          <div style={{'display': 'flex'}}>
                                              <div style={{'width': '35%'}}>
                                                  <Select
                                                    handleChange={e=> operatorsCat[index] = select.logicOptions().find(val => val.value === e) as Option}
                                                    options={select.logicOptions()}
                                                    value={operatorsCat[index]}
                                                    placeholder={'Select operator'}
                                                  />
                                              </div>
                                              <div style={{'width': '65%'}}>
                                                  <Multiselect
                                                    handleChange={v => handleKeywordSelectUpdate(v, index)}
                                                    nested
                                                    options={getCategoryOptions()}
                                                    placeholder="Select categories"
                                                    values={getSelectedCategories(index)}
                                                  />
                                              </div>
                                          </div>
                                          <FiltersWrapper>
                                              {
                                                  lastCat === index && (
                                                    <FilterButton
                                                      data-testid="advanced-search-button"
                                                      theme={Theme.light}
                                                      add={true}
                                                      onClick={() => {
                                                          setSelectedKeywordIds([...selectedKeywordIds, []]);
                                                          setLastCat(lastCat + 1);
                                                          setOperatorsCat([...operatorsCat, {label: 'OR', value: 'or'}]);
                                                      }}
                                                    >
                                                        Add Filter
                                                        <AddCircle />
                                                    </FilterButton>
                                                  )
                                              }
                                              {lastCat === index && (
                                                <FilterButton
                                                  data-testid="advanced-search-button"
                                                  theme={Theme.light}
                                                  onClick={e => {
                                                      selectedKeywordIds.pop();
                                                      setLastCat( lastCat - 1)
                                                      operatorsCat.pop();
                                                  }}
                                                  add={false}
                                                >
                                                    Delete
                                                    <RemoveCircleIcon />
                                                </FilterButton>
                                              )}
                                          </FiltersWrapper>
                                      </>
                                    )
                                })
                            }
                        </MultiContainer>
                        <MultiContainer>
                            <Label>Free Search Text</Label>
                            <FreeSearch
                              resultsPage
                              onChange={arr => {
                                  setSelectedFreeSearchWords([...arr])
                              }}
                              onInputChange={handleFreeSearchInputChange}
                              selectedWords={selectedFreeSearchWords}
                              value={freeSearchInputValue}
                              onFilterClick={() => {
                                  setSelectedFreeSearchWords([...selectedFreeSearchWords, []]);
                                  setFreeSearchInputValue([...freeSearchInputValue, '']);
                                  setLast(last + 1);
                                  setOperators([...operators, {label: 'OR', value: 'or'}]);
                              }}
                              index={0}
                              last={last === 0}
                              operators={operators}
                              onFilterRemoveClick={() => null}
                            />
                            {
                                selectedFreeSearchWords.map((e, index) => {
                                    if (index === 0)
                                        return;
                                    return (
                                      <FreeSearch
                                        resultsPage
                                        onChange={setSelectedFreeSearchWords}
                                        onInputChange={handleFreeSearchInputChange}
                                        selectedWords={selectedFreeSearchWords}
                                        value={freeSearchInputValue}
                                        onFilterClick={() => {
                                            setSelectedFreeSearchWords([...selectedFreeSearchWords, []]);
                                            setFreeSearchInputValue([...freeSearchInputValue, '']);
                                            setLast(last + 1);
                                            setOperators([...operators, {label: 'OR', value: 'or'}]);
                                        }}
                                        index={index}
                                        last={last === index}
                                        operators={operators}
                                        onFilterRemoveClick={() => {
                                            freeSearchInputValue.pop();
                                            selectedFreeSearchWords.pop();
                                            operators.pop();
                                            setLast( last - 1)
                                        }}
                                      />
                                    );
                                })
                            }
                        </MultiContainer>
                    </InputsWrapper>
                </div>
                <Background />
            </Top>
            <Wrapper>
                <ExportButtonWrapper>
                    <ExportButton
                        onClick={async () =>{
                            history.push('/exportCSV', parameters);
                        }}
                    />
                </ExportButtonWrapper>
                <MobileButtonRow>
                    <MobileSort onBackdrop={mobileSortScreenVisible}>
                        <SortMenuButton
                            onClick={() =>
                                setMobileSortScreenVisible(
                                    !mobileSortScreenVisible
                                )
                            }
                        >
                            Sort By {sortOption}
                            <SortIcon />
                        </SortMenuButton>
                        <MobileSortMenu show={mobileSortScreenVisible}>
                            {sortOptions.map(option => {
                                const selected = option === sortOption;
                                return (
                                    <SortOption
                                        key={option}
                                        onClick={() =>
                                            onSortOptionClick(option)
                                        }
                                        selected={selected}
                                    >
                                        {selected && <SortOptionBullet />}
                                        {option}
                                    </SortOption>
                                );
                            })}
                        </MobileSortMenu>
                        <SortBackdrop
                            onClick={() => setMobileSortScreenVisible(false)}
                            open={mobileSortScreenVisible}
                        />
                    </MobileSort>
                </MobileButtonRow>

                <TopRow>
                    <MetadataContainer searchData>
                        <div>
                            <span>Viewing:</span>
                            <span>
                                {metadata.searchParticipants} /{' '}
                                {metadata.dataSetParticipants} Participants
                            </span>
                        </div>
                        <div>
                            {metadata.searchEntries} / {metadata.dataSetEntries}{' '}
                            Entries
                        </div>
                        <div>{metadata.searchPercentage} of data set</div>

                        <Tooltip
                            id="search-tooltip"
                            trigger={<InfoIcon color="inherit" />}
                        >
                            <p>
                                These numbers represent how many entries in the
                                data set contain at least one occurence of the
                                selected categories or free search words.
                            </p>
                            <p>
                                If no data set was defined, it defaults to the
                                entire dream journal library.
                            </p>
                        </Tooltip>
                    </MetadataContainer>
                    <BaselinePanel
                        freeSearchWords={selectedFreeSearchWords}
                        keywordIds={selectedKeywordIds}
                        searchData={wordSearchMetadata}
                        searchEntries={metadata.searchEntries}
                        searchPercentage={metadata.searchPercentage}
                        showNoBaselineText={
                            selectedFreeSearchWords.length === 0 &&
                            selectedKeywordIds.length === 0
                        }
                        totalEntries={metadata.dataSetEntries}
                    />
                    <WordCountStatisticsPanel data={wordSearchStatistics} />
                    <SortMenu>
                        <SortMenuButton
                            onClick={() =>
                                setDesktopSortListVisible(
                                    !desktopSortListVisible
                                )
                            }
                        >
                            <span>Sort by {sortOption}</span>
                            <SortIcon />
                        </SortMenuButton>
                        <SortMenuList show={desktopSortListVisible}>
                            {sortOptions.map(option => {
                                const selected = option === sortOption;
                                return (
                                    <SortOption
                                        key={option}
                                        onClick={() =>
                                            onSortOptionClick(option)
                                        }
                                        selected={selected}
                                    >
                                        {selected && <SortOptionBullet />}
                                        {option}
                                    </SortOption>
                                );
                            })}
                        </SortMenuList>
                    </SortMenu>
                </TopRow>
                <DesktopWrapper>
                    <FilterColumn>
                        <BaselinePanel
                            desktop
                            freeSearchWords={selectedFreeSearchWords}
                            keywordIds={selectedKeywordIds}
                            searchData={wordSearchMetadata}
                            searchEntries={metadata.searchEntries}
                            searchPercentage={metadata.searchPercentage}
                            showNoBaselineText={
                                selectedFreeSearchWords.length === 0 &&
                                selectedKeywordIds.length === 0
                            }
                            totalEntries={metadata.dataSetEntries}
                        />
                        <WordCountStatisticsPanel
                            data={wordSearchStatistics}
                            desktop
                        />
                    </FilterColumn>
                    <DreamEntryListContainer>
                        {emptySuccessfulResponse && (
                            <EmptyResultsMessage>
                                Your query did not match any results. Please try
                                another search.
                            </EmptyResultsMessage>
                        )}
                        {dreamEntryLoading &&
                            paginationCursor === 0 && <LoadingIndicator />}
                        {(!dreamEntryLoading ||
                            paginationCursor > 0) && (
                            <InfiniteScroll
                                dataLength={dreamEntries.length}
                                loader={<LoadingIndicator />}
                                hasMore={
                                    dreamEntries.length < metadata.searchEntries
                                }
                                next={loadMoreDreamEntries}
                                endMessage={
                                    <InfiniteScrollEndMessage>
                                        {emptySuccessfulResponse
                                            ? ''
                                            : 'All matching entries have been loaded.'}
                                    </InfiniteScrollEndMessage>
                                }
                            >
                                {dreamEntries.map((entry, i) => (
                                    <SingleDreamEntry
                                        key={i}
                                        to={entry.linkTarget}
                                    >
                                        <Entry>
                                            <EntryTitle>
                                                {entry.questionText}
                                            </EntryTitle>
                                            <EntryText>
                                                <TextHighlighter
                                                    textToHighlight={entry.text}
                                                    searchWords={getWordsToHighlight()}
                                                />
                                            </EntryText>
                                        </Entry>
                                        <DreamEntryMeta>
                                            <DreamEntryMetaEntry>
                                                <div>DREAMER ID</div>
                                                <div>
                                                    {entry.respondent ? entry.respondent.userName : 'N/A'}
                                                </div>
                                            </DreamEntryMetaEntry>
                                            <DreamEntryMetaEntry>
                                                <div>DREAM DATE</div>
                                                <div>{entry.displayDate}</div>
                                            </DreamEntryMetaEntry>
                                            <DreamEntryMetaEntry>
                                                <div>WORD COUNT</div>
                                                <div>{entry.wordCount}</div>
                                            </DreamEntryMetaEntry>
                                        </DreamEntryMeta>
                                    </SingleDreamEntry>
                                ))}
                            </InfiniteScroll>
                        )}
                    </DreamEntryListContainer>
                </DesktopWrapper>
            </Wrapper>
        </>
    );
};

export default withRouter(AdvancedSearchResults);
