export const colorScale: string[] = [
    '#fac69a',
    '#eb524d',
    '#b68a07',
    '#3e683a',
    '#40647a',
    '#2c2c2c',
    '#90caca',
    '#409895',
    '#762020',
    '#c32e2e',
    '#952755',
    '#6E4381',
    '#BE5B06',
    '#ED9055',
    '#FCE2CC',
    '#F5A8A5',
    '#DAC482',
    '#9EB39C',
    '#9FB1BC',
    '#959595',
    '#C7E4E4',
    '#9FCBCA',
    '#BA8F8F',
    '#E19696',
    '#CA92A9',
    '#B6A0C0',
    '#DEAC82',
    '#F6C7A9'
];

export const graphAreaHeight = 300;
export const graphAreaWidth = 600;
