import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { baseInputStyles, Label } from '../shared/styled/FormInput';
import { baseFont, colors, Theme } from '../../theme';
import {FilterButton} from "../shared/styled/SearchInput";
import select, {getLogicOptions, Option} from "./selectors";
import Select from "../shared/Select";

const RemoveWordIcon: React.FC = () => (
    <svg
        height="14"
        width="14"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
    >
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
);

const FreeSearchContainer = styled.div<{ resultsPage: boolean }>`
    // margin: 12px 0 0;
    ${p =>
        p.resultsPage &&
        css`
            // margin-top: 36px;
            // padding-bottom: 56px;
        `}
`;

export const Input = styled.input<{ theme: Theme; whiteInput: boolean }>`
    ${baseInputStyles()}
    width: calc(100% - 40px);
    ${p =>
        p.whiteInput &&
        css`
            background: ${colors.white};
        `}
`;

export const YearInput = styled.input<{ theme: Theme; whiteInput: boolean, onlyYear: boolean }>`
    ${baseInputStyles()}
    width: ${p => p.onlyYear ? '55%' : '30%'};
    ${p =>
  p.whiteInput &&
  css`
            background: ${colors.white};
        `}
`;

const InputWrapper = styled.div<{white: boolean}>`
    position: relative;
    display: block;
    width: 100%;
    background: ${p => p.white ? colors.white : 'transparent'};
`;

export const FiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Button = styled.button<{ disabled: boolean; moveLeft: boolean }>`
    outline: none;
    border: none;
    background: transparent;
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
    position: absolute;
    top: 17px;
    right: ${p => (p.moveLeft ? '20' : '0')}px;
    color: ${p => (p.disabled ? colors.lightGray : colors.darkGray)};
    display: block;
`;

const SelectedWord = styled.div<{ onGray: boolean }>`
    display: inline-flex;
    background: ${p => (p.onGray ? colors.white : colors.lightestGray)};
    color: ${colors.darkGray};
    padding: 6px 15px 6px 18px;
    ${baseFont({ size: 14 })}
    border-radius: 2px;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
`;

const SelectedWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const RemoveButton = styled.button`
    border: none;
    border-radius: 2px;
    outline: none;
    padding: 3px 3px 0 3px;
    margin: 0 0 0 12px;
    cursor: pointer;
    background: transparent;
    &:hover {
        background: #d0d0d0;
    }
`;

interface Props {
    onChange: (values: string[][]) => void;
    onGray?: boolean;
    onInputChange: (value: string[]) => void;
    resultsPage?: boolean;
    selectedWords: string[][];
    value: string[];
    onFilterClick: () => void;
    index: number;
    last: boolean;
    operators: Option[];
    onFilterRemoveClick: () => void;
}

const FreeSearch: React.FC<Props> = ({
    onChange,
    onGray = false,
    onInputChange,
    resultsPage = false,
    selectedWords,
    value,
    onFilterClick,
    index,
    last,
    operators,
    onFilterRemoveClick
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.charCode === 13 && value[index] !== '') {
            handleButtonClick();
        }
    };

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        value[index] = e.target.value;
        onInputChange(value);
        // onInputChange(e.target.value);
        if (e.target.value === '') {
            setButtonDisabled(true);
        } else {
            if (buttonDisabled) {
                setButtonDisabled(false);
            }
        }
    };

    const handleButtonClick = () => {

        let localWords = selectedWords[index];

        if (undefined === localWords) {
            selectedWords[index] = [value[index]];
        } else {
            selectedWords[index] = [...localWords, value[index]];
        }

        setButtonDisabled(true);
        onChange(selectedWords);
         value[index] = '';
         onInputChange(value);
    };

    const removeSelected = (removeIndex: number) => {
        const newSelectedWords = selectedWords;
        newSelectedWords[index] = selectedWords[index].filter((_, i) => i !== removeIndex);
        onChange(newSelectedWords);
    };

    return (
        <FreeSearchContainer resultsPage={resultsPage}>
            <FiltersWrapper>
                {
                    index > 0 && (
                      <div style={{'width': '35%'}}>
                          <Select
                            handleChange={e=> operators[index] = select.logicOptions().find(val => val.value === e) as Option}
                            options={select.logicOptions()}
                            value={operators[index]}
                            placeholder={'Select operator'}
                          />
                      </div>
                    )
                }
                <InputWrapper style={{'width': index > 0 ? '65%' : '100%'}} white={!resultsPage}>
                    <Input
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      placeholder="Type a word to search"
                      type="text"
                      value={value[index]}
                      whiteInput={resultsPage}
                    />
                    <Button
                      disabled={buttonDisabled}
                      onClick={handleButtonClick}
                      moveLeft={false}
                    >
                        <AddCircle color="inherit" />
                    </Button>
                </InputWrapper>
            </FiltersWrapper>
            {selectedWords.length > 0 && (
                <SelectedWrapper>
                    {selectedWords[index].map((word, i) => (
                        <SelectedWord key={`selected-${i}`} onGray={resultsPage}>
                            {word}
                            <RemoveButton onClick={() => removeSelected(i)}>
                                <RemoveWordIcon />
                            </RemoveButton>
                        </SelectedWord>
                    ))}
                </SelectedWrapper>
            )}
            <FiltersWrapper>
                {last && (
                  <FilterButton
                    data-testid="advanced-search-button"
                    theme={Theme.light}
                    onClick={e => onFilterClick()}
                    add={true}
                  >
                      Add Filter
                      <AddCircle />
                  </FilterButton>
                )}
                {last && index !== 0 && (
                  <FilterButton
                    data-testid="advanced-search-button"
                    theme={Theme.light}
                    onClick={e => onFilterRemoveClick()}
                    add={false}
                  >
                      Delete
                      <RemoveCircleIcon />
                  </FilterButton>
                )}
            </FiltersWrapper>
        </FreeSearchContainer>
    );
};

export default FreeSearch;
