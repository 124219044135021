import React from 'react';
import { ModalTriggerSourceButton } from './types';
import {
    AlertWrapper,
    Modal,
    ModalButtonWrapper,
    ModalContents
} from './styled';
import AlertIcon from '../../shared/Alert';

interface Props {
    onContinueClick: () => void;
    onModalClose: () => void;
    trigger: ModalTriggerSourceButton | null;
}

const ConfirmationModal: React.FC<Props> = ({
    onContinueClick,
    onModalClose,
    trigger
}) => {
    const getBodyText: () => string = () => {
        if (trigger === ModalTriggerSourceButton.editChart) {
            return 'This will clear your current chart settings';
        }
        return 'Changing a section of your analysis automatically clears all other settings past that point. For example, adjusting step 2 (the x axis) will reset steps 3-5 (the y axis, constraints, and title).';
    };

    return (
        <Modal open={true} onClose={onModalClose}>
            <ModalContents>
                <AlertWrapper>
                    <AlertIcon />
                </AlertWrapper>
                <h2>Wait a second...</h2>
                <p>
                    <span>Important info: </span>
                    {getBodyText()}
                </p>
                <ModalButtonWrapper>
                    <button onClick={onModalClose}>Cancel</button>
                    <button onClick={onContinueClick}>Continue</button>
                </ModalButtonWrapper>
            </ModalContents>
        </Modal>
    );
};

export default ConfirmationModal;
