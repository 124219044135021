import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as serviceWorker from './serviceWorker';
import { ENTRYPOINT } from './config/entrypoint';
import Home from './components/home/Home';
import Library from './components/library/Library';
import ResponseDetail from './components/library/ResponseDetail';
import AnalyzeData from './components/analysis/Analysis';
import AdvancedSearch from './components/advancedSearch/AdvancedSearch';
import AdvancedSearchResults from './components/advancedSearch/results/AdvancedSearchResults';
import NavigationMenu from './components/navigation/NavigationMenu';
import { Path } from './types';
import ExportCSV from './components/export/ExportCSV';
import OpenSurveys from './components/survey/OpenSurveys';
import OpenSurvey from './components/survey/OpenSurvey';

let apiUrl = ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
    apiUrl = window._env_.API_URL;
}

console.debug(`SDDb connected to`, apiUrl)

ReactDOM.render(
    <BrowserRouter>
        <>
            <NavigationMenu />
            <Switch>
                <Route path={Path.home} component={Home} strict exact />
                <Route path={Path.library} component={Library} exact />
                <Route path={Path.analyze} component={AnalyzeData} exact />
                <Route path={Path.openSurveys} component={OpenSurveys} exact />
                <Route path={Path.openSurvey} component={OpenSurvey} exact />
                <Route
                    path={Path.search}
                    component={AdvancedSearch}
                    exact
                />
                <Route
                    path={Path.searchResults}
                    component={AdvancedSearchResults}
                    exact
                />
                <Route
                    path={Path.responseSetDetail}
                    component={ResponseDetail}
                    exact
                />
                <Route path={Path.exportCSV} component={ExportCSV} exact />
                <Route component={Home} />
            </Switch>
        </>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
