import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { colors } from '../../theme';

const Wrapper = styled.div`
    color: ${colors.pink};
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 40px;
`;

interface Props {
    className?: string;
}

const LoadingIndicator: React.FC<Props> = ({ className }) => (
    <Wrapper className={className}>
        <CircularProgress color="inherit" />
    </Wrapper>
);

export default LoadingIndicator;
