import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { baseFont, breakpoints, colors } from '../../../theme';
import bg from '../../../assets/Dreams-of-Lucid-Awareness@3x.png';

export const Heading = styled.h2`
    color: ${colors.darkGray};
    ${baseFont({ size: 12, weight: 900 })}
    text-align: left;
    width: 100%;
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 14px;
    }
    margin: 0;
    text-transform: uppercase;
    line-height: initial;
`;

export const Container = styled.div`
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: ${colors.white};
    @media (min-width: ${breakpoints.largeMin}) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 32px 16px;
    p {
        font-size: 24px;
        font-family: 'Martel Sans', sans-serif;
        font-weight: 200;
        line-height: 1.5;
        padding: 16px 0;
        text-align: left;
        color: ${colors.titleGray};
        margin: 0;
    }
    button {
        margin: 0;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        padding: 32px 16px 40px 16px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 400px;
        padding: 20px 0 0 32px;
        p {
            font-size: 28px;
        }
    }
`;

export const Background = styled.div`
    position: absolute;
    width: 100%;
    top: 225px;
    left: 0;
    right: 0;
    height: 900px;
    background-image: url(${bg});
    background-size: 1000px;
    background-position: -200px -16px;
    background-repeat: no-repeat;
    @media (min-width: ${breakpoints.mediumMin}) {
        top: 210px;
        background-size: 926px;
        background-position: -138px 50px;
    }
    &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
    }
    z-index: -1;
    @media (min-width: ${breakpoints.largeMin}) {
        display: none;
    }
`;

export const LargeBackground = styled.div`
    position: absolute;
    width: 978px;
    height: 871px;
    top: 303px;
    left: 50%;
    right: 0;
    margin-left: -606px;
    background-image: url(${bg});
    background-size: 1104px;
    background-position: -118px -16px;
    background-repeat: no-repeat;
    &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
    }
    z-index: -1;
    @media (max-width: ${breakpoints.mediumMax}) {
        display: none;
    }
`;

export const Hero = styled.div`
    width: 100%;
    padding-bottom: 63.8%;
    height: 0;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        padding-bottom: 0;
        height: initial;
        img {
            object-fit: initial;
            position: initial;
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        height: 370px;
        width: 350px;
        img {
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
        }
    }
`;

export const Link = styled(DefaultLink)`
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 48px;
    text-decoration: none;
    border: none;
    outline: none;
    width: calc(100% - 70px);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 320px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1032px;
        margin: 76px 0 188px 0;
    }
`;
