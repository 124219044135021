import { SearchParameters, LibraryEntryUrl } from './types';
import racial_justice from '../../assets/library/Racial_Justice_Survey@2x.jpg';
import visitations from '../../assets/library/Visitations_Dead@2x.jpg';
import lucidity from '../../assets/library/Lucid_Dreams@2x.jpg';
import religious_references from '../../assets/library/Religious_References@2x.jpg';
import flying from '../../assets/library/Flying_Dreams@2x.jpg';
import falling from '../../assets/library/Falling_Dreams@2x.jpg';
import pandemic from '../../assets/library/Pandemic-Related@2x.jpg';
import nightmares from '../../assets/library/Nightmares@2x.jpg';
// import young_women_animals from '../../assets/library/Womens_Dreams_Animals@2x.jpg';
// import young_men_animals from '../../assets/library/Mens_Dreams_Animals@2x.jpg';
import beverly from '../../assets/library/Religious_Cult@2x.jpg';
import nan from '../../assets/library/Traumatic_Accident@2x.jpg';
import mike from '../../assets/library/Vietnam_War@2x.jpg';
import lucrecia from '../../assets/library/Century_Prophet@2x.jpg';
import mehinaku from '../../assets/library/Indigenous_People@2x.jpg';
import trump from '../../assets/library/Donald_Trump@2x.jpg';
import obama from '../../assets/library/Obama_Dreams@2x.jpg';

interface LibraryEntry {
    image: string;
    params: Partial<SearchParameters>;
    title: string;
    url: LibraryEntryUrl;
}

export const libraryEntries: LibraryEntry[] = [
    {
        title: '2020 Racial Justice Survey',
        url: LibraryEntryUrl.RacialJustice,
        params: {
            surveyIds: ['c7329901-6339-4142-a8a6-6c39f89b7fe9'],
            surveyNames: ['2020 Racial Justice Survey'],
            category: '2020 Racial Justice Survey'
        },
        image: racial_justice
    },
    {
        title: 'Visitations from the Dead',
        url: LibraryEntryUrl.Visitations,
        params: {
            dreamTextIds: ['97489ad6-8421-11ea-b97a-0242ac120005'],
            dreamTexts: ['Dream Text: Visitation'],
            minWordCount: '50',
            category: 'Visitations from the Dead'
        },
        image: visitations
    },
    {
        title: 'Lucid Dreams',
        url: LibraryEntryUrl.Lucidity,
        params: {
            dreamTextIds: ['97489b78-8421-11ea-b97a-0242ac120005'],
            dreamTexts: ['Dream Text: Awareness'],
            minWordCount: '50',
            category: 'Lucid Dreams'
        },
        image: lucidity
    },
    {
        title: 'Dreams with Religious References',
        url: LibraryEntryUrl.ReligiousReferences,
        params: {
            keywordIds: [['b2de5e23-8b60-11ea-ba75-0242ac120007']],
            keywordTexts: ['religion'],
            minWordCount: '50',
            category: 'Dreams with Religious References'
        },
        image: religious_references
    },
    {
        title: 'Flying Dreams',
        url: LibraryEntryUrl.Flying,
        params: {
            keywordIds: [['b2de5ada-8b60-11ea-ba75-0242ac120007']],
            keywordTexts: ['flying'],
            minWordCount: '50',
            category: 'Flying Dreams'
        },
        image: flying
    },
    {
        title: 'Falling Dreams',
        url: LibraryEntryUrl.Falling,
        params: {
            keywordIds: [['b2de5b10-8b60-11ea-ba75-0242ac120007']],
            keywordTexts: ['falling'],
            minWordCount: '50',
            category: 'Falling Dreams'
        },
        image: falling
    },
    {
        title: 'Pandemic Dreams',
        url: LibraryEntryUrl.Pandemic,
        params: {
            surveyIds: [
                'c53aad62-7f13-416c-9938-093495f62bb0',
                '4524b1d3-2d30-4a15-8f76-1debf0ca1d72'
            ],
            category: 'Pandemic Dreams'
        },
        image: pandemic
    },
    {
        title: 'Nightmares',
        url: LibraryEntryUrl.Nightmares,
        params: {
            dreamTextIds: ['9749b4bd-8421-11ea-b97a-0242ac120005'],
            dreamTexts: ['Dream Text: Worst Nightmare'],
            minWordCount: '50',
            category: 'Nightmares'
        },
        image: nightmares
    },
    // {
    //     title: "Young Women's Dreams of Animals",
    //     url: LibraryEntryUrl.YoungWomenDreamOfAnimals,
    //     params: {
    //         // @TODO - add parameters
    //         category: "Young Women's Dreams of Animals"
    //     },
    //     image: young_women_animals
    // },
    // {
    //     title: "Young Men's Dreams of Animals",
    //     url: LibraryEntryUrl.YoungMenDreamOfAnimals,
    //     params: {
    //         // @TODO - add parameters
    //         category: "Young Men's Dreams of Animals"
    //     },
    //     image: young_men_animals
    // },
    {
        title: 'Beverly: Dreams of a Religious Cult Member',
        url: LibraryEntryUrl.Beverly,
        params: {
            respondentIds: ['a26c63d8-db06-4f71-ac9b-dec7cc00b3a8'],
            respondentNames: ['beverly_1986:beverly'],
            category: 'Dreams of a Religious Cult Member'
        },
        image: beverly
    },
    {
        title: 'Nan: Dreams of a Traumatic Accident Survivor',
        url: LibraryEntryUrl.Nan,
        params: {
            respondentIds: ['4798dcdc-c7bb-47d6-a35d-fe046ce463b5'],
            respondentNames: ['nan_series:nan'],
            category: 'Dreams of a Traumatic Accident Survivor'
        },
        image: nan
    },
    {
        title: 'Mike: Dreams of a Vietnam War Veteran',
        url: LibraryEntryUrl.Mike,
        params: {
            respondentIds: ['be1945f5-2e42-46a3-abbb-55e4c0c91294'],
            respondentNames: ['mike_journal:mike'],
            category: 'Dreams of a Vietnam War Veteran'
        },
        image: mike
    },
    {
        title: 'Lucrecia de Leon: Dreams of a 16th Century Prophet',
        url: LibraryEntryUrl.Lucrecia,
        params: {
            respondentIds: ['2dde8f8d-7130-4db7-a5f2-59b2d596a976'],
            respondentNames: ['lucrecia_1:lucrecia'],
            category: 'Dreams of a 16th Century Prophet'
        },
        image: lucrecia
    },
    {
        title: 'The Mehinaku: Dreams of an Indigenous People of the Amazon',
        url: LibraryEntryUrl.Mehinaku,
        params: {
            surveyIds: ['97476f7c-8421-11ea-b97a-0242ac120005'],
            surveyNames: ["Mehinaku of Brazil Dreams 1970's"],
            category: 'Dreams of an Indigenous People of the Amazon'
        },
        image: mehinaku
    },
    {
        title: 'Dreams of Donald Trump 2016-2017',
        url: LibraryEntryUrl.Trump,
        params: {
            surveyIds: ['97477412-8421-11ea-b97a-0242ac120005'],
            surveyNames: ['Trump Dreams 2016-2017'],
            category: 'Dreams of Donald Trump 2016-2017'
        },
        image: trump
    },
    {
        title: 'Dreams of Barack Obama 2008',
        url: LibraryEntryUrl.Obama,
        params: {
            surveyIds: ['97476475-8421-11ea-b97a-0242ac120005'],
            surveyNames: ['Barack Obama Dreams 2008'],
            category: 'Dreams of Barack Obama 2008'
        },
        image: obama
    }
];

export const surveyCategorySortOrder = [
    'SDDb Surveys',
    'Psychology Research',
    'Cultural Research',
    'Historical Individuals',
    'Modern Individuals'
];
