import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { baseFont, breakpoints, colors } from '../../theme';
import DefaultCopyright from '../shared/Copyright';

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 120px;
    @media (max-width: ${breakpoints.smallMax}) {
        padding-bottom: 56px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        padding-top: 164px;
    }
`;

export const TabsWrapper = styled.div`
    margin: 28px 0 64px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-bottom: 76px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin: 36px 0 80px;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 72px);
    margin: 0 auto;
    margin-bottom: 76px;
    padding: 56px 0 76px;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 160px);
        padding: 68px 0 68px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        padding: 92px 0 108px;
        width: 936px;
    }

    background: ${colors.lightestGray};
    text-align: center;
    ${baseFont()}
`;

export const CardTitle = styled.div`
    ${baseFont({ weight: 900 })}
    color: ${colors.darkGray};
    opacity: 0.6;
    text-transform: uppercase;
    margin-bottom: 16px;
    letter-spacing: 1.4px;
`;

export const CardText = styled.p`
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
    ${baseFont()}
    color: ${colors.titleGray};
    text-align: center;
    width: calc(100% - 64px);
    line-height: 22px;
    span {
        font-weight: 700;
    }
`;

export const Link = styled(DefaultLink)`
    display: block;
    margin: 0 auto;
    outline: none;
    background: transparent;
    padding: 10px 40px 10px 40px;
    line-height: 1;
    border: 1px solid ${colors.titleGray};
    border-radius: 4px;
    text-decoration: none;
    color: ${colors.titleGray};
    ${baseFont({ weight: 700 })}
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.titleGray};
        color: ${colors.lightestGray};
    }
`;

export const Copyright = styled(DefaultCopyright)`
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 0;
        padding: 0;
    }
`;
