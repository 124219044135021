import {ApiType, WordSearch, WordSearchRequest} from "./types";
import {extractIdFromPath, buildQueryString } from './utils';
import { ENTRYPOINT } from '../../config/entrypoint';

let apiUrl = ENTRYPOINT as string;
if (window._env_ && window._env_.API_URL) {
    apiUrl = window._env_.API_URL;
}

import axios from 'axios';

class Api
{
    async getOne<Type>(id: string, apiType: ApiType): Promise<Type|null> {
        const resource = apiType.toString();
        id = extractIdFromPath(id);
        const url = `${apiUrl}/sddb/${resource}/${id}`
        const { data } = await axios.get(url);
        return data || null;
    }

    async get<Type>(params: any, apiType: ApiType): Promise<Type[]|null> {
        const resource = apiType.toString();
        let url = `${apiUrl}/sddb/${resource}`
        const queryString = buildQueryString(params);
        if (queryString !== '') {
            url += '?' + queryString;
        }

        const { data } = await axios.get(url);
        if (data && data['hydra:member']) {
            return data['hydra:member'];
        } else {
            return [];
        }
    }

    async create<T>(request: any, apiType: ApiType) {
        const resource = apiType.toString();
        let url = `${apiUrl}/sddb/${resource}`
        const { data } = await axios.post(url, request);
        return data || null;
    }

    async wordSearch(request: Partial<WordSearchRequest>): Promise<WordSearch> {
        let url = `${apiUrl}/sddb/word-search`;
        const { data } = await axios.post(url, request);
        return data || null;
    }
}

export default Api;
