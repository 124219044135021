import styled, { css } from 'styled-components';
import { colors, Theme } from '../../../theme';
import { HintStatus } from '../FormInput';

export const NavButtonWrapper = styled.div<{ reverse: boolean; theme: Theme }>`
    position: absolute;
    top: 15px;
    line-height: 0.6;
    color: ${p =>
        p.theme === Theme.light ? colors.lightGray : 'rgba(255,255,255,0.7)'};
    svg {
        font-size: 40px;
    }
    ${p =>
        p.reverse &&
        css`
            left: 22px;
            svg {
                transform: rotate(180deg);
            }
        `}
    ${p =>
        !p.reverse &&
        css`
            right: 22px;
        `}
`;

export const Wrapper = styled.div<{ hintStatus: HintStatus; theme: Theme }>`
    * {
        font-family: 'Nunito Sans', sans-serif;
    }
    position: relative;
    .SingleDatePickerInput,
    .DateInput {
        background: transparent;
    }
    .SingleDatePicker_picker {
        top: 70px !important;
    }
    .DateInput_fang {
        top: 60px !important;
    }
    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
        width: 100%;
    }
    .DateInput_input {
        font-size: 18px;
        background: ${p =>
            p.theme === Theme.dark ? colors.darkGray : colors.offWhite};
        outline: none;
        border: none;
        border-radius: 4px;
        height: 56px;
        padding: 20px 16px;
        ${p =>
            !(p.hintStatus === HintStatus.hidden) &&
            css`
                padding: 28px 16px 12px;
            `}
        color: ${p =>
            p.theme === Theme.dark ? colors.white : colors.darkGray};
        ::-webkit-input-placeholder {
            color: ${colors.lightGray};
            opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 1)};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        ::-moz-placeholder {
            color: ${colors.lightGray};
            opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 1)};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        :-ms-input-placeholder {
            color: ${colors.lightGray};
            opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 1)};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        :-moz-placeholder {
            color: ${colors.lightGray};
            opacity: ${p => (p.hintStatus === HintStatus.active ? 0 : 1)};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        ${p =>
            p.theme === Theme.dark &&
            css`
                ::-webkit-input-placeholder {
                    color: ${colors.white};
                    opacity: ${p.hintStatus === HintStatus.active ? 0 : 0.7};
                }
                ::-moz-placeholder {
                    color: ${colors.white};
                    opacity: ${p.hintStatus === HintStatus.active ? 0 : 0.7};
                }
                :-ms-input-placeholder {
                    color: ${colors.white};
                    opacity: ${p.hintStatus === HintStatus.active ? 0 : 0.7};
                }
                :-moz-placeholder {
                    color: ${colors.white};
                    opacity: ${p.hintStatus === HintStatus.active ? 0 : 0.7};
                }
            `}
    }
    .DateInput_input__focused {
        border-bottom: 2px solid ${colors.goldenrod};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .CalendarMonth_caption {
        color: ${p =>
            p.theme === Theme.light ? colors.titleGray : colors.white};
    }
    .DayPicker_weekHeader {
        color: ${p =>
            p.theme === Theme.light
                ? colors.lightGray
                : 'rgba(255,255,255,0.7)'};
    }
    .CalendarDay__default:hover {
        background: ${colors.offWhite};
        color: ${colors.darkGray};
    }
    ${p =>
        p.theme === Theme.dark &&
        css`
            .SingleDatePicker_picker,
            .CalendarMonthGrid,
            .CalendarMonth,
            .DayPicker__horizontal {
                background: ${colors.darkGray};
            }
            .DateInput_fangStroke,
            .DateInput_fangShape {
                fill: ${colors.darkGray};
                stroke: ${colors.darkGray};
            }
            .CalendarDay__default {
                background: ${colors.darkGray};
                color: rgba(255, 255, 255, 0.75);
                border: 1px solid rgba(255, 255, 255, 0.75);
            }
            .CalendarDay__blocked_out_of_range,
            .CalendarDay__blocked_out_of_range:active,
            .CalendarDay__blocked_out_of_range:hover {
                background: ${colors.darkGray};
                color: rgba(255, 255, 255, 0.15);
            }
        `}
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
        background: ${colors.goldenrod};
        border: 1px double ${colors.goldenrod};
        color: ${colors.white};
    }
`;
