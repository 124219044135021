import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../shared/Card';
import { transformWordSearchParametersToFilters } from '../advancedSearch/utils';
import { Path, Runnable, Supplier } from '../../types';
import useWordSearch from "../api/useWordSearch";

interface Props {
    color: string;
    image: string;
    params: any;
    title: string;
    url: string;
}

const TemplateCard: React.FC<Props> = ({
    color,
    image,
    params,
    title,
    url
}) => {
    let history = useHistory();

    const [counts, setCounts] = useState({ dreamEntryCount: 0, respondentCount: 0 });

    const { data, loading, error } = useWordSearch({
        ...transformWordSearchParametersToFilters(params),
        metadataOnly: true
    })

    useEffect(() => {
        if (
            !loading &&
            !error &&
            data
        ) {
            if (data) {
                setCounts({
                    dreamEntryCount: data.dreamEntryCount,
                    respondentCount: data.respondentCount
                });
            }
        }
    }, [data, loading, error]);

    const handleButtonClick: Runnable = () => {
        const link = Path.searchResults.replace(':json?', url);
        history.push(link);
    };

    const getSubtitleString: Supplier<string> = () =>
        `${counts.respondentCount} Participant${
            counts.respondentCount === 1 ? '' : 's'
        } / ${counts.dreamEntryCount} Entr${counts.dreamEntryCount === 1 ? 'y' : 'ies'}`;

    return (
        <Card
            buttonText="View Library"
            color={color}
            img={image}
            loading={loading}
            onButtonClick={handleButtonClick}
            subtitle={getSubtitleString()}
            title={title}
        />
    );
};

export default TemplateCard;
