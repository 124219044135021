import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import { Theme } from '../../theme';
import { NavButtonWrapper, Wrapper } from './styled/DatePicker';
import { HintStatus } from './FormInput';
import { Hint } from './styled/FormInput';
import { Consumer } from '../../types';
import select, {ExtendedOption, Option} from "../advancedSearch/selectors";
import Select from "./Select";
import {Input} from "../advancedSearch/FreeSearch";

const NavButton: React.FC<{ reverse?: boolean; theme: Theme }> = ({
    reverse,
    theme
}) => (
    <NavButtonWrapper reverse={reverse ? reverse : false} theme={theme}>
        <Arrow />
    </NavButtonWrapper>
);

interface Props {
    date: Moment | null;
    id: string;
    onDateSelect: (date: Moment | null, isBce: string) => void;
    placeholder: string;
    theme?: Theme;
}

const CenturyPicker: React.FC<Props> = ({
    date,
    id,
    onDateSelect,
    placeholder,
    theme,
}) => {
    const [focused, setFocus] = useState<boolean | null>(false);
    const [_date, setDate] = useState<Moment | null>(date);
    const [century, setCentury] = useState<ExtendedOption | undefined>();
    const [hintStatus, setHintStatus] = useState<HintStatus>(HintStatus.hidden);
    const [isBce, setBce] = useState<Option|undefined>({label: 'CE', value: 'false'});

    const handleDateChange: Consumer<Moment | null> = date => {
        setDate(date);
        // @ts-ignore
        onDateSelect(date, isBce.value ? isBce.value : 'false');
    };

    useEffect(() => {
        setDate(date);
    }, [date]);

    useEffect(() => {
        if (century !== undefined) {
            const newDate = id === 'start-date' ? moment(century.start) : moment(century.end);

            setDate(newDate);
            // @ts-ignore
            onDateSelect(newDate, isBce.value ? isBce.value : 'false');
        }
    }, [century, isBce]);

    useEffect(() => {
        if (focused) {
            setHintStatus(HintStatus.active);
        } else {
            if (_date) {
                setHintStatus(HintStatus.complete);
            } else {
                setHintStatus(HintStatus.hidden);
            }
        }
    }, [focused, _date]);

    return (
        <Wrapper hintStatus={hintStatus} theme={theme ? theme : Theme.light} style={{display: 'flex', width: '100%'}}>
            <Hint status={hintStatus} theme={theme ? theme : Theme.light}>
                {placeholder}
            </Hint>
            <div style={{width: '50%'}}>
                <Select
                    handleChange={e=> setCentury(select.centuryOptions().find(val => val.value === e))}
                    options={select.centuryOptions()}
                    placeholder="Century"
                    value={century === undefined ? null : century}
                />
            </div>
            <div style={{width: '30%'}}>
                <Select
                    handleChange={e=> setBce(select.bceOptions().find(val => val.value === e))}
                    options={select.bceOptions()}
                    placeholder="CE/BCE"
                    value={isBce === undefined ? null : isBce}
                    clearable={false}
                />
            </div>
        </Wrapper>
    );
};

export default CenturyPicker;
