import styled from 'styled-components';
import DefaultDivider from '../../shared/Divider';
import { baseFont, breakpoints, colors } from '../../../theme';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    @media (max-width: ${breakpoints.smallMax}) {
        padding-bottom: 56px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        width: 1280px;
        margin: 0 auto;
    }
`;

export const Heading = styled.h2`
    color: ${colors.darkGray};
    ${baseFont({ size: 12, weight: 900 })}
    text-align: center;
    width: 100%;
    margin: 76px 0 66px 0;
    text-transform: uppercase;
    line-height: initial;
    @media (min-width: ${breakpoints.mediumMin}) {
        display: none;
    }
`;

export const SearchWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 72px;
    @media (min-width: ${breakpoints.mediumMin}) {
        margin-top: 84px;
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: 148px;
        margin-bottom: 84px;
    }
`;

export const CustomDivider = styled(DefaultDivider)`
    margin-bottom: 12px;
`;

export const CardsWrapper = styled.div`
    @media (max-width: ${breakpoints.smallMax}) {
        > * {
            &:last-child {
                margin-bottom: 48px;
                background: white;
            }
        }
    }
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 152px);
        margin: 0 auto;
        margin-bottom: 88px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > div {
            margin: 24px 8px 0 8px;
            &:first-child {
                margin: 24px 0 0 0;
            }
            &:last-child {
                margin: 24px 0 0 0;
            }
        }
    }
    @media (min-width: ${breakpoints.largeMin}) {
        margin-top: 40px;
    }
`;
