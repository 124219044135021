import React, {useEffect, useReducer} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {initialState, reducer} from './state';
import select from './selectors';
import {ActionType, Reducer, Step} from './types';
import {ApiType, Question, Survey} from '../../api/types';
import {Constraint} from '../types';
import {Consumer, Runnable, Supplier} from '../../../types';
import {Parameters} from '../results/types';
import {getQuestionsInAllSurveys, transformSurveyData} from './utils';
import {decode} from '../../../utils';
import LoadingIndicator from '../../shared/LoadingIndicator';
import ProgressIndicator from '../../shared/ProgressIndicator';
import ChooseData from './ChooseData';
import DefineAxis from './DefineAxis';
import AddConstraints from './AddConstraints';
import NameAndCreate from './NameAndCreate';
import {
    ArrowBack,
    BackButton,
    Button,
    ContentWrapper,
    CurrentSelectionCounter,
    FixedBottomBar,
    ResultsLink,
    StepSubtitle,
    StepTitle,
    Wrapper
} from './styled';
import useApiGet from "../../api/useApiGet";

const steps: { name: string; optional: boolean }[] = [
    { name: Step[0], optional: false },
    { name: Step[1], optional: false },
    { name: Step[2], optional: false },
    { name: Step[3], optional: true },
    { name: Step[4], optional: true }
];

interface Props extends RouteComponentProps<{ json: string }> {}

const AnalysisBuilder: React.FC<Props> = ({ match }) => {
    const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

    /*
        Initial values passed from the results page
        when user has already built an analysis
        but wants to edit a selection
    */
    useEffect(() => {
        if (match.params.json) {
            const parameters = decode<Partial<Parameters>>(match.params.json);
            if (parameters !== undefined) {
                dispatch({
                    type: ActionType.setInitialValues,
                    payload: parameters
                });
            }
        } else {
            dispatch({
                type: ActionType.initialized
            });
        }
    }, [match.params.json]);

    //the question IDs here are only supposed to be for non-dream entries
    const { data: surveys, loading: surveysLoading } = useApiGet<Survey>({
        enabled: true,
    }, ApiType.Survey);

    useEffect(() => {
        if (surveys) {
            const sortedSurveys = transformSurveyData(surveys);
            dispatch({ type: ActionType.setSurveys, payload: sortedSurveys });
        }
    }, [surveys]);


    const { data: questions, loading: questionsLoading } = useApiGet<Question>({
        surveys: state.selectedSurveyIds
    }, ApiType.Question, {
        active: state.selectedSurveyIds && state.selectedSurveyIds.length > 0
    });

    useEffect(() => {
        if (state.selectedSurveyIds && state.selectedSurveyIds.length > 0 && questions) {
            const questionsInAllSurveys = getQuestionsInAllSurveys(questions, state.surveys.filter((s) => state.selectedSurveyIds.includes(s.id)));
            dispatch({ type: ActionType.setQuestions, payload: questionsInAllSurveys });
        }
    }, [questions]);

    const handleProgressIndicatorClick: Consumer<number> = step => {
        if (step < state.currentStep) {
            dispatch({ type: ActionType.goToPreviousStep, payload: step });
        }
    };

    const handleSurveySelectionChange: Consumer<string[]> = ids => {
        dispatch({ type: ActionType.setSelectedSurveyIds, payload: ids });
    };

    const handleNextButtonClick: Runnable = () => {
        dispatch({ type: ActionType.incrementStep });
    };

    const handleXAxisQuestionChange: Consumer<Question | null> = question => {
        dispatch({ type: ActionType.setXAxis, payload: question });
    };

    const handleYAxisQuestionChange: Consumer<Question | null> = question => {
        dispatch({ type: ActionType.setYAxis, payload: question });
    };

    const handleConstraintSelectionChange: Consumer<
        Constraint[]
    > = constraints => {
        dispatch({
            type: ActionType.setSelectedConstraints,
            payload: constraints
        });
    };

    const handleTitleInputChange: Consumer<string> = title => {
        dispatch({
            type: ActionType.setTitle,
            payload: title
        });
    };

    const getContent: Supplier<JSX.Element> = () => {
        switch (state.currentStep) {
            case 0:
                if (surveysLoading) {
                    return <LoadingIndicator />;
                }
                return (
                    <ChooseData
                        onSurveySelectionChange={handleSurveySelectionChange}
                        selectedSurveyIds={state.selectedSurveyIds}
                        surveys={state.surveys}
                    />
                );
            case 1:
                return (
                    <DefineAxis
                        onSelectionChange={handleXAxisQuestionChange}
                        questions={state.questions}
                        questionsLoading={questionsLoading}
                        selectedQuestionId={select.xAxisQuestionId(state)}
                    />
                );
            case 2:
                return (
                    <DefineAxis
                        onSelectionChange={handleYAxisQuestionChange}
                        questions={select.yAxisQuesions(state)}
                        selectedQuestionId={select.yAxisQuestionId(state)}
                    />
                );
            case 3:
                return (
                    <AddConstraints
                        constraintQuestions={select.constraints(state)}
                        onSelectionChange={handleConstraintSelectionChange}
                        selectedConstraints={state.selectedConstraints}
                    />
                );
            case 4:
                return (
                    <NameAndCreate
                        onInputChange={handleTitleInputChange}
                        summaryData={select.summaryData(state)}
                        value={state.title}
                    />
                );
            default:
                return <></>;
        }
    };

    const getCounter: Supplier<JSX.Element> = () => {
        switch (state.currentStep) {
            case 0:
                if (state.selectedSurveyIds.length === 0) {
                    return <div></div>;
                }
                return (
                    <CurrentSelectionCounter>
                        <div>{state.selectedSurveyIds.length}</div> selected
                        {state.selectedSurveyIds.length === 3 && ' (max)'}
                    </CurrentSelectionCounter>
                );
            case 1:
                if (!state.selectedXAxisQuestion) {
                    return <div></div>;
                }
                return (
                    <CurrentSelectionCounter>
                        <div>1</div> 1 item max selection
                    </CurrentSelectionCounter>
                );
            case 2:
                if (!state.selectedYAxisQuestion) {
                    return <div></div>;
                }
                return (
                    <CurrentSelectionCounter>
                        <div>1</div> 1 item max selection
                    </CurrentSelectionCounter>
                );
            case 3:
                return (
                    <CurrentSelectionCounter>
                        <div>{state.selectedConstraints.length}</div> Optional
                        selection
                    </CurrentSelectionCounter>
                );
            default:
                return <div></div>;
        }
    };

    return (
        <Wrapper>
            <BackButton to="/analyze">
                <ArrowBack />
                Back to Analyze Data
            </BackButton>
            {state.initialized && (
                <ProgressIndicator
                    currentStep={state.currentStep}
                    onStepChange={handleProgressIndicatorClick}
                    selections={select.selectionTexts(state)}
                    steps={steps}
                />
            )}

            {!state.initialized && <LoadingIndicator />}
            {state.initialized && (
                <>
                    <StepTitle>{Step[state.currentStep]}</StepTitle>
                    {steps[state.currentStep].optional && (
                        <StepSubtitle>Optional</StepSubtitle>
                    )}
                    <ContentWrapper>{getContent()}</ContentWrapper>
                    <FixedBottomBar hide={select.nextButtonDisabled(state)}>
                        {getCounter()}
                        {state.currentStep !== 4 && (
                            <Button
                                disabled={select.nextButtonDisabled(state)}
                                onClick={handleNextButtonClick}
                            >
                                {select.buttonText(state)}
                            </Button>
                        )}
                        {state.currentStep === 4 && (
                            <ResultsLink
                                to={`/analyze/custom_results/${select.urlString(
                                    state
                                )}`}
                            >
                                Create Analysis
                            </ResultsLink>
                        )}
                    </FixedBottomBar>
                </>
            )}
        </Wrapper>
    );
};

export default withRouter(AnalysisBuilder);
