import styled, { css } from 'styled-components';
import DefaultCheck from '@material-ui/icons/Check';
import { baseFont, breakpoints, colors } from '../../../theme';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const StepsRow = styled(Row)`
    margin-top: 4px;
    @media (max-width: ${breakpoints.smallMax}) {
        display: none;
    }
`;

export const SelectionsRow = styled(Row)`
    margin-top: -4px;
    @media (max-width: ${breakpoints.smallMax}) {
        display: none;
    }
`;

export const HiddenButtonsRow = styled(Row)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
`;

export const HiddenButton = styled.button`
    padding: 0;
    margin: 0;
    opacity: 0;
    &:hover,
    &:active,
    &:focused,
    &:visited {
        opacity: 0;
    }
    width: 25px;
    height: 100%;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 130px;
    }
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
    }
`;

interface CellProps {
    highlight?: boolean;
    indicator?: boolean;
    stepText?: boolean;
    selectionText?: boolean;
}

export const Cell = styled.div<CellProps>`
    display: ${p => (p.indicator ? 'flex' : 'inline')};
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    text-align: center;
    @media (min-width: ${breakpoints.mediumMin}) {
        width: 130px;
        ${p =>
            p.indicator &&
            css`
                &:not(:first-child) {
                    &:after {
                        position: absolute;
                        left: calc(-50% + 16px);
                        right: calc(50% + 16px);
                        content: '';
                        display: block;
                        height: 2px;
                        background: ${colors.goldenrod};
                        opacity: 0.2;
                    }
                }
            `}
    }
    ${p =>
        p.stepText &&
        css`
            color: ${colors.darkGray};
            ${baseFont()};
        `}
    ${p =>
        p.selectionText &&
        css`
            color: ${colors.lightGray};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            ${baseFont({ size: 12 })};
            padding: 0 8px;
        `}
    ${p =>
        p.highlight &&
        css`
            color: ${colors.cornHarvest};
            font-style: italic;
        `}
`;

export const Circle = styled.div`
    width: 8px;
    height: 8px;
    background: ${colors.goldenrod};
    border-radius: 50%;
`;

export const LargeCircle = styled.div`
    width: 20px;
    height: 20px;
    background: ${colors.goldenrod};
    border-radius: 50%;
    color: ${colors.white};
    ${baseFont({ size: 12 })}
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Check = styled(DefaultCheck)`
    color: ${colors.goldenrod};
`;
