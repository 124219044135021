import React from 'react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { TemplateCard, TemplateCardWrapper } from './styled';
import { templateAnalyses } from './constants';
import { Path } from '../../../types';

const Templates: React.FC = () => (
    <TemplateCardWrapper>
        {templateAnalyses.map(template => (
            <TemplateCard
                key={`${template.title}`}
                to={Path.analyze
                    .replace(':type?', 'template_results')
                    .replace(':json?', template.url)}
            >
                <h2>{template.title}</h2>
                <h3>{template.respondentCount} participants</h3>
                <img src={template.imageUrl} />
                <p>{template.highlight}</p>
                <button>
                    View Analysis <ArrowForward />
                </button>
            </TemplateCard>
        ))}
    </TemplateCardWrapper>
);

export default Templates;
