import React from 'react';
import styled from 'styled-components';
import Highlighter, { Chunk, FindChunks } from 'react-highlight-words';
import { baseFont, colors } from '../../theme';

const Results = styled.p`
    padding: 0;
    margin: 0;
    ${baseFont()}
    color: ${colors.darkGray};
    line-height: 22px;
    text-align: left;
    background: transparent;
    mark {
        background: transparent;
        color: ${colors.goldenrod};
        font-weight: 700;
    }
`;

interface Props {
    caseSensitive?: boolean;
    className?: string;
    searchWords: string[];
    textToHighlight: string;
}

const TextHighlighter: React.FC<Props> = ({
    caseSensitive = false,
    className,
    searchWords,
    textToHighlight
}) => {
    const findWholeWords: (options: FindChunks) => Chunk[] = ({
        caseSensitive,
        searchWords,
        textToHighlight
    }) => {
        const chunks: Chunk[] = [];

        const text = caseSensitive
            ? textToHighlight
            : textToHighlight.toLowerCase();

        const singleTextWords = text.split(/\b/g);

        let fromIndex = 0;
        const singleTextWordsWithStart = singleTextWords.map(word => {
            const index = text.indexOf(word, fromIndex);
            fromIndex = index;
            return {
                word,
                index
            };
        });

        searchWords.forEach(word => {
            const searchWord = caseSensitive ? word : word.toLowerCase();
            singleTextWordsWithStart.forEach(textWordWithStart => {
                if (textWordWithStart.word === searchWord) {
                    const start = textWordWithStart.index;
                    const end = textWordWithStart.index + searchWord.length;
                    chunks.push({
                        start,
                        end
                    });
                }
            });
        });

        return chunks;
    };
    return (
        <Results className={className}>
            <Highlighter
                searchWords={searchWords}
                textToHighlight={textToHighlight}
                caseSensitive={caseSensitive}
                findChunks={findWholeWords}
            />
        </Results>
    );
};

export default TextHighlighter;
