import React from 'react';
import { StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { colors } from '../../../theme';

export const IndicatorWrapper = styled.div<{ open: boolean }>`
    cursor: pointer;
    padding: 8px;
    display: flex;
    svg {
        color: ${colors.darkGray};
    }
    ${p =>
        p.open &&
        css`
            transform: rotate(180deg);
        `}
`;

export const DropdownIndicator: React.FC<{ open: boolean }> = ({ open }) => (
    <IndicatorWrapper open={open}>
        <ArrowDropDownIcon />
    </IndicatorWrapper>
);

const baseStyles: (overrides?: { [param: string]: string }) => StylesConfig = (
    overrides = {}
) => ({
    clearIndicator: provided => ({
        ...provided,
        cursor: 'pointer'
    }),
    control: (_, state) => ({
        display: 'flex',
        marginBottom: '8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'none',
        borderBottom: state.menuIsOpen
            ? `1px solid ${colors.goldenrod}`
            : `1px solid ${colors.darkGray}`,
        borderRadius: 0,
        padding: '7.5px 0 7.5px 0',
        background: `${colors.white}`,
        ...overrides
    }),
    input: provided => ({
        ...provided,
        fontFamily: `'Nunito Sans', sans-serif`,
        fontSize: '16px',
        marginBottom: '8px'
    }),
    menu: provided => ({
        ...provided,
        padding: '0 16px',
        zIndex: 2
    }),
    noOptionsMessage: provided => ({
        ...provided,
        fontFamily: `'Nunito Sans', sans-serif`
    }),
    option: provided => ({
        ...provided,
        cursor: 'pointer',
        background: 'transparent',
        fontFamily: `'Nunito Sans', sans-serif`,
        '&:not(:last-child)': {
            borderBottom: '1px solid rgba(59, 59, 59, 0.2)'
        },
        '&:hover, &:active, &:focus': {
            backgroundColor: `${colors.lightestGray}`
        },
        color: `${colors.darkGray}`
    }),
    placeholder: provided => ({
        ...provided,
        fontFamily: `'Nunito Sans', sans-serif`,
        fontStyle: 'italic',
        fontSize: '16px'
    })
});

export const customSelectStyles: (overrides: {
    [param: string]: string;
}) => StylesConfig = overrides => ({
    ...baseStyles(overrides),
    singleValue: provided => ({
        ...provided,
        fontFamily: `'Nunito Sans', sans-serif`
    })
});

export const customMultiSelectStyles: StylesConfig = {
    ...baseStyles(),
    multiValue: provided => ({
        ...provided,
        backgroundColor: `${colors.lightestGray}`,
        color: `${colors.darkGray}`,
        padding: '3px 12px',
        fontFamily: `'Nunito Sans', sans-serif`
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: `${colors.darkGray}`,
        fontFamily: `'Nunito Sans', sans-serif`
    }),
    multiValueRemove: provided => ({
        ...provided,
        marginLeft: '12px',
        cursor: 'pointer',
        ':hover': {
            background: '#d0d0d0'
        }
    })
};

export const customNestedMultiSelectStyles: StylesConfig = {
    ...customMultiSelectStyles,
    group: provided => ({
        ...provided,
        paddingLeft: '8px'
    }),
    groupHeading: provided => ({
        ...provided,
        fontFamily: `'Nunito Sans', sans-serif`,
        fontSize: '16px',
        marginLeft: '-8px'
    })
};
