import styled from 'styled-components';
import { breakpoints, colors, Theme } from '../../theme';

interface Props {
    theme?: Theme;
}

const Title = styled.h1<Props>`
    display: block;
    margin: 0 auto;
    text-align: center;
    font-family: 'Martel Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    color: ${p => (p.theme === Theme.dark ? colors.white : colors.titleGray)};
    @media (min-width: ${breakpoints.largeMin}) {
        font-size: 28px;
    }
`;

export default Title;

Title.defaultProps = {
    theme: Theme.light
};
