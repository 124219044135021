import styled, { css } from 'styled-components';
import { baseFont, breakpoints, colors } from '../../../theme';

export const Container = styled.div<{ large: boolean; open: boolean }>`
    background: ${colors.white};
    position: absolute;
    top: ${p => (p.large ? 67 : 130)}px;
    left: 50%;
    width: calc(100% - 70px);
    margin: 0 auto;
    margin-left: calc(-50% + 35px);
    box-shadow: 0 4px 6px 0 rgba(40, 40, 40, 0.25);
    @media (min-width: ${breakpoints.mediumMin}) {
        width: calc(100% - 240px);
        margin-left: calc(-50% + 120px);
    }
    @media (min-width: ${breakpoints.largeMin}) {
        top: ${p => (p.large ? 83 : 130)}px;
        width: 756px;
        margin-left: -378px;
    }
    opacity: 0;
    z-index: -1;
    display: none;
    ${p =>
        p.open &&
        css`
            display: initial;
            opacity: 1;
            z-index: 3;
            max-height: calc(100vh - 200px);
            overflow-y: auto;
        `}
`;

export const RelativeContainer = styled.div<{ open: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 0;
    padding: 0;
    ${p =>
        p.open &&
        css`
            padding: 40px 32px 32px 32px;
            line-height: initial;
            opacity: 1;
        `}
`;

export const CloseButton = styled.button`
    background: transparent;
    color: ${colors.darkGray};
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const Grouping = styled.h3`
    margin: 0;
    padding: 0 40px 0 0;
    ${baseFont()}
    font-weight: 900;
    text-transform: uppercase;
`;

export const ResultList = styled.ul`
    list-style: none;
    padding: 0 0 0 16px;
    li {
        list-style: none;
        ${baseFont({ size: 16 })}
        width: 100%;
        display: block;
        &:not(:last-child) {
            border-bottom: 1px solid rgba(59, 59, 59, 0.2);
        }
        a {
            display: block;
            text-decoration: none;
            color: inherit;
            outline: none;
            border: none;
            cursor: pointer;
            padding: 24px 0;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: inherit;
            }
            em {
                color: ${colors.goldenrod};
                font-style: initial;
            }
        }
    }
`;

export const NoResults = styled.div`
    text-align: center;
    padding: 40px 0;
    margin: 0;
    text-align: center;
    ${baseFont({ size: 16 })}
    width: 100%;
    display: block;
`;

export const InfiniteScrollEndMessage = styled.p`
    text-align: center;
    ${baseFont()}
    color: ${colors.darkGray};
    padding-top: 16px;
    width: 100%;
`;
