import { ENTRYPOINT } from '../../config/entrypoint';

interface DataProps {
    firstName?: string;
    lasttName?: string;
    email?: string;
    projectDetails?: string;
}
const postCSVExportData = async (data: DataProps) => {
    let apiUrl = ENTRYPOINT;
    if (window._env_ && window._env_.API_URL) {
        apiUrl = window._env_.API_URL;
    }
    const url = `${apiUrl}/csv_exports`;
    return await fetch(url || '', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
};
export default postCSVExportData;
