import React, { useMemo } from 'react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import * as Styled from './styled/FeaturedAnalysis';
import { TemplateAnalysis } from '../analysis/templates/types';
import { Path } from '../../types';

interface Props {
    template: TemplateAnalysis;
}

const FeaturedAnalysis: React.FC<Props> = ({ template }) => {
    const link = useMemo(
        () =>
            Path.analyze
                .replace(':type?', 'template_results')
                .replace(':json?', template.url),
        [template]
    );

    return (
        <Styled.Card to={link}>
            <Styled.Title>{template.title}</Styled.Title>
            <Styled.Subtitle>
                {template.respondentCount} participants
            </Styled.Subtitle>
            <Styled.Graph src={template.imageUrl} />
            <Styled.Highlight>{template.highlight}</Styled.Highlight>
            <Styled.Action>
                View Analysis
                <ArrowForward />
            </Styled.Action>
        </Styled.Card>
    );
};

export default FeaturedAnalysis;
