import React from 'react';
import {
    SummaryText,
    SurveyCounts,
    SurveyMeta,
    SurveySummaryWrapper
} from './styled';
import {Survey} from "../api/types";

interface Props {
    survey: Survey;
}

const SurveySummary: React.FC<Props> = ({ survey }) => (
    <SurveySummaryWrapper>
        <SummaryText>{survey.name}</SummaryText>
        <SummaryText dangerouslySetInnerHTML={{__html: survey.description || 'N/A'}} />
        <SurveyMeta>
            <SurveyCounts>
                <span>
                    {`${survey.respondentCount} Participant${
                        survey.respondentCount === 1 ? '' : 's'
                    }`}
                </span>
            </SurveyCounts>
            <div>{survey.year}</div>
        </SurveyMeta>
    </SurveySummaryWrapper>
);

export default SurveySummary;
