import React, {useEffect, useState} from 'react';
import moment from 'moment';
import * as Styled from './styled/FeaturedDream';
import Button from '../shared/Button';
import {createFullUuid, getRandomImageUrl, removeApiPrefix} from '../../utils';
import useApiGetOne from "../api/useApiGetOne";
import {Answer, ApiType} from "../api/types";

/* Daily Featured Dream */
const featuredDreamAnswerIds: string[] = [
    '9a1fc503',
    '9a1ff369',
    '9a2d79ec',
    '9aa006fd',
    '9a095e83',
    '9a5114e0',
    '9aae32ca',
    '9aac1bcb',
    '9cf96833',
    '99f8409a',
    '99f83fb2',
    '99f82d0a',
    '99e4a37a'
];

const dayOfYearToday = parseInt(moment(new Date()).format('DDD'));
const todaysFeaturedDreamIndex =
    (dayOfYearToday - 1) % featuredDreamAnswerIds.length;

const featuredDreamId = `${createFullUuid(
    featuredDreamAnswerIds[todaysFeaturedDreamIndex]
)}`;

/* Random Image */
const randomImageUrl = getRandomImageUrl();

interface Props {
    onDataFetched?: () => void;
}

const FeaturedDream: React.FC<Props> = ({ onDataFetched = () => null }) => {
    const { data: answer, loading, error } = useApiGetOne<Answer>(featuredDreamId, ApiType.Answer);

    if (answer && !loading) {
        onDataFetched && onDataFetched();
    }

    if (error) {
        console.error(error);
        onDataFetched && onDataFetched();
    }

    return (
        <>
            {loading && <></>}
            {error && (
                <>
                    <Styled.Link
                        to={`/not-found`}
                    >
                        <Styled.Container>
                            <Styled.Hero>
                                <img src={randomImageUrl} />
                            </Styled.Hero>
                            <Styled.Content>
                                <Styled.Heading>Featured Dream</Styled.Heading>
                                <p>In a dream I asked the dancer Eduardova to dance the Czardas just one time more. She had a
                                    broad streak of shadow or light across the middle of her face between the lower part of her
                                    forehead and the cleft of her chin. Just then someone with the loathsome gestures of an
                                    unconscious intriguer approached to tell her the train was leaving immediately. The manner in
                                    which she listened to this announcement made it terribly clear to me that she would not dance
                                    again. ‘I am a wicked, evil woman, am I not?’ she said. ‘Oh no,’ I said, ‘not that,’ and turned away
                                    aimlessly <br />
                                    <i>--Franz Kafka, 1910</i>
                                </p>
                                <Button>View Dream</Button>
                            </Styled.Content>
                        </Styled.Container>
                    </Styled.Link>
                    <Styled.Background />
                    <Styled.LargeBackground />
                </>
            )}
            {answer && (
                <>
                    <Styled.Link
                        to={`${removeApiPrefix(answer.responseSet)}/${answer.id}`}
                    >
                        <Styled.Container>
                            <Styled.Hero>
                                <img src={randomImageUrl} />
                            </Styled.Hero>
                            <Styled.Content>
                                <Styled.Heading>Featured Dream</Styled.Heading>
                                <p>{answer.answerText}</p>
                                <Button>View Dream</Button>
                            </Styled.Content>
                        </Styled.Container>
                    </Styled.Link>
                    <Styled.Background />
                    <Styled.LargeBackground />
                </>
            )}
        </>
    );
};

export default FeaturedDream;
